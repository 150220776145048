import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';


const tableData = [
    {
        id: uuidv4(),
        title: 'Name',
        value: 'Metropoly',
    },
    {
        id: uuidv4(),
        title: 'Symbol',
        value: 'METRO',
    },
    {
        id: uuidv4(),
        title: 'Decimals',
        value: '18',
    },
    {
        id: uuidv4(),
        title: 'Blockchain',
        value: 'Ethereum',
    },
    {
        id: uuidv4(),
        title: 'Total Supply',
        value: '1,000,000,000 METRO',
    },
    {
        id: uuidv4(),
        title: 'Taxes',
        value: 'No Taxes',
    },
    {
        id: uuidv4(),
        title: 'Launch Price',
        value: '5% less than Stage 10',
    },
    {
        id: uuidv4(),
        title: 'Liquidity Pair',
        value: ' METRO/USDC (ETH)',
    },


]
const PresaleData = [
    {
        id: uuidv4(),
        title: 'Crypto to purchase',
        value: 'ETH/USDT/BNB',
    },
    {
        id: uuidv4(),
        title: 'Presale starts',
        value: '30th October at 3pm UTC',
    },
    {
        id: uuidv4(),
        title: 'Presale Ends',
        value: '19th December at 3pm UTC',
    },
    {
        id: uuidv4(),
        title: 'Tokens for Presale',
        value: '200,000,000 METRO',
    },
    {
        id: uuidv4(),
        title: 'Tokens for Bonus Amount',
        value: '100,000,000 METRO',
    },
    // {
    //     id: uuidv4(),
    //     title: 'Taxes',
    //     value: 'No Taxes',
    // },
    // {
    //     id: uuidv4(),
    //     title: 'Launch Price',
    //     value: '50% less than Stage 10',
    // },
    // {
    //     id: uuidv4(),
    //     title: 'Liquidity Pair',
    //     value: ' METRO/USDC (ETH)',
    // },


]

const BonusData = [
    {
        id: uuidv4(),
        title: 'Stage 1',
        bonusValue: '50%',
        value: '5 Days',
    },
    {
        id: uuidv4(),
        title: 'Stage 2',
        bonusValue: '45%',
        value: '5 Days',
    },
    {
        id: uuidv4(),
        title: 'Stage 3',
        bonusValue: '40%',
        value: '5 Days',
    },
    {
        id: uuidv4(),
        title: 'Stage 4',
        bonusValue: '35%',
        value: '5 Days',
    },
    {
        id: uuidv4(),
        title: 'Stage 5',
        bonusValue: '30%',
        value: '5 Days',
    },
    {
        id: uuidv4(),
        title: 'Stage 6',
        bonusValue: '25%',
        value: '5 Days',
    },
    {
        id: uuidv4(),
        title: 'Stage 7',
        bonusValue: '20%',
        value: '5 Days',
    },
    {
        id: uuidv4(),
        title: 'Stage 8',
        bonusValue: '15%',
        value: '5 Days',
    },
    {
        id: uuidv4(),
        title: 'Stage 9',
        bonusValue: '10%',
        value: '5 Days',
    },
    {
        id: uuidv4(),
        title: 'Stage 10',
        bonusValue: '5%',
        value: '5 Days',
    },

]


const FairLaunch = ({ item }) => {


    return (
        <>
            <div className=" 2xl:w-[70%] xl:w-[80%] lg:w-[80%] mx-auto">
                <div className="">
                    <div className="text-center sm:pt-2 xs:pt-2 pb-10">
                        <h3 className="text-[30px] sm:text-[24px] xs:text-[24px] font-semibold font-Poppins text-[#000000]">Metro Token</h3>
                    </div>
                    <div className="border-t border-l border-r ">

                        {

                            tableData.map((item, id) => (
                                <div className="border-b flex justify-between py-3 px-5 xs:px-3">
                                    <h4 className="text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000]">{item.title}</h4>
                                    <h4 className="text-[16px]  sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000]">{item.value}</h4>
                                </div>

                            )
                            )

                        }
                    </div>
                </div>
                <div className="">
                    <div className="text-center py-10">
                        <h3 className="text-[30px] sm:text-[24px] xs:text-[24px] font-semibold font-Poppins text-[#000000]">Presale Structure</h3>
                    </div>
                    <div className="border-t border-l border-r  ">

                        {

                            PresaleData.map((item, id) => (
                                <div className="border-b flex justify-between py-3 px-5 xs:px-3">
                                    <h4 className="text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000]">{item.title}</h4>
                                    <h4 className="text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000] xs:text-right">{item.value}</h4>
                                </div>

                            )
                            )

                        }
                    </div>
                </div>

                <div className="">
                    <div className="text-center py-10">
                        <h3 className="text-[30px] sm:text-[24px] xs:text-[24px] font-semibold font-Poppins text-[#000000]">Bonus System in 10 Stages, Max: 50% Bonus</h3>
                    </div>
                    <div className="border-t border-l border-r  ">
                        <div className="flex justify-between xs:items-center xs:text-center py-4 px-5 xs:px-0 xs:pr-3 border-b bg-[#ECF4FF]">
                            <h3 className="w-[44%] xs:w-[40%] text-[18px] sm:text-[14px] xs:text-[14px] font-semibold font-Poppins text-[#000000]">Presale Stages</h3>
                            <h3 className="w-[20%] text-[18px] sm:text-[14px] xs:text-[14px] font-semibold font-Poppins text-[#000000]">Bonus %</h3>
                            <h3 className="w-[30%] text-end text-[18px] sm:text-[14px] xs:text-[14px] font-semibold font-Poppins text-[#000000]">Days</h3>
                        </div>

                        {

                            BonusData.map((item, id) => (
                                <div className="border-b flex justify-between py-3 px-5 xs:px-3 xs:test-center">
                                    <h4 className="pl-4 xs:pl-0 w-[44%] text-[16px] font-[400] font-Poppins text-[#000000] sm:text-[14px] xs:text-[14px]">{item.title}</h4>
                                    <h4 className="pl-4 xs:pl-0 w-[21%] text-[16px] font-[400] font-Poppins text-[#000000] sm:text-[14px] xs:text-[14px]">{item.bonusValue}</h4>
                                    <h4 className="pl-4 xs:pl-0 w-[30%] text-end text-[16px] font-[400] font-Poppins text-[#000000] sm:text-[14px] xs:text-[14px]">{item.value}</h4>
                                </div>

                            )
                            )

                        }
                    </div>
                </div >

            </div>

        </>
    )
}
export default FairLaunch;