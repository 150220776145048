import React, { useState, useEffect } from "react";
import NavBar from "./Navbar/Navbar";
import FooterNav from "./FooterNav/Navbar";
import App from "../App";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from "./Footer/Footer";
import Claim from "../Pages/Claim"
import ClaimNavBar from "../Pages/Claim/component/ClaimNav";
import MyPage from "../Pages/Claim/MyPage";
import ClaimHome from "../Pages/Claim/ClaimPage";
import { useSelector, useDispatch } from 'react-redux'
import { useMoralis } from 'react-moralis';
import { getUserData, updateUserData } from '../app/feature/userData/userDataSlice'



const Layout = () => {

    const userData = useSelector((state) => state.userData.value)
    const dispatch = useDispatch()
    const { isAuthenticated, user } = useMoralis();

    const [open, setOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false)
    const [claim, setClaim] = useState(false); // when you set claim true then you are in claims page , else pevious one to test just add true in this  state you will see what happen here

    const handleOpen = () => {
        setOpen(!open);

    }
    const handleClaimOpen = () => {
        setOpen(!true)
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleClaim = () => {
        setClaim(!claim);
    }

    const handleNavbarMenu = () => {
        setMenuOpen(!menuOpen)
    }



    useEffect(() => {
        if (isAuthenticated) {
            dispatch(updateUserData(JSON.stringify(user.attributes)))
        }
    }, [isAuthenticated])



    return (
        <Router>

            <div className="container-fluid w-full">
                <div className="flex w-full">
                    <div className="w-full">
                        <div className="w-full">
                            {
                                window.location.pathname === '/mypage' || window.location.pathname === '/claimhome' ?
                                    <ClaimNavBar open={open} handleOpen={handleOpen} handleClaim={handleClaim} />
                                    :
                                    <NavBar open={open} handleOpen={handleOpen} handleClaim={handleClaim} handleNavbarMenu={handleNavbarMenu} handleClose={handleClose} menuOpen={menuOpen} />
                            }
                            <div >
                                <App handleOpen={handleOpen} handleNavbarMenu={handleNavbarMenu} open={open} />
                                {
                                    window.location.pathname === '/mypage' || window.location.pathname === '/claimhome' ?
                                        ""
                                        : <>
                                            <Footer />y
                                        </>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Router >

    )
}
export default Layout;

