import React from "react"
import { useTranslation } from "react-i18next";



const Button = ({ text, wallet, onClick, classes, imageClass, }) => {
    const {t} = useTranslation()
    return (
        <div className="flex">
            <button onClick={onClick} type="button" className={` ${classes}`}>
                {wallet &&
                    <img className={imageClass} src={wallet} alt="" />
                }
                <span className="">
                    {t(text)}
                </span>

            </button>

        </div>
    )
}
export default Button;