
// export const usdcContract = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
// // export const crowdsaleContract = "0x9d410814e567c7F6704ae7964AE262B2b4c050d5";
// export const crowdsaleContract = "0x25869fab4b53bdb22ba1b4e56220fa878f45e588";
// export const bnbcrowdsaleContract = "0x62650fb0B0D93CBCcf62F296E8fC4127c27831c3";
// export const nftContract = "0x10c2b05719EE0563A55721114E38A5C7c5A133b8"
// export const currentChain = "mainnet"
// export const bnbcurrentChain = "bsc"




export const usdcContract = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
// export const crowdsaleContract = "0x9d410814e567c7F6704ae7964AE262B2b4c050d5";
export const crowdsaleContract = "0x4c95bead189d5bf84f0cabc8fc68f67ebe53f785";
export const bnbcrowdsaleContract = "0x2f87de83E849957dbdC104DCd0430F0F2A688850";

export const crowdsaleContractOld = "0x2EBc6687565A1D3F76507683EE84c2D605911ce5";
export const bnbcrowdsaleContractOld = "0x0cd61825b3fc949682befac5b1e44a184ca8c8c1";
export const nftContract = "0x10c2b05719EE0563A55721114E38A5C7c5A133b8"
export const currentChain = "mainnet"
export const bnbcurrentChain = "bsc"
export const currentChainOld = "mainnet"
export const bnbcurrentChainOld = "bsc"

