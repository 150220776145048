import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';


import CC from "../assects/images/certik.png"
import SP from "../assects/images/sp.svg"



const SecureSection = () => {
    const {t} = useTranslation();
    const SecureData = [
        {
            id: 1,
            image: SP,
            title: t("100% Security by SolidProof"),
            subtitle: t('Contract code fully audited by SolidProof and shown to be 100% secure.'),
        },
        {
            id: 2,
            image: CC,
            title: t('Team Verified by CertiK'),
            subtitle: t('Entire team KYC verified by CertiK, view certificate here.'),
        },
    
    ]
    return (
        <div className=" py-16 2xl:px-32 xl:px-32 lg:px-32 md:px-12 sm:px-6 px-6" >
            <div className="text-center">
                <h2 className="font-[700] font-[Poppins] text-[30px]">{t("100% Secure")}</h2>
            </div>
            <div className="2xl:grid 2xl:grid-cols-2 xl:grid xl:grid-cols-2 lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 sm:grid sm:grid-cols-1 grid grid-cols-1 gap-y-4 gap-x-5 pt-10">
                {
                    SecureData.map((item, id) => (
                        <div key={id} className="border border-[#cacaca] bg-white  pt-3 py-4 space-y-3 rounded-lg text-center">
                            <div className="flex justify-center">
                                <img src={item.image} alt="" width={50} />

                            </div>
                            <div className="px-10">
                                <h3 className="font-[600] font-[Poppins] text-[19px]">{item.title}</h3>
                                {item.id === 2 ?
                                    <p className="font-[400] font-[Poppins] text-[13px]">{t("Entire team KYC verified by CertiK, view certificate")} <a href="https://www.certik.com/projects/metropoly" style={{ textDecoration: "underline" }}>{t("here")}</a>.
                                    </p>

                                    :
                                    <>
                                        <p className="font-[400] font-[Poppins] text-[13px]">{item.subtitle}</p>
                                        <p className="font-[400] font-[Poppins] text-[13px]">{t("View audit")} <a href="https://app.solidproof.io/projects/metropoly?audit_id=143" style={{ textDecoration: "underline" }}>{t("here")}</a>.</p>

                                    </>
                                }

                            </div>

                        </div>
                    ))
                }
            </div>

        </div>
    )
}
export default SecureSection 
