import React, { useState, useEffect, useCallback } from "react";

import 'react-toastify/dist/ReactToastify.css';
import { WalletDropdown } from '../components/Dropdowns';

// import metroLogo from "../assects/icons/userlogo.png"

import metroLogo from "../assects/images/CO.svg";
import { useChain, useMoralis, useMoralisSubscription, useMoralisWeb3Api, useMoralisQuery, useWeb3ExecuteFunction } from 'react-moralis';
import { SwapWidget, Theme } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'
import ved from "../assects/video/course-video.mp4"
import Iicon from "../assects/images/Iicon.png"
import exchange from "../assects/images/exchange.png"
import Button from "../components/Button/button";
import Dollar from "../assects/images/USDT.png";
import FAQ2 from "../assects/images/faq1.png";
import SelectDropdown from "../components/Select";
import SelectMetro from "../components/SelectMetro";
import ETH from "../assects/images/ETH.png";
import BNB from "../assects/images/BNB.png";
import Loading from "./loading";
import thumb from "../assects/images/thumb.png"
import { notify, notifyInfo, notifyWarning, notifyWithPromise } from "../utils/notify"
import { update, updateApproval, updateError } from "../utils/notify"
import { usdcContract, crowdsaleContract, currentChain, bnbcrowdsaleContract, bnbcurrentChain } from "../utils/constants"
import { useTranslation } from "react-i18next";

const theme: Theme = {
    borderRadius: 0,
    accent: '#0072BA',
    primary: '#0072BA',
    secondary: '#0072BA',
    dialog: '#FFFFFF',
}


const people = [
    {
        id: 1,
        name: 'USDT',
        avatar: Dollar,
    },
    {
        id: 2,
        name: 'ETH',
        avatar: ETH,
    },
    {
        id: 3,
        name: 'BNB',
        avatar: BNB,
    }
]

const metroWord = [
    {
        id: 1,
        name: 'METRO',
        avatar: metroLogo,
    }
]



const contributeStages = [1667142000, 1667574000, 1668006000, 1668438000,
    1668870000, 1669302000, 1669734000, 1670166000, 1670598000, 1671030000, 1671462000]

const ProductDetalis = ({ handleOpen, range, handleClaim, open }) => {
    // const handle = localStorage.getItem('handleOpen')
    // console.log(handle, 'handle')

    const { t } = useTranslation();

    const Web3Api = useMoralisWeb3Api();
    const { Moralis } = useMoralis();
    const [ethPrice, setEthPrice] = useState(1);
    const [ethPriceInUSD, setEthPriceInUSD] = useState(0);
    const [BNBPrice, setBnbPrice] = useState(1);
    const [BNBPriceInUSD, setBnbPriceInUSD] = useState(0);
    const [metroPrice, setMetroPrice] = useState(0);
    const [inputData, setInputData] = useState(range);
    const [coupon, setCoupon] = useState("ref");
    const [hasBonus, setHasBonus] = useState(false);
    const [decReady, setDecReady] = useState(false);
    const [inputValue, setinputValue] = useState(1000);
    const [hasDec, setHasDec] = useState(false);
    const [latestParticipantsETH, setLatestParticipantsETH] = useState(0);
    const [latestParticipantsBNB, setLatestParticipantsBNB] = useState(0);
    const [latestContributionETH, setLatestContributionETH] = useState(0);
    const [latestContributionBNB, setLatestContributionBNB] = useState(0);
    const [participantsNew, setParticipantsNew] = useState(0);
    const [participantsNewForBNB, setParticipantsNewForBNB] = useState(0);
    const [contributionForNew, setContributionForNew] = useState(0);
    const [contributionForNewBNB, setContributionNewForBNB] = useState(0);
    const [estimateAmount, setEstimateAmount] = useState(1000);
    const [buyBTNStatus, setbuyBTNStatus] = useState(false);
    const [provider, setProvider] = useState("");
    const [buyBtn, setBuyBTN] = useState(t("Presale is completed"));
    const hoursMinSecs = { days: 0, hours: 0, minutes: 0, seconds: 0 }
    const { days = 0, hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[dys, hrs, mins, secs], setTime] = useState([days, hours, minutes, seconds]);
    const { switchNetwork, chainId } = useChain();

    const [selected, setSelected] = useState(people[0])
    const [selectedMetro, setSelectedMetro] = useState(metroWord[0])
    const [pop, setPop] = useState(false);
    const [pop2, setPop2] = useState(false);
    const [timeState, setTimeState] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [newPercentage, setNewPercentage] = useState(0);
    const [newPercentageBNB, setNewPercentageBNB] = useState(0);
    const [userAddress, setUserAddress] = useState("");
    const [flag, setFlag] = useState(false);

    function getURLParams() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        const result = {};
        for (const [key, value] of params.entries()) {
            result[key] = value;
        }

        return result;
    }
    const getBalance = async () => {
        const result = await Web3Api.account.getNativeBalance({
            chain: currentChain
        })
        return result.balance
    }

    const getBalanceBNB = async () => {
        const result = await Web3Api.account.getNativeBalance({
            chain: bnbcurrentChain
        })
        return result.balance
    }

    const toastId = React.useRef(1);

    const { isAuthenticated, user, isWeb3EnableLoading, isWeb3Enabled, enableWeb3, isInitialized, isInitializing, initialize } = useMoralis();

    const executeFunction = useWeb3ExecuteFunction();

    useMoralisSubscription("LatestBNBContribution", q => q, [], {
        onUpdate: data => {
            if (data.attributes.contributor === userAddress)
                notify("You contributed " + data.attributes.amount / 1000000 + " USD", toastId)
            objectIdQuery();
        }
    });

    useMoralisSubscription("LatestETHContribution", q => q, [], {
        onUpdate: data => {
            if (data.attributes.contributor === userAddress)
                notify("You contributed " + data.attributes.amount / 1000000 + " USD", toastId)
            objectIdQuery();
        }
    });


    const LatestContributorsETH = useMoralisQuery(
        "LatestETHContribution",
        (query) => query.limit(1000),
        []
    );
    // (query) => query.equalTo("contributor","0x53b3a5ebe65429252464f29f4ef71b7ffa28dc04").limit(3500),

    const LatestBNBContributors = useMoralisQuery(
        "LatestBNBContribution",
        (query) => query.limit(3500),
        []
    );
    const objectIdQuery = useCallback(() => {
        LatestBNBContributors.fetch({
            onSuccess: (participantsResult) => {

                let par = participantsResult.length - 285
                setParticipantsNewForBNB(par)

                // const data = participantsResult.map((result) => result.attributes);
                // saveDataAsExcelFile(data, 'LatestETHContribution.xlsx');
                let test = 0;
                participantsResult.map((index) => {

                    test += Number(index.attributes.amount);
                })
                setContributionNewForBNB(Number(((test - 58906570000) / 1000000).toFixed(2)))

                let perc2 = ((test - 58906570000) / 1000000) / 1500000 * 100;
                setNewPercentageBNB(Number(perc2.toFixed(0)))
            },
            onError: (error) => {
            },
        });
        LatestContributorsETH.fetch({
            onSuccess: (participantsResult) => {
                setParticipantsNew(participantsResult.length)
                let test = 0;
                participantsResult.map((index) => {

                    test += Number(index.attributes.amount);
                })
                setContributionForNew(Number(((test) / 1000000).toFixed(2)))

                let perc2 = ((test) / 1000000) / 1500000 * 100;
                setNewPercentage(Number(perc2.toFixed(0)))
            },
            onError: (error) => {
            },
        });

    }, [fetch]);



    const getEstimateAmount = (value) => {

        setEstimateAmount(value * 11);
    }

    function dhm(ms) {
        const days = Math.floor(ms / (24 * 60 * 60));
        const daysms = ms % (24 * 60 * 60);
        const hours = Math.floor(daysms / (60 * 60));
        const hoursms = ms % (60 * 60);
        const minutes = Math.floor(hoursms / (60));
        const minutesms = ms % (60);
        const sec = Math.floor(minutesms);
        setTime([days, hours, minutes, sec])

        return "Days: " + days + " Hours: " + hours + " Minutes: " + minutes + " Seconds: " + sec;
    }

    const resetBtn = async () => {
        setBuyBTN(t("Presale is completed"))
        setbuyBTNStatus(false);
    }

    const handleWarning = async (e, errorId) => {

        resetBtn()

        switch (e.code) {
            case "4001":
                notifyWarning("User denied transaction.", errorId)
                break;
            case 4001:
                notifyWarning("User denied transaction.", errorId)
                break;
            case "INSUFFICIENT_FUNDS":
                notifyWarning("You do not have enough fund.", errorId)
                break;
            case "UNPREDICTABLE_GAS_LIMIT":
                notifyWarning("Please check if you have enough funds and try again.", errorId)
                break;
            case "CALL_EXCEPTION":

                notifyWarning("Transaction Failed. Please try again.", errorId)
                break;

            default:
                notifyWarning("Transaction Failed. Please try again.", errorId)
                break;

        }
    }
    const handleError = async (e, errorId) => {

        resetBtn()
        switch (e.code) {
            case "4001":
                updateError("User denied transaction.", errorId)
                break;
            case 4001:
                updateError("User denied transaction.", errorId)
                break;
            case "INSUFFICIENT_FUNDS":
                updateError("You do not have enough fund.", errorId)
                break;
            case "CALL_EXCEPTION":

                updateError("Transaction Failed. You can either add more gas fee or try again later.", errorId)
                break;

            default:
                updateError("Transaction Failed. Please try again.", errorId)
                break;

        }
    }


    const buyUsingUSDC = async () => {

        if (chainId !== "0x1") {
            await switchNetwork("0x1");
            resetBtn()
        }
        setBuyBTN("Contributing USDT...")
        setbuyBTNStatus(true);

        let latestCode = coupon.toLowerCase();
        if (latestCode === "")
            latestCode = "ref"
        let ref = getURLParams().ref
        console.log(ref)
        if (ref === undefined)
            ref = "0x0000000000000000000000000000000000000000"
        const contributeUSDCOptions = {
            abi: [
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountInUSD",
                            "type": "uint256"
                        },
                        {
                            "internalType": "string",
                            "name": "code",
                            "type": "string"
                        },
                        {
                            "internalType": "address",
                            "name": "_referredBy",
                            "type": "address"
                        }
                    ],
                    "name": "contribute",
                    "outputs": [],
                    "stateMutability": "payable",
                    "type": "function"
                }
            ],
            contractAddress: crowdsaleContract,
            functionName: "contribute",
            chain: currentChain,
            provider: provider,
            params: {
                amountInUSD: inputValue * 1000000,
                code: latestCode,
                _referredBy: ref
            },
        };
        try {
            notifyWithPromise("Waiting for the user to transfer funds.", toastId)
            const transaction = await Moralis.executeFunction(contributeUSDCOptions)
            updateApproval("Waiting for confirmation...", toastId)
            await transaction.wait();
            update("Confirmed!", toastId)
            resetBtn()
        } catch (e) {

            updateError("Transaction Failed. Please try again.", toastId)
            handleError(e, toastId)
        }
    }



    const checkUSDCBalance = async () => {

        if (chainId !== "0x1") {
            await switchNetwork("0x1");
            resetBtn()
        }
        const readOptions = {
            abi: [{
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }],
            contractAddress: usdcContract,
            functionName: "balanceOf",
            chain: currentChain,
            provider: provider,
            params: {
                account: userAddress
            }
        };
        await executeFunction.fetch({ params: readOptions }).then(async (message) => {
            if (parseInt(message._hex) < inputValue * 1000000) {
                notifyWarning("You do not have enough USDT.", toastId)
                resetBtn()

            }
            else {
                approveUSDC();
            }
        })

    }

    const approveUSDC = async () => {

        if (chainId !== "0x1") {
            await switchNetwork("0x1");
            resetBtn()
        }
        const readOptions = {
            abi: [
                {
                    "constant": true,
                    "inputs": [
                        {
                            "name": "_owner",
                            "type": "address"
                        },
                        {
                            "name": "_spender",
                            "type": "address"
                        }
                    ],
                    "name": "allowance",
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256"
                        }
                    ],
                    "payable": false,
                    "stateMutability": "view",
                    "type": "function"
                }],
            contractAddress: usdcContract,
            functionName: "allowance",
            chain: currentChain,
            provider: provider,
            params: {
                _owner: userAddress,
                _spender: crowdsaleContract
            }
        };

        await executeFunction.fetch({ params: readOptions }).then(async (message) => {
            if (message) {
                if (parseInt(message._hex) < inputValue * 1000000) {

                    let allowanceNeededLeft = (inputValue * 1000000) - parseInt(message._hex)
                    const approveOptions = {
                        abi: [
                            {
                                "constant": false,
                                "inputs": [
                                    {
                                        "name": "_spender",
                                        "type": "address"
                                    },
                                    {
                                        "name": "_value",
                                        "type": "uint256"
                                    }
                                ],
                                "name": "approve",
                                "outputs": [
                                    {
                                        "name": "",
                                        "type": "bool"
                                    }
                                ],
                                "payable": false,
                                "stateMutability": "nonpayable",
                                "type": "function"
                            }],
                        contractAddress: usdcContract,
                        functionName: "approve",
                        chain: currentChain,
                        provider: provider,
                        params: {
                            _spender: crowdsaleContract,
                            _value: allowanceNeededLeft
                        }
                    };

                    notifyWithPromise("Please allow the contract to use your USDT.", toastId)
                    await Moralis.executeFunction(approveOptions).then(async (transaction) => {
                        setBuyBTN("Approving USDT...")
                        setbuyBTNStatus(true);
                        updateApproval("Waiting for approval confirmation...", toastId)
                        await transaction.wait();
                        update("Approved!", toastId);
                        setBuyBTN("Contribute USDT")
                        setbuyBTNStatus(false);
                    }).catch(async (e) => {

                        handleError(e, toastId)
                    });
                } else {
                    buyUsingUSDC();
                }
            } else {
                notifyWarning("Please make sure your wallet is connected.", toastId)
                resetBtn()
            }
        });
    }

    const buyUsingETH = async () => {
        try {

            let latestCode = coupon.toLowerCase();
            if (latestCode === "")
                latestCode = "ref"
            let ref = getURLParams().ref
            console.log(ref)
            if (ref === undefined)
                ref = "0x0000000000000000000000000000000000000000"
            notifyInfo("Please make sure you are connected to ETH Mainnet.")
            if (chainId !== "0x1") {
                await switchNetwork("0x1");
                resetBtn()
            }
            let bal = await getBalance();
            if (bal < inputData * 10 ** 18) {
                notifyWarning("You do not have enough funds.", toastId)
                resetBtn()
            }
            else {
                const contributeETHOptions = {
                    abi: [{
                        "inputs": [
                            {
                                "internalType": "uint256",
                                "name": "amountInUSD",
                                "type": "uint256"
                            },
                            {
                                "internalType": "string",
                                "name": "code",
                                "type": "string"
                            },
                            {
                                "internalType": "address",
                                "name": "_referredBy",
                                "type": "address"
                            }
                        ],
                        "name": "contribute",
                        "outputs": [],
                        "stateMutability": "payable",
                        "type": "function"
                    }
                    ],
                    contractAddress: crowdsaleContract,
                    functionName: "contribute",
                    chain: currentChain,
                    provider: provider,
                    params: {
                        amountInUSD: 0,
                        code: latestCode,
                        _referredBy: ref
                    },
                    msgValue: (inputData * 10 ** 18).toString()
                };
                try {
                    notifyWithPromise("Waiting for the user to transfer funds.", toastId)
                    await Moralis.executeFunction(contributeETHOptions).then(async (transaction) => {
                        updateApproval("Waiting for confirmation...", toastId)
                        await transaction.wait();
                        update("Confirmed!", toastId)
                        resetBtn();
                    });
                }
                catch (e) {
                    handleError(e, toastId)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }


    const buyUsingBNB = async () => {

        notifyInfo("Please make sure you are connected to BSC.")
        // if (chainId !== "0x38") {
        //     await switchNetwork("0x38");
        //     resetBtn()
        // }
        // if (provider === "walletconnect") {
        //     await enableWeb3({
        //         provider: "walletconnect",
        //         chain: bnbcurrentChain
        //     })
        //     setProvider("walletconnect");

        //     if (chainId !== "0x38") {
        //         try {
        //             await authenticate({
        //                 signingMessage: "Welcome to Metropoly!", provider: "walletconnect", chainId: 56
        //             })
        //         } catch (e) { console.log(e) }
        //     }
        // } else
        // const readEstimatedETHOptions = {
        //     contractAddress: bnbcrowdsaleContract,
        //     functionName: "getEstimatedETHforUSD",
        //     chain: bnbcurrentChain,
        //     provider: provider,
        //     abi: [
        //         {
        //             "inputs": [
        //                 {
        //                     "internalType": "uint256",
        //                     "name": "usdAmount",
        //                     "type": "uint256"
        //                 }
        //             ],
        //             "name": "getEstimatedETHforUSD",
        //             "outputs": [
        //                 {
        //                     "internalType": "uint256[]",
        //                     "name": "",
        //                     "type": "uint256[]"
        //                 }
        //             ],
        //             "stateMutability": "view",
        //             "type": "function"
        //         }
        //     ],
        //     params: {
        //         usdAmount: Moralis.Units.ETH(BNBPriceInUSD)
        //     }
        // }
        let bal = await getBalanceBNB();
        if (bal < inputData * 10 ** 18) {
            notifyWarning("You do not have enough funds.", toastId)
            resetBtn()
        }
        else {
            let latestCode = coupon.toLowerCase();
            if (latestCode === "")
                latestCode = "ref"
            let ref = getURLParams().ref
            console.log(ref)
            if (ref === undefined)
                ref = "0x0000000000000000000000000000000000000000"
            const contributeBNBOptions = {
                abi: [{
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountInUSD",
                            "type": "uint256"
                        },
                        {
                            "internalType": "string",
                            "name": "code",
                            "type": "string"
                        },
                        {
                            "internalType": "address",
                            "name": "_referredBy",
                            "type": "address"
                        }
                    ],
                    "name": "contribute",
                    "outputs": [],
                    "stateMutability": "payable",
                    "type": "function"
                }
                ],
                contractAddress: bnbcrowdsaleContract,
                functionName: "contribute",
                chain: bnbcurrentChain,
                provider: provider,
                params: {
                    amountInUSD: 0,
                    code: latestCode,
                    _referredBy: ref
                },
                msgValue: (inputData * 10 ** 18).toString()
            };
            try {
                notifyWithPromise("Waiting for the user to transfer funds.", toastId)
                await Moralis.executeFunction(contributeBNBOptions).then(async (transaction) => {
                    updateApproval("Waiting for confirmation...", toastId)
                    await transaction.wait();
                    update("Confirmed!", toastId)
                    resetBtn();
                });
            }
            catch (e) {
                handleError(e, toastId)
            }
        }
    }

    const handleBuy = async () => {

        notifyInfo("Presale is completed")
        // setBuyBTN("Processing...");
        // setbuyBTNStatus(true);

        // if (buyBtn === "Contribute USDC") {
        //     notifyInfo("Please make sure you are connected to ETH Mainnet.")
        //     buyUsingUSDC()
        //     return;
        // }

        // try {

        //     if (selected.id === 1) {
        //         if (inputValue > 0) {
        //             notifyInfo("Please make sure you are connected to ETH Mainnet.")
        //             checkUSDCBalance();
        //         }
        //         else {
        //             notifyWarning("Must be greater than 0", toastId)
        //             resetBtn();
        //         }
        //     }

        //     if (selected.id === 2) {
        //         if (inputValue > 0) {
        //             buyUsingETH();
        //         }
        //         else {
        //             notifyWarning("Must be greater than 0", toastId)
        //             resetBtn();
        //         }
        //     }

        //     if (selected.id === 3) {
        //         if (inputValue > 0) {
        //             buyUsingBNB();
        //         }
        //         else {
        //             notifyWarning("Must be greater than 0", toastId)
        //             resetBtn();
        //         }
        //     }
        // }
        // catch (e) {
        //     handleWarning(e)
        // }
    }

    const handleCoupon = (code) => {
        if (coupon.toLowerCase() === "berlin") {
            notify("The bonus is applied!")
        } else
            if (coupon.toLowerCase() === "launch20") {
                notify("The bonus is applied!")
            }
            else
                if (coupon.toLowerCase() === "aurelie20") {
                    notify("The bonus is applied!")
                }
                else {
                    notifyWarning("Code not valid.")
                    setCoupon("ref")
                }
    }
    const handleCurrency = async (selected) => {
        setSelected(selected)

        if (selected.id === 1 || selected.id === 2) {
            await switchNetwork("0x1");
            if (selected.id === 2)
                getEthPrice(inputValue)
        }
        if (selected.id === 3) {
            await switchNetwork("0x38");
            getBnbPrice(inputValue)
        }

        if (selected.id === 1)
            getEstimateAmount(inputValue)
        if (selected.id === 2)
            getEstimateAmount(ethPriceInUSD)

        if (selected.id === 3)
            getEstimateAmount(BNBPriceInUSD)

        resetBtn()
    }
    const tick = () => {

        let today = new Date();
        let timeLeft
        for (let x = 0; x < contributeStages.length; x++) {
            if (contributeStages[x] > (today.getTime() / 1000).toFixed(0)) {
                timeLeft = contributeStages[x] - (today.getTime() / 1000).toFixed(0)
                setTimeState(x);
                dhm(timeLeft)
                return
            }
        }
        if (hrs === 0 && mins === 0 && secs === 0) {

        }
    };

    // const handleUpdate2 = async () => {

    //     console.log("batch two")

    //     console.log(addressOldETHBatchTwo.length)
    //     console.log(amountOldETHBatchTwo.length)
    //     console.log(stagesOldETHBatchTwo.length)
    //     console.log(codeOldETHBatchTwo.length)
    //     // const middleIndex = Math.ceil(codeOld.length / 2);

    //     // const firstHalf = codeOld.splice(0, middleIndex);
    //     // const secondHalf = codeOld.splice(-middleIndex);

    //     // console.log(" First Half: ", JSON.stringify(firstHalf));  // [1, 2, 3]
    //     // console.log(" Second Half: ", JSON.stringify(secondHalf)); // [4, 5, 6]
    //     // console.log(firstHalf.length);
    //     // console.log(secondHalf.length);

    //     const readOptions = {
    //         abi: [{
    //             "inputs": [
    //                 {
    //                     "internalType": "address[]",
    //                     "name": "addrs",
    //                     "type": "address[]"
    //                 },
    //                 {
    //                     "internalType": "uint256[]",
    //                     "name": "amount",
    //                     "type": "uint256[]"
    //                 },
    //                 {
    //                     "internalType": "uint256[]",
    //                     "name": "stage",
    //                     "type": "uint256[]"
    //                 },
    //                 {
    //                     "internalType": "string[]",
    //                     "name": "code",
    //                     "type": "string[]"
    //                 }
    //             ],
    //             "name": "updateContributors",
    //             "outputs": [],
    //             "stateMutability": "nonpayable",
    //             "type": "function"
    //         }],
    //         contractAddress: bnbcrowdsaleContract,
    //         functionName: "updateContributors",
    //         chain: bnbcurrentChain,
    //         provider: provider,
    //         params: {
    //             addrs: addressOldETHBatchTwo,
    //             amount: amountOldETHBatchTwo,
    //             stage: stagesOldETHBatchTwo,
    //             code: codeOldETHBatchTwo
    //         }
    //     };
    //     await executeFunction.fetch({ params: readOptions }).then(async (message) => {
    //         console.log(message)
    //     })

    // }

    // const handleUpdate = async () => {
    //     // const list = [1, 2, 3, 4, 5, 6, 7];
    //     // const middleIndex = Math.ceil(list.length / 2);

    //     // const firstHalf = list.splice(0, middleIndex);   
    //     // const secondHalf = list.splice(-middleIndex);

    //     // console.log(firstHalf);  // [1, 2, 3]
    //     // console.log(secondHalf); // [4, 5, 6]
    //     // console.log(list);  

    //     console.log("batch one")
    //     console.log(addressOldETHBatchOne.length)
    //     console.log(amountOldETHBatchOne.length)
    //     console.log(stagesOldETHBatchOne.length)
    //     console.log(codeOldETHBatchOne.length)

    //     // const middleIndex = Math.ceil(codeOldETH.length / 2);

    //     // const firstHalf = codeOldETH.splice(0, middleIndex);
    //     // const secondHalf = codeOldETH.splice(-middleIndex);

    //     // console.log(" First Half: ", JSON.stringify(firstHalf));  // [1, 2, 3]
    //     // console.log(" Second Half: ", JSON.stringify(secondHalf)); // [4, 5, 6]
    //     // console.log(firstHalf.length);
    //     // console.log(secondHalf.length);

    //     const readOptions = {
    //         abi: [{
    //             "inputs": [
    //                 {
    //                     "internalType": "address[]",
    //                     "name": "addrs",
    //                     "type": "address[]"
    //                 },
    //                 {
    //                     "internalType": "uint256[]",
    //                     "name": "amount",
    //                     "type": "uint256[]"
    //                 },
    //                 {
    //                     "internalType": "uint256[]",
    //                     "name": "stage",
    //                     "type": "uint256[]"
    //                 },
    //                 {
    //                     "internalType": "string[]",
    //                     "name": "code",
    //                     "type": "string[]"
    //                 }
    //             ],
    //             "name": "updateContributors",
    //             "outputs": [],
    //             "stateMutability": "nonpayable",
    //             "type": "function"
    //         }],
    //         contractAddress: bnbcrowdsaleContract,
    //         functionName: "updateContributors",
    //         chain: bnbcurrentChain,
    //         provider: provider,
    //         params: {
    //             addrs: addressOldETHBatchOne,
    //             amount: amountOldETHBatchOne,
    //             stage: stagesOldETHBatchOne,
    //             code: codeOldETHBatchOne
    //         }
    //     };
    //     await executeFunction.fetch({ params: readOptions }).then(async (message) => {
    //         console.log(message)
    //     })

    // }

    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    useEffect(() => {
        setSelected(people[0])
        setInputData(range)
        setinputValue(range)
        getEstimateAmount(range)
    }, [range]);

    useEffect(() => {

        if (selected.id === 1)
            getEstimateAmount(inputValue)
        if (selected.id === 2)
            getEstimateAmount(ethPriceInUSD)
        if (selected.id === 3)
            getEstimateAmount(BNBPriceInUSD)
    }, [selected.id, ethPriceInUSD, BNBPriceInUSD, inputValue])

    const getEthPrice = useCallback(async (value) => {
        setEthPriceInUSD("Fetching the best price...")
        setbuyBTNStatus(true)

        const resultInUSD = await Web3Api.token.getTokenPrice({
            address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
        })

        setEthPrice(truncateToDecimals(resultInUSD.usdPrice));
        setEthPriceInUSD(truncateToDecimals(resultInUSD.usdPrice * value));
        // setEthPriceInUSD((resultInUSD.usdPrice * value).toFixed(0));
        getEstimateAmount(truncateToDecimals(resultInUSD.usdPrice * value))
        setbuyBTNStatus(false)
    }, [Web3Api.token, ethPrice])


    const getBnbPrice = useCallback(async (value) => {
        setBnbPriceInUSD("Fetching the best price...")
        setbuyBTNStatus(true)

        const resultInUSD = await Web3Api.token.getTokenPrice({
            address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            chain: "bsc"
        })


        setBnbPrice(truncateToDecimals(resultInUSD.usdPrice));

        setBnbPriceInUSD((truncateToDecimals(resultInUSD.usdPrice * value)));

        getEstimateAmount(truncateToDecimals(resultInUSD.usdPrice * value))
        setbuyBTNStatus(false)
    }, [Web3Api.token, BNBPrice])

    useEffect(() => {
        if (isAuthenticated) {
            setUserAddress(user.get("ethAddress"))
        }
        getEstimateAmount(inputValue)
    }, [isAuthenticated, selected.id, metroPrice])



    // useEffect(() => {
    //     (async () => {
    //         try {
    //             if (!flag) {
    //                 async function fetchData() {
    //                     if (isAuthenticated)
    //                         setUserBalance(getBalance());
    //                     objectIdQuery();

    //                     // getCurrentPrice();
    //                 }
    //                 if (!isInitialized && !isInitializing) {
    //                     initialize({
    //                         appId: "BapdkIiLR6HMorA8MiVgOGCCp8dzCQyTzdchsRyP",
    //                         serverUrl: "https://osrc6bvftjcv.grandmoralis.com:2053/server"
    //                     });

    //                 }
    //                 if (isInitialized && !isWeb3Enabled && !isWeb3EnableLoading) {
    //                     setFlag(true)
    //                     try {
    //                         if (window.localStorage.walletconnect) {
    //                             await enableWeb3({
    //                                 provider: "walletconnect",
    //                                 chain: currentChain
    //                             })
    //                             setProvider("walletconnect");
    //                         } else
    //                             if (window.ethereum) {
    //                                 setProvider("metamask");
    //                                 await enableWeb3()
    //                             }
    //                     } catch (e) {
    //                         notifyInfo("Web3 not found, please use Metamask or Trust Wallet browser.")
    //                     }
    //                 }

    //                 if (isInitialized && isWeb3Enabled) {
    //                     fetchData();
    //                     console.log("TESTTTTTTTTTTTTTTT")
    //                 }
    //                 objectIdQuery();
    //             }
    //         } catch (error) {
    //             console.log("error", error);
    //             notifyInfo("Please navigate using a DApp browser.")
    //             setFlag(true)
    //         }
    //     }
    //     )()
    // }, [isWeb3Enabled, isInitialized, chainId, isAuthenticated, isInitializing, isWeb3EnableLoading])


    function truncateToDecimals(num, dec = 2) {
        const calcDec = Math.pow(10, dec);
        return Math.trunc(num * calcDec) / calcDec;
    }

    // const getParticipants = async () => {

    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: currentChain,
    //             address: crowdsaleContract,
    //             function_name: "getTotalParticipants",
    //             abi: [{
    //                 "inputs": [],
    //                 "name": "getTotalParticipants",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {}
    //         })
    //         .then((response) => {
    //             console.log(response)
    //             setLatestParticipantsETH(response)
    //         });

    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: bnbcurrentChain,
    //             address: bnbcrowdsaleContract,
    //             function_name: "getTotalParticipants",
    //             abi: [{
    //                 "inputs": [],
    //                 "name": "getTotalParticipants",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {}
    //         })
    //         .then((response) => {
    //             console.log(response)
    //             setLatestParticipantsETH(response)
    //         });
    // }

    // const getContributions = async () => {

    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: currentChain,
    //             address: crowdsaleContract,
    //             function_name: "getTotalContribution",
    //             abi: [{
    //                 "inputs": [],
    //                 "name": "getTotalContribution",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {}
    //         })
    //         .then((response) => {
    //             setLatestContributionETH(response)
    //             console.log(response)
    //         });

    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: bnbcurrentChain,
    //             address: bnbcrowdsaleContract,
    //             function_name: "getTotalContribution",
    //             abi: [{
    //                 "inputs": [],
    //                 "name": "getTotalContribution",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {}
    //         })
    //         .then((response) => {
    //             setLatestContributionBNB(response)
    //             console.log(response)
    //         });

    // }
    const MY_TOKEN_LIST = [
        {
            "name": "Dai Stablecoin",
            "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
            "symbol": "DAI",
            "decimals": 18,
            "chainId": 1,
            "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
        },
        {
            "name": "Tether USD",
            "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            "symbol": "USDT",
            "decimals": 6,
            "chainId": 1,
            "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png"
        },
        {
            "name": "USD Coin",
            "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
            "symbol": "USDC",
            "decimals": 6,
            "chainId": 1,
            "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png"
        },
    ]
    // Use the native token of the connected chain as the default input token
    const NATIVE = 'NATIVE' // Special address for native token

    // WBTC as the default output token
    const METRO = '0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7'
    return (
        <>
            <div className="flex justify-between pt-4 pb-12 md:block md:py-10 sm:block sm:pt-10 xs:block xs:pt-3">
                <div className="w-[67%]  md:w-[100%] sm:w-[100%] xs:w-[100%] space-y-2 "  >
                    <div className="flex items-center md:flex md:justify-center ">
                        <video poster={thumb} className=" w-[100%] h-[475px] xs:h-[100%] sm:-[100%] md:h-[100%]" controls autoPlay preload="metadata" playsinline  >
                            <source src={ved} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className="w-[30%] md:w-[100%] sm:w-[100%] xs:w-[100%]  lg:mt-4 md:py-8 sm:pt-8 xs:pt-8 ">
                    <SwapWidget
                        theme={theme}
                        tokenList={MY_TOKEN_LIST}
                        defaultInputTokenAddress={NATIVE}
                        defaultOutputTokenAddress={METRO}

                        width={"98%"}
                    /></div>
                {/* <div className="w-[30%] md:w-[100%] sm:w-[100%] xs:w-[100%]  lg:mt-4 md:py-8 sm:pt-8 xs:pt-8 ">
                        <div className="relative">
                            
                            <div className=" ">
                                <h3 className="text-[35px] sm:text-[33px] xs:text-[33px] font-family font-[700] text-[#0072BA] leading-8">${(contributionForNew + contributionForNewBNB).toLocaleString('en-US')}</h3>
                                <div className="flex justify-between">
                                    <h5 className="text-[18px] font-[500] text-[#929292]">{newPercentage + newPercentageBNB}% {t("of minimum goal raised")}</h5>


                                    <button onMouseEnter={() => setPop(true)} onMouseOut={() => setPop(false)} type="button" className={`cursor-pointer relative`}>
                                        <img src={Iicon} alt="" />
                                    </button>
                                    <div className={`${pop ? 'absolute z-99 w-[220px] right-0 -top-10   text-center py-2 px-3 bg-[#F7F8FA]  rounded-lg text-[#22222] text-sm' : 'hidden'}`}>
                                        <span className="">Metropoly has raised {newPercentage + newPercentageBNB}% of a goal of
                                            {" $1.5M"}
                                        </span>
                                        <div className="absolute z-99 w-5 h-5 -bottom-1   right-1 bg-[#F7F8FA]   rotate-45"></div>
                                    </div>


                                </div>
                            </div>
                            <div>
                                {

                                    newPercentage + newPercentageBNB
                                        < 100 ? <>
                                        <div className='w-[100%] bg-gray-200 rounded-full h-2.5'>
                                            <div
                                                style={{ width: `${newPercentage + newPercentageBNB}%` }}
                                                className={`h-full rounded-full 
                                         ${newPercentage + newPercentageBNB < 1500000 ? 'bg-[#0072BA]' : 'bg-gray-100'}`}>
                                            </div>
                                        </div>
                                    </> : <>
                                        <div className='w-[100%] bg-gray-200 rounded-full h-2.5'>
                                            <div
                                                style={{ width: `100%` }}
                                                className={`h-full rounded-full ${newPercentage + newPercentageBNB < 1500000 ? 'bg-[#0072BA]' : 'bg-gray-100'}`}>
                                            </div>
                                        </div>
                                    </>

                                }

                            </div>

                            <div className="flex justify-end">
                                <h5 className="text-[12px]">{"$1,500,000"}</h5>
                            </div>
                        </div>
                        <div className="bg-[#F7F8FA] rounded-lg px-4 py-4 mb-2 mt-2">

                            <div className="pb-2">
                                <h5 className="text-[18px] font-[500] leading-5  text-[#929292]">{t("Participants")}</h5>
                                <h3 className="text-[24px] font-[700] sm:text-[25px]  xs:text-[25px]">{participantsNew + participantsNewForBNB + latestParticipantsBNB + latestParticipantsETH}</h3>
                            </div>
                            <div className=" space-y-2" >
                                <div>
                                    <h5 className="text-[18px] font-[500] text-[#929292]">
                                        {t("Final Stage Price")}
                                        <span>
                                            <button onMouseEnter={() => setPop2(true)} onMouseOut={() => setPop2(false)} type="button" className={`cursor-pointer relative`}>
                                                <img src={Iicon} alt="" style={{ marginBottom: "-8px" }} />
                                            </button>
                                            <div className={`${pop2 ? 'absolute z-99 w-[220px] right-28 top-[550px] 2xl:right-[160px] xl:right-[160px] lg:right-[160px] 2xl:top-[280px] xl:top-[280px] lg:top-[280px]md:right-[45px] sm:right-[45px] xs:right-[45px] text-center py-2 px-3 bg-[#ffffff]  rounded-lg text-[#22222] text-sm' : 'hidden'}`}>
                                                <span className="text-black">{t("The initial launch price for 1 METRO is set at $0.1.")}
                                                </span>
                                                <div className="absolute z-99 w-5 h-5 -bottom-1 sm:right-[190px] xs:right-[190px] right-[175px] bg-[#ffffff] rotate-45"></div>
                                            </div>

                                        </span>
                                    </h5>

                                </div>
                                <div className="flex space-x-3 items-center">
                                    <h2 className="text-[24px] sm:text-[25px] xs:text-[25px] font-[700] leading-6">
                                        {"1 METRO = $0.09"}

                                    </h2>
                                </div>
                            </div>

                        </div>

                        <div className="mt-5">
                            <div className="bg-[#F7F8FA] flex justify-between items-center px-4 py-2 rounded-xl">
                                <div >
                                    {selected.id === 1 ? <>

                                        <input type="text" lang="en-150" className="text-[20px] font-[Inter] outline-none bg-[#F7F8FA] w-[100%]" placeholder="1000" value={decReady ? inputData : Number(inputData).toLocaleString('en-US', { maximumFractionDigits: 8 })}
                                            onChange={(e) => {
                                                let value = e.target.value.replaceAll(",", "")
                                                if (!isNaN(value)) {
                                                    setInputData(value)
                                                    setinputValue(value)
                                                    setDecReady(false)
                                                }
                                                if (value.slice(-1) === ".") {
                                                    if (!hasDec) {
                                                        setDecReady(!decReady)
                                                        setInputData(value)
                                                        setinputValue(value)
                                                    }
                                                }
                                                if (value.includes("."))
                                                    setHasDec(true)
                                                else
                                                    setHasDec(false)
                                                if (selected.id === 1) {
                                                    if (value > 0)
                                                        setbuyBTNStatus(false);
                                                    else
                                                        setbuyBTNStatus(true);
                                                }
                                                getEstimateAmount(value)
                                            }} />
                                    </> : <>

                                        <input type="number" lang="en-150" className="text-[20px] font-[Inter] outline-none bg-[#F7F8FA] w-[100%]" placeholder="1000" value={inputData}
                                            onChange={(e) => {
                                                let value = e.target.value.replaceAll(",", "")
                                                setInputData(value)
                                                setinputValue(value)
                                                if (selected.id === 2) {
                                                    getEthPrice(value)
                                                    if (value > 0)
                                                        setbuyBTNStatus(false);
                                                    else
                                                        setbuyBTNStatus(true);
                                                }
                                                else if (selected.id === 3) {
                                                    getBnbPrice(value)
                                                    if (BNBPriceInUSD < 100)
                                                        setbuyBTNStatus(true);
                                                    else
                                                        setbuyBTNStatus(false);
                                                }
                                            }} />
                                    </>}
                                    {
                                        selected.id === 1 ? <></> :
                                            <>
                                                {selected.id === 2 ? <>

                                                    {
                                                        ethPriceInUSD === "Fetching the best price..." ? <>
                                                        </> : <>
                                                            <h5 className="text-[#929292] text-[12px]">${Number(ethPriceInUSD).toLocaleString('en-US')}</h5>
                                                        </>
                                                    }
                                                </> : <>

                                                    {
                                                        BNBPriceInUSD === "Fetching the best price..." ? <>
                                                        </> : <>
                                                            <h5 className="text-[#929292] text-[12px]">${Number(BNBPriceInUSD).toLocaleString('en-US')}</h5>
                                                        </>
                                                    }
                                                </>}
                                            </>
                                    }
                                </div>
                                <div className="">
                                    <SelectDropdown handleCurrency={handleCurrency} selected={selected} people={people} />

                                </div>
                            </div>
                            <div className="mt-2 flex justify-center mb-2"><img src={exchange} width={"10px"} /></div>
                            <div className="bg-[#F7F8FA] flex justify-between items-center px-4 py-2 rounded-xl">
                                <div >
                                    <input type="text" lang="en-150" className="text-[20px] font-[Inter] outline-none bg-[#F7F8FA] w-[100%]" placeholder="1000" value={(estimateAmount).toLocaleString('en-US', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                        onChange={(e) => {

                                        }} />

                                </div>
                                <div className="">
                                    <SelectMetro selected={selectedMetro} people={metroWord} />
                                </div>
                            </div>
                        </div >
                        <div id="team" className=" space-y-1 mt-6">
                            {
                                isAuthenticated ? <>
                                    {buyBTNStatus ? <>
                                        <Button classes="bg-[#d5d5d5] rounded-sm w-[100%] h-[50px] text-[black] text-[18px] font-[500]"
                                            text={buyBtn} >
                                        </Button></>
                                        : <>
                                            {
                                                ethPriceInUSD === "Fetching the best price..." ? <>
                                                    <Button classes="bg-[#d5d5d5] rounded-sm w-[100%] h-[50px] text-[black] text-[18px] font-[500]"
                                                        text={buyBtn} />
                                                </> : <><Button classes="bg-[#d5d5d5] rounded-sm w-[100%] h-[50px] text-[black] text-[18px] font-[500]"
                                                    text={buyBtn} onClick={handleBuy} />
                                                </>
                                            }
                                        </>
                                    }

                                </> : <>
                                    <button className="bg-[#0072BA] rounded-sm w-[100%] h-[50px] text-[white] text-[18px] font-[500]"
                                        onClick={handleOpen} >{t("Connect Wallet")}</button>


                                </>
                            }

                        </div>
                        {!hasBonus ?
                            <h5 className="2xl:py-1 text-[13px] mt-2 font-[500] text-[#929292] text-center pb-1" onClick={() => setHasBonus(true)} >{t("Bonus Code?")}</h5>

                            : <>
                                <br />
                                <div className="bg-[#F4F4F4] flex justify-between items-center rounded-xl">

                                    <input type="text" lang="en-150" className="text-[12px] pl-3 font-[Inter] outline-none bg-[#F7F8FA] w-[100%]" placeholder="Bonus Code"
                                        onChange={(e) => {
                                            setCoupon(e.target.value)
                                        }} />

                                    <div className="">
                                        <Button classes="bg-[#F4F4F4] rounded-sm w-[100%] rounded-lg rounded-md pl-5 pr-5 pt-0 h-[30px] text-[white] text-[13px] font-[500]"
                                            text={"Apply"} onClick={handleCoupon} />

                                    </div>
                                </div>
                            </>
                        }
                    </div > */}

            </div >

        </>
    )
}
export default ProductDetalis;