import React from "react";
// import { Link } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ClaimHome from "./ClaimPage";
import ClaimNavBar from "./component/ClaimNav";



const ClaimDashboard = ({ handleOpen, open }) => {
    return (
        <>
            <ClaimHome open={open} handleOpen={handleOpen} />
        </>


    )
}
export default ClaimDashboard;