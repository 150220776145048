
import React, { useState, useEffect } from 'react';
import Button from "../../../components/Button/button";


import Logo from "../../../assects/logo/Logo.png";
import wallet from "../../../assects/images/Wallet.png";
import Connected from "../../../assects/images/connected.png";
import WalletBlue from "../../../assects/svgs/walletsvg.svg";


import WalletDropdown from '../../../components/Dropdowns/WalletDrop';

import { useMoralis, useChain } from 'react-moralis';




const ClaimNavBar = ({ handleOpen, open, handleClaim }) => {


    const { isAuthenticated, user } = useMoralis();
    const [userWallet, setUserWallet] = useState("");
    const [menuOpen, setMenuOpen] = useState(false)


    useEffect(() => {
        if (isAuthenticated) {
            var first = user.get("ethAddress").slice(0, 5);
            var last = user.get("ethAddress").slice(-5);
            setUserWallet(first + "...." + last);
        }
    }, [isAuthenticated, user, userWallet])

    const handleNavbarMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const handleRoute = () => {
        setMenuOpen(!menuOpen)

    }



    const navData = [
        {
            id: 1,
            name: "Teams",
            navid: "#teams",
        },
        {
            id: 2,
            name: "Rewards",
            navid: "#rewards",
        },
        {
            id: 3,
            name: "Campaign",
            navid: "#campaigns",
        },
        {
            id: 4,
            name: "How it works",
            navid: "#howItWork",
        },
    ]

    const handleOpenSideBar = () => {
        setMenuOpen(!menuOpen)
    }


    return (
        <nav className=" lg:px-2 border-b py-1 sm:py-0 xs:py-0 w-full  px-20 sm:px-10 xs:px-6" >
            <header className="">
                <div
                    className="flex items-center justify-between h-16 mx-auto max-w-screen-2xl sm:px-0 xs:px-0 lg:px-8 "
                >
                    <div className="flex items-center">

                        <div className="flex items-center">
                            <a href="/" >
                                <img src={Logo} className="mr-3 h-10 xs:h-[28px] sm:h-[28px]" alt="Metropoly Logo" />
                            </a>
                        </div>
                    </div>
                    <div className="flex items-center justify-end flex-1">
                        <div className="flex items-center ml-8">
                            {!isAuthenticated ?
                                <>

                                    <button className='px-3  rounded-full items-center justify-start w-[145px] h-[35px] flex items-center text-[12px] p-1.5 text-sm font-semibold font-[poppins] text-white space-x-1 relative bg-[#0072BA]' onClick={handleOpen}>
                                        <div className='absolute bg-white -right-3 h-[40px] w-[40px] rounded-full border border-[#0072BA] flex justify-center items-center'>
                                            <span className='  '>
                                                <img className='' src={WalletBlue} alt="" />
                                            </span>
                                        </div>
                                        <span>
                                            Connect Wallet
                                        </span>

                                    </button>

                                </> : <>
                                    <button className='pl-3 border hover:border-2 border-[#56aeff] rounded-full items-center justify-center w-[145px] h-[35px] flex items-center text-[12px] p-1.5 text-sm font-semibold font-[poppins] text-[#56aeff] -border-[#56aeff] space-x-1 relative ' onClick={handleOpen} > <span className='flex items-center justify-center absolute bg-[#0072BA] -left-4 h-[40px] w-[40px] rounded-full'><img src={Connected} alt="" /></span>{userWallet}</button>
                                </>
                            }
                            {open &&
                                <WalletDropdown open={open} handleOpen={handleOpen} handleClaim={handleClaim} />
                            }
                        </div>
                    </div>
                </div>
            </header >
            {
                menuOpen &&
                <div className="z-999 relative w-full  xl:hidden lg:hidden">
                    <ul
                        className="text-base text-gray-700 pt-4   md:pt-0"
                    >
                        {navData.map((item, id) => (
                            <li key={id} onClick={handleNavbarMenu}>
                                <a className="md:p-2 py-2 block  hover:text-[#56aeff]" href={item.navid}
                                >{item.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </nav >
    )
}
export default ClaimNavBar;
