import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

import Piechart from "../../../assects/images/Piechart.png"
import smallScreen from "../../../assects/images/smallScreen2.png"

const Tokenomics = () => {

    const {t} = useTranslation()
    return (
        <div className="bg-[#ecf4ff] px-20 py-10 sm:py-2 xs:py-2 sm:px-0 xs:px-0">
            <div className="py-6 py-6 sm:py-2 xs:py-2 sm:px-4">
                <h3 className="pb-[33px] text-center text-[30px] font-[700] text[#F4F4F4] pb-4 font-[Poppins]">{t("Metropoly Tokenomics")}</h3>
            </div>
            <div className="2xl:flex xl:flex lg:flex md:flex sm:flex flex justify-center items-center 2xl:block xl:block lg:block md:hidden sm:hidden hidden w-[100%]">
                <img src={Piechart} alt="" className="2xl:w-[80%] xl:w-[80%]   lg:w-[100%] md:w-[100%] sm:w-[100%] w-[100%]" />
            </div>
            <div className="flex justify-center items-center 2xl:hidden xl:hidden lg:hidden md:block sm:block block">
                <img src={smallScreen} alt="" className="2xl:w-[80%] xl:w-[80%]   lg:w-[100%] md:w-[100%] sm:w-[100%] w-[100%]" />
            </div>
        </div>
    )
}
export default Tokenomics;