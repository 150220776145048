import React, { useEffect, useCallback, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useMoralis, useMoralisQuery } from 'react-moralis';
import TabSection from "./TabsSection";

const MyPage = () => {

    const [userContribution, setUserContribution] = useState(0);

    const tableData = [
        {
            id: uuidv4(),
            title: 'Purchased Token',
            value: '20,000 METRO',
        },
        {
            id: uuidv4(),
            title: 'Bonus Token',
            value: '5,000 METRO',
        },
        {
            id: uuidv4(),
            title: 'NFTs Received',
            value: '1',
        }
    ]


    const totalParticipants = useMoralisQuery(
        "ContributorsLatest",
        (query) => query,
        [],
        { autoFetch: false }
    );

    const objectIdQuery = useCallback(() => {
        totalParticipants.fetch({
            onSuccess: (participantsResult) => {
                let test = 0;
                participantsResult.map((index) => {

                    test += Number(index.attributes.amount);
                })
                setUserContribution(Number((test / 1000000).toFixed(2)).toLocaleString('en-US'))
            },
            onError: (error) => {
                // The object was not retrieved successfully.
                // error is a Moralis.Error with an error code and message.
            },
        });
    }, [fetch]);


    useEffect(() => {
        (async () => {
            try {
                async function fetchData() {
                    objectIdQuery();
                }
                fetchData()
            } catch (error) {
                console.log("error", error);
            }
        }
        )()
    }, [objectIdQuery])
    return (
        <div className="xs:px-4 sm:px-4 px-20 m-auto pt-8">
            <TabSection />
        </div>
    )
}
export default MyPage;
