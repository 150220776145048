
import React, { useState, useEffect } from 'react';


import Logo from "../../assects/svgs/metropolylogo.svg";
import WalletBlue from "../../assects/svgs/walletsvg.svg";
import globe from "../../assects/images/globe.png";
import Connected from "../../assects/images/connected.png";

import { useMoralis } from 'react-moralis';
import ModalPayment from '../../components/Modal/index';
import { useTranslation } from 'react-i18next';

// import { useSearchParams } from 'react-router-dom';
import "./style.css"

import { ConnectButton } from '@rainbow-me/rainbowkit';
import {
    useConnectModal,
    useAccountModal,
    useChainModal,
} from '@rainbow-me/rainbowkit';
import MyWallet from '../../components/Dropdowns/MyWallet';

const NavBar = ({ handleOpen, open, handleClose, handleClaim, menuOpen, handleNavbarMenu }) => {
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const { openChainModal } = useChainModal();
    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    const [distance, setDistance] = useState(0)
    const [distance2, setDistance2] = useState(0)

    let countDown = new Date('Jun 10, 2023 18:00:00 UTC').getTime(),
        x = setInterval(function () {

            let now = new Date().getTime(),
                distance = countDown - now;
            if (distance > 0)
                setDistance(distance)
        }, second)

    let countDown2 = new Date('Sept 24, 2023 16:00:00 UTC').getTime(),
        y = setInterval(function () {

            let now = new Date().getTime(),
                distance2 = countDown2 - now;
            if (distance2 > 0)
                setDistance2(distance2)
        }, second)

    const { isAuthenticated, user } = useMoralis();
    const [userWallet, setUserWallet] = useState("");
    const [langStatus, setLangStatus] = useState(false);
    const [lang, setLang] = useState("English");
    const [url, setURL] = useState("en");
    const { t, i18n } = useTranslation()
    localStorage.setItem('handleOpen', handleOpen)

    function getURLParams() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        const result = {};
        for (const [key, value] of params.entries()) {
            result[key] = value;
        }

        return result;
    }
    useEffect(() => {

        if (window.location.href !== "") {
            if (window.location.href.slice(-2) === 'en') {
                setURL("en")
                setLang("English")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'de') {
                setLang("German")
                setURL("de")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'fr') {
                setLang("French")
                setURL("fr")
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'es') {
                setLang("Spanish")
                setURL("es")
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'it') {
                setLang("Italian")
                setURL("it")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'nl') {
                setLang("Dutch")
                setURL("nl")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'tr') {
                setLang("Turkish")
                setURL("tr")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'ko') {
                setLang("Korean")
                setURL("ko")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'nb') {
                setLang("Norwegian")
                setURL("nb")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'ru') {
                setLang("Russian")
                setURL("ru")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'zh') {
                setLang("Chinese")
                setURL("zh")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
        }
        if (isAuthenticated) {
            var first = user.get("ethAddress").slice(0, 5);
            var last = user.get("ethAddress").slice(-5);
            setUserWallet(first + "...." + last);

        }
    }, [isAuthenticated, user, userWallet])


    const navData = [
        {
            id: 5,
            name: t("Marketplace"),
            navid: "#metropoly",
        },
        {
            id: 6,
            name: t("Whitepaper"),
            navid: "#metropoly",
        },
        {
            id: 1,
            name: t("What is Metropoly?"),
            navid: "#metropoly",
        },
        {
            id: 2,
            name: t("How to Buy"),
            navid: "#realEstate",
        },
        // {
        //     id: 3,
        //     name: t("Team"),
        //     navid: "#teams",
        // },
        {
            id: 4,
            name: "FAQ",
            navid: "#metropolyFAQ",
        },
    ]
    return (
        <>
            <header class="header text-white items-center md:block sm:block xs:block text-center">
                <div className="alert-container  place-content-center  flex items-center md:block sm:block xs:block">
                    <div className="copy-container">

                        {
                            distance > 0 ?
                                <h1 className="main-head text-[18px] font-bold ">{t('Second Property Presentation in')}</h1>
                                :
                                <h1 className="main-head text-[18px] font-bold ">{t('First Test Property Listing with Real Data on Marketplace')}</h1>
                        }
                    </div>
                    <div className='flex items-center justify-center'>
                        <ul className="countdown-clock 2xl:grid xl:grid lg:grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:inline-flex sm:inline-flex xs:inline-flex">
                            {
                                distance > 0 ? <>
                                    <li><span id="days">{Math.floor(distance / (day))}</span> {t("Days")}</li>
                                    <li><span id="hours">{Math.floor((distance % (day)) / (hour))}</span> {t("Hours")}</li>
                                    <li><span id="minutes">{Math.floor((distance % (hour)) / (minute))}</span> {t("Mins")}</li>
                                    <li><span id="seconds">{Math.floor((distance % (minute)) / second)}</span> {t("Secs")}</li>
                                </>
                                    : <>
                                        <li><span id="days">{Math.floor(distance2 / (day))}</span> {t("Days")}</li>
                                        <li><span id="hours">{Math.floor((distance2 % (day)) / (hour))}</span> {t("Hours")}</li>
                                        <li><span id="minutes">{Math.floor((distance2 % (hour)) / (minute))}</span> {t("Mins")}</li>
                                        <li><span id="seconds">{Math.floor((distance2 % (minute)) / second)}</span> {t("Secs")}</li>
                                    </>
                            }
                        </ul>
                        <div className="nav-button-container ">
                            <a className="nav-button-wrap">
                                <div id="nav-button" className='border-2 border-inherit	rounded px-2' ><a href="https://www.xt.com/tradePro/metro_usdt">{t('BUY METRO NOW')}</a>!</div>
                            </a>
                        </div>
                    </div>
                </div>
            </header>

            <nav className=" z-10 sticky top-0 xl:top-10 2xl:top-10 lg:top-10 bg-white 2xl:px-20 xl:px-20 lg:px-20 md:px-20 sm:px-4 xs:px-4  py-1 sm:py-0 xs:py-0 w-full  border-b border-gray-200  absolute" >
                <header className="">
                    <div
                        className="flex items-center justify-between h-16 mx-auto max-w-screen-2xl sm:px-0 xs:px-0 lg:px- "
                    >
                        <div className="flex items-center">
                            <button type="button" onClick={handleNavbarMenu} className="p-1 sm:mr-4 xl:hidden lg:hidden 2xl:hidden">
                                <svg
                                    className="w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            </button>
                            <div className="flex items-center">
                                <a href="https://www.metropoly.io/" >
                                    <img src={Logo} className="h-[33px]" alt="Metropoly Logo" />
                                </a>
                            </div>
                        </div>
                        <div className="flex items-center justify-end flex-1">
                            <div className='flex   md:hidden sm:hidden xs:hidden'>
                                <div className='flex pr-6'>
                                    <ul className="items-stretch xs:hidden space-x-3 flex">
                                        {/* <li className="flex">
                                            <a rel="noopener noreferrer" href={"https://giveaway.metropoly.io/?" + url} className="flex items-center px-4 -mb-1 text-[14px] font-[400] font-Poppins hover:text-[#56aeff] text-black" >{t('Win Burj Khalifa Apartment')}</a>
                                        </li> */}
                                        {navData.map((item, id) => (
                                            <li key={id} className="flex">
                                                <a rel="noopener noreferrer" href={item.navid} className="flex items-center px-4 -mb-1 text-[14px] font-[400] font-Poppins hover:text-[#56aeff] text-black" >{item.name}</a>
                                            </li>
                                        ))}

                                        <li >

                                            <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class="text-black font-medium rounded-lg text-sm  py-2.5 text-center inline-flex items-center px-2"
                                                style={{
                                                    border: "1px solid #313c44"
                                                }} type="button" onClick={() => { setLangStatus(!langStatus) }}>
                                                <img src={globe} width={20} className=" mr-2" />
                                                {lang}
                                                <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>

                                            {langStatus &&
                                                <div id="dropdown" class="z-10 mt-40 overflow-auto h-[200px]  bg-white divide-y divide-gray-100 rounded-lg shadow " >
                                                    <ul class="text-sm text-black " aria-labelledby="dropdownDefaultButton">
                                                        <li>
                                                            <button onClick={() => {
                                                                setLang("English")
                                                                i18n.changeLanguage('en')
                                                                setURL("en")
                                                                setLangStatus(!langStatus)
                                                            }
                                                            } style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" >English</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLang("German")
                                                                i18n.changeLanguage('de')
                                                                setURL("de")
                                                                setLangStatus(!langStatus)
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">German</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("French")
                                                                setURL("fr")
                                                                i18n.changeLanguage('fr')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">French</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Spanish")
                                                                setURL("es")
                                                                i18n.changeLanguage('es')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Spanish</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Italian")
                                                                setURL("it")
                                                                i18n.changeLanguage('it')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Italian</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Dutch")
                                                                setURL("nl")
                                                                i18n.changeLanguage('nl')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dutch</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Turkish")
                                                                setURL("tr")
                                                                i18n.changeLanguage('tr')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Turkish</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Korean")
                                                                setURL("ko")
                                                                i18n.changeLanguage('ko')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Korean</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Norwegian")
                                                                setURL("nb")
                                                                i18n.changeLanguage('nb')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Norwegian</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Russian")
                                                                setURL("ru")
                                                                i18n.changeLanguage('ru')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Russian</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Chinese")
                                                                setURL("zh")
                                                                i18n.changeLanguage('zh')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Chinese</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            }

                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <ConnectButton.Custom>
                                {({
                                    account,
                                    chain,
                                    authenticationStatus,
                                    mounted,
                                }) => {
                                    // Note: If your app doesn't use authentication, you
                                    // can remove all 'authenticationStatus' checks
                                    const ready = mounted && authenticationStatus !== 'loading';
                                    const connected =
                                        ready &&
                                        account &&
                                        chain &&
                                        (!authenticationStatus ||
                                            authenticationStatus === 'authenticated');
                                    return (
                                        <div
                                            {...(!ready && {
                                                'aria-hidden': true,
                                                'style': {
                                                    opacity: 0,
                                                    pointerEvents: 'none',
                                                    userSelect: 'none',
                                                },
                                            })}
                                        >
                                            {(() => {
                                                if (!connected) {
                                                    return (

                                                        <button className='px-3 pr-8 rounded-full w-[145px] h-[40px] flex items-center text-[12px] p-1.5 text-sm justify-center font-semibold font-[poppins] text-white space-x-1 relative bg-[#0072BA]'
                                                            onClick={openConnectModal} >
                                                            <div className='absolute bg-white -right-3 h-[40px] w-[40px] rounded-full border border-[#0072BA] flex justify-center items-center'>
                                                                <span className='  '>
                                                                    <img className='' src={WalletBlue} alt="" />
                                                                </span>
                                                            </div>
                                                            <span>
                                                                {t("Connect Wallet")}
                                                            </span>

                                                        </button>
                                                    );
                                                }

                                                if (chain.unsupported) {
                                                    return (
                                                        <button onClick={openChainModal} type="button">
                                                            Wrong network
                                                        </button>
                                                    );
                                                }

                                                return (
                                                    <div>

                                                        <button className='pl-3 border hover:border-2 border-[#56aeff] border-2 rounded-full items-center justify-center w-[145px] h-[35px] flex items-center text-[12px] p-1.5 text-sm font-semibold font-[poppins] text-[#56aeff] border-[#56aeff] space-x-1 relative ' onClick={handleOpen}>
                                                            <span className='flex items-center justify-center absolute bg-[#0072BA] -left-4 h-[40px] w-[40px] rounded-full'>
                                                                <img src={Connected} alt="" />
                                                            </span>
                                                            <span className='font-[600]'>
                                                                {account.address.slice(0, 6)}...{account.address.slice(-4)}
                                                            </span>
                                                        </button>
                                                        {
                                                            open ? <div >
                                                                {/* <MyWallet accounts={account.address} /> */}

                                                                <div className="z-10 absolute right-3 sm:-right-0 xs:-right-0 top-[60px]  sm:w-full xs:w-full w-[100%] bg-[transparent] h-[90vh]  block" onClick={handleClose} >
                                                                    <MyWallet accounts={account.address} /> </div>
                                                                {/* <ModalPayment handleOpen={handleOpen} handleClose={handleClose} /> */}
                                                            </div> :
                                                                <></>
                                                        }
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                    );
                                }}
                            </ConnectButton.Custom>

                            {/* <div className="">
                                {!isAuthenticated ?
                                    <>

                                    <Button onClick={handleOpen} text={"Connect Wallet"} wallet={wallet} classes=" border hover:border-2  rounded-full items-center justify-center text-[11px]  w-[144px] h-[35px] flex items-center p-1.5 text-sm font-semibold font-[poppins] bg-[#0072BA] text-white space-x-1" imageClass="w-[13px] h-[13px]" /> 



                                        <button className='px-3 pr-8 rounded-full w-[145px] h-[40px] flex items-center text-[12px] p-1.5 text-sm justify-center font-semibold font-[poppins] text-white space-x-1 relative bg-[#0072BA]' onClick={handleOpen}>
                                            <div className='absolute bg-white -right-3 h-[40px] w-[40px] rounded-full border border-[#0072BA] flex justify-center items-center'>
                                                <span className='  '>
                                                    <img className='' src={WalletBlue} alt="" />
                                                </span>
                                            </div>
                                            <span>
                                                {t("Connect Wallet")}
                                            </span>

                                        </button>
                                    </> : <>
                                        <button className='pl-3 border hover:border-2 border-[#56aeff] border-2 rounded-full items-center justify-center w-[145px] h-[35px] flex items-center text-[12px] p-1.5 text-sm font-semibold font-[poppins] text-[#56aeff] border-[#56aeff] space-x-1 relative ' onClick={handleOpen}>
                                            <span className='flex items-center justify-center absolute bg-[#0072BA] -left-4 h-[40px] w-[40px] rounded-full'>
                                                <img src={Connected} alt="" />
                                            </span>
                                            <span className='font-[600]'>
                                                {userWallet}
                                            </span>
                                        </button>
                                    </>
                                }
                                {open &&


                                    // <WalletDropdown open={open} handleClose={handleClose} handleOpen={handleOpen} handleClaim={handleClaim} />

                                    <ModalPayment handleOpen={handleOpen} handleClose={handleClose} />


                                }

                            </div> */}
                        </div>
                    </div>
                </header >
                {
                    menuOpen &&
                    <div className="z-999 relative w-full  xl:hidden lg:hidden ">
                        <ul
                            className="text-base text-gray-700 pt-4   md:pt-0 mb-4"
                        >

                            {/* <li >
                                <a className="md:p-2 py-2 block  hover:text-[#56aeff]" href={"https://giveaway.metropoly.io/?" + url}
                                >{t('Win Burj Khalifa Apartment')}</a>
                            </li> */}
                            {navData.map((item, id) => (
                                <li key={id} onClick={handleNavbarMenu}>
                                    <a className="md:p-2 py-2 block  hover:text-[#56aeff]" href={item.navid}
                                    >{item.name}</a>
                                </li>
                            ))}

                            <li >

                                <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class="text-black font-medium rounded-lg text-sm  py-2.5 text-center inline-flex items-center px-2"
                                    style={{
                                        border: "1px solid #313c44"
                                    }} type="button" onClick={() => { setLangStatus(!langStatus) }}>
                                    <img src={globe} width={20} className=" mr-2" />
                                    {lang}
                                    <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>

                                {langStatus &&
                                    <div id="dropdown" class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow " >
                                        <ul class="text-sm text-black " aria-labelledby="dropdownDefaultButton">
                                            <li>
                                                <button onClick={() => {
                                                    setLang("English")
                                                    setURL("en")
                                                    i18n.changeLanguage('en')
                                                    setLangStatus(!langStatus)
                                                }
                                                } style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" >English</button>
                                            </li>
                                            <li>
                                                <button onClick={() => {
                                                    setLang("German")
                                                    setURL("de")
                                                    i18n.changeLanguage('de')
                                                    setLangStatus(!langStatus)
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">German</button>
                                            </li>
                                            <li>
                                                <button onClick={() => {
                                                    setLangStatus(!langStatus)
                                                    setLang("French")
                                                    setURL("fr")
                                                    i18n.changeLanguage('fr')
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">French</button>
                                            </li>
                                            <li>
                                                <button onClick={() => {
                                                    setLangStatus(!langStatus)
                                                    setLang("Spanish")
                                                    setURL("es")
                                                    i18n.changeLanguage('es')
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Spanish</button>
                                            </li>
                                            <li>
                                                <button onClick={() => {
                                                    setLangStatus(!langStatus)
                                                    setLang("Italian")
                                                    setURL("it")
                                                    i18n.changeLanguage('it')
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Italian</button>
                                            </li>
                                            <li>
                                                <button onClick={() => {
                                                    setLangStatus(!langStatus)
                                                    setLang("Dutch")
                                                    setURL("nl")
                                                    i18n.changeLanguage('nl')
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dutch</button>
                                            </li>

                                            <li>
                                                <button onClick={() => {
                                                    setLangStatus(!langStatus)
                                                    setLang("Turkish")
                                                    setURL("tr")
                                                    i18n.changeLanguage('tr')
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Turkish</button>
                                            </li>

                                            <li>
                                                <button onClick={() => {
                                                    setLangStatus(!langStatus)
                                                    setLang("Korean")
                                                    setURL("ko")
                                                    i18n.changeLanguage('ko')
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Korean</button>
                                            </li>
                                            <li>
                                                <button onClick={() => {
                                                    setLangStatus(!langStatus)
                                                    setLang("Norwegian")
                                                    setURL("nb")
                                                    i18n.changeLanguage('nb')
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Norwegian</button>
                                            </li>
                                            <li>
                                                <button onClick={() => {
                                                    setLangStatus(!langStatus)
                                                    setLang("Russian")
                                                    setURL("ru")
                                                    i18n.changeLanguage('ru')
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Russian</button>
                                            </li>
                                            <li>
                                                <button onClick={() => {
                                                    setLangStatus(!langStatus)
                                                    setLang("Chinese")
                                                    setURL("zh")
                                                    i18n.changeLanguage('zh')
                                                }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Chinese</button>
                                            </li>
                                        </ul>
                                    </div>
                                }

                            </li>
                        </ul>
                    </div>
                }
            </nav >
            <div class="sticky-header-spacer"></div>
        </>
    )
}
export default NavBar;
