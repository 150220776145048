import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import VestingPeriod from "./VestingPeriod";

const FAQsTab = () => {
    const { t } = useTranslation()
    return (
        <div className="mb-20">
            <span id="metropolyFAQ" style={{ top: "-50px", position: "relative" }} ></span>
            <div className="py-6  sm:py-4 xs:py-4 sm:px-4">
                <h3 className="pb-[33px] text-center text-[30px] font-[700] text[#F4F4F4] pb-4 font-[Poppins]">{t("Frequently Asked Questions")}</h3>
            </div>
            <div className="grid gap-11 sm:pt-2 xs:pt-2 2xl:w-[80%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[100%] xs:w-[100%]  m-auto ">
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group " open>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h3 className="pb-[33px] text-[30px] sm:text-[16px] xs:text-[16px] font-[600] text[#F4F4F4] pb-4 font-[Poppins]">{t("What is a presale?")}</h3>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 sm:px-2 xs:px-2  leading-relaxed ">{t("During a presale, a limited number of tokens are sold at a lower price to people who want to get involved early. This is a great chance for people to be a part of a new project before an actual coin/token launch takes place in which the general public can participate.")} <br />
                            <br />
                            {t("It’s kind of like being part of a secret club, where you get access to something before anyone else does. By participating in a presale, you can purchase a token at an early-bird price before it goes public. Therefore, presale can be considered a potential opportunity to buy tokens or coins before they are released into circulation and listed on exchanges.")}

                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group " close>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h3 className="pb-[33px] text-[30px] sm:text-[16px] xs:text-[16px] font-[600] text[#F4F4F4] pb-4 font-[Poppins]">{t("What is Metropoly?")}</h3>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 sm:px-2 xs:px-2  leading-relaxed ">{t("With Metropoly, we are building the first NFT marketplace to invest in income-generating properties without banks, hidden fees, or geographic limits. Metropoly is for everyone seeking ways to invest in real estate, generate passive income, diversify their investment portfolio, and protect their capital from inflation.")} <br />
                            <br />
                            {t("Each NFT in the Metropoly Marketplace is backed by real-world properties and is divided into fractions, allowing anyone to invest in real estate for as little as $100.")}
                            <br /> <br />
                            {t("The Real Estate NFT owner receives monthly rental income, benefits from long-term value appreciation, and can trade their shares anytime.")}

                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" close>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h3 className="pb-[33px] text-[30px] sm:text-[16px] xs:text-[16px] font-[600] text[#F4F4F4] pb-4 font-[Poppins]">{t("Why are we doing a presale?")}</h3>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">
                            {t("Your contribution will help us build the Metropoly Platform and bring our vision of a global, decentralized real estate marketplace to life.")}
                            <br></br><br></br>
                            {t("The funds will be re-invested to support our go-to-market strategy and secure down payments for our first properties. Plus, the METRO token will be listed on top centralized and decentralized exchanges.")}<br></br><br></br>
                            {t("Participate in the Metropoly public presale and become a part of the future of real estate. Not only will you get early access to our platform, but you’ll also receive Mr. Metropoly NFT for contributions over $100.")}<br></br><br></br>
                            {t("Don’t miss this opportunity to make a difference and help us create a world where anyone, anywhere, can access the real estate market. Join the Metropoly community and invest in the future of real estate now!")}
                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" close>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h3 className="pb-[33px]  text-[30px] sm:text-[16px] xs:text-[16px] font-[600] text[#F4F4F4] pb-4 font-[Poppins]">{t("What is METRO?")}</h3>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">{t("METRO is an exclusive ERC-20 token on the Ethereum blockchain and has a supply of only 1 billion. Use METRO as a payment and reward method on the Metropoly Marketplace and be a part of a secure and accessible new era in real estate.")}
                            <br></br><br></br>
                            {t("View our Token Contract Address")}
                            <span> <a href="https://etherscan.io/address/0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7#code" target='_blank' rel='noopener noreferrer' style={{ textDecoration: "underline" }}>{t("here")}
                            </a>.</span>

                        </p>
                    </details>


                </div>

                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" close>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h3 className="pb-[33px] text-[30px] sm:text-[16px] xs:text-[16px] font-[600] text[#F4F4F4] pb-4 font-[Poppins]">{t("What measures are in place to ensure my tokens are protected?")}</h3>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">{t("To ensure the safety of the METRO token and its holders, Metropoly underwent a security audit by CertiK and Solidproof, two leading blockchain security companies. The audits examined Metropoly’s specifications, smart contract, and team to make sure they meet high Web3 standards. CertiK used advanced methods and a skilled team to identify any errors and potential risks to ensure the safe use of the Metropoly token. Further they conducted full KYC check and interviews with the executives to provide maximum security and transparency.")}
                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" close>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h3 className="pb-[33px] text-[30px] sm:text-[16px] xs:text-[16px] font-[600] text[#F4F4F4] pb-4 font-[Poppins]">{t("Does the METRO token have a vesting plan?")}</h3>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">{t("Yes, please refer to our Vesting plan below.")}
                        </p>
                        <VestingPeriod />
                        <br></br>
                    </details>


                </div>

                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" close>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h3 className="pb-[33px]  text-[30px] sm:text-[16px] xs:text-[16px] font-[600] text[#F4F4F4] pb-4 font-[Poppins]">{t("What is the METRO Listing Price?")}</h3>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">
                            {t("The $METRO Listing Price is the price at which $METRO tokens will be available for purchase during its launch. The initial launch price for 1 METRO is set at $0.1.")}


                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" close>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h3 className="pb-[33px]  text-[30px] sm:text-[16px] xs:text-[16px] font-[600] text[#F4F4F4] pb-4 font-[Poppins]">{t("Where can I claim my tokens?")}</h3>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">{t("Find the “Connect Wallet” button located at the top right corner. Click on it, and then choose the option “Claim METRO” to receive your tokens. The distribution of tokens will take place over a period of 90 days, with 10% of the total tokens being given to you every 10 days.")}

                        </p>
                    </details>


                </div>
                {/* <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" open>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins font-[600]">Has the token METRO token been audited?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">The METRO token has been successfully audited, has no taxes and the
                            ownership is renounced to provide a maximum of security. <br />
                            <br />
                            https://app.solidproof.io/projects/metropoly
                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" open>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins font-[600]">How does the crowdfunding campaign work?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">It's a fair launch structure with a fixed reserved amount of 200 million
                            METRO (+100 Million Bonus) tokens which will be divided by the total
                            amount METRO purchased. The bonus is paid in addition depending on
                            which stage you have placed your order in.<br />
                            <br />
                            Everyone will get the same METRO price which will be determined at the
                            end of the community fair launch.<br /> <br />
                            All tokens will be distributed with a vesting plan after the presale ends.
                        </p>
                    </details>


                </div> */}
                {/* <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" open>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins font-[600]">How does the Bonus System work?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">Our community fair launch will have 10 stages.<br />
                            <br />
                            It starts with a 50% bonus in the first stage. <br /><br />
                            50% bonus in the 2nd Stage <br /><br />
                            45% bonus in the 3rd stage <br /><br />
                            and so forth... <br /><br />
                            5% bonus in the 10th stage (final stage) <br /><br />
                            Each stage will conclude after 5 days.
                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" open>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins font-[600]">How does the Level System work?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">
                            The more you spend, the more you get!<br />
                            <br />
                            All participants automatically become a part of our exclusive Metropoly
                            Membership Club and will get one, Mr. Metropoly NFT, with reward tiers
                            starting at just $100.<br /> <br />
                            <span className="text-[18px] font-Poppins font-[600]">The first level is Student: $100 to $999 USD</span> <br /><br />
                            As a Student, you get: <br /><br />
                            • Early access to Metropoly Beta <br />
                            • 1 x Metropoly Student NFT<br />
                            • Ability to earn METRO Tokens <br /><br />
                            <span className="text-[18px] font-Poppins font-[600]">The second level is Broker: $1,000 to $9,999 USD</span> <br /><br />
                            As a Broker, you get:<br /><br />
                            • Early access to Metropoly Beta <br />
                            • 1 x Metropoly Broker NFT<br />
                            • Ability to earn METRO Tokens<br />
                            • Real Estate NFT worth $100 USD<br /><br />
                            <span className="text-[18px] font-Poppins font-[600]">The third level is Manager: $10,000 to $24,999 USD</span> <br /><br />
                            As a Manager, you get:<br /><br />
                            • Early access to Metropoly Beta <br />
                            • 1 x Metropoly Manager NFT<br />
                            • Ability to earn METRO Tokens <br />
                            • Real Estate NFTs worth $1000 USD<br />
                            • 1% Cash-Back on Rental Income<br /><br />
                            <span className="text-[18px] font-Poppins font-[600]">The fourth level is Boss: $25,000 - $99,999 USD</span> <br /><br />
                            As a Boss, you get:<br /><br />
                            • Early access to Metropoly Beta <br />
                            • 1 x Metropoly Boss NFT <br />
                            • Ability to earn METRO Tokens<br />
                            • Real Estate NFTs worth $2500 USD<br />
                            • 2.5% Cash-Back on Rental Income<br /><br />
                            <span className="text-[18px] font-Poppins font-[600]">The ultimate level is the Tycoon: $100,000+ USD</span> <br /><br />
                            As a Tycoon, you get:<br /><br />
                            • Early access to Metropoly Beta <br />
                            • 1 x Metropoly Tycoon NFT<br />
                            • Ability to earn METRO Tokens<br />
                            • Real Estate NFTs worth $10,000 USD<br />
                            • 5% Cash-Back on Rental Income
                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 sm:px-2 xs:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group" open>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins font-[600]">What happens after the community fair launch?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">After the community fair launch ends, we will launch the Metro token on
                            different centralized and decentralized exchanges (Uniswap for example)<br />
                            <br />
                            We will also launch the Beta of the Metropoly Marketplace and collaborate
                            with different real estate companies around the globe to make the real
                            estate market accessible to users worldwide.<br /> <br />
                            Let's fight together against increasing inflation and low-interest rates. With
                            Metropoly, we will create a platform that empowers people to build their
                            wealth and earn passive income with real estate. <br /><br />
                            Join our community now and help us revolutionize the real estate industry!
                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto ">
                    <details className="group" open>
                        <summary
                            className="flex sm:block items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins font-[600]">Is METRO a security?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">No, METRO cannot be considered a security. The community fair launch is
                            done to kickstart the tech startup Metropoly and attract users for the first
                            BETA Version of the decentralized Metropoly Marketplace. Participating in
                            this event is the only way to get early access to the Metropoly platform.<br />
                            <br />
                            METRO is a utility and reward token and will be mainly used as a reward
                            method for the gamified Metropoly level system without buying them.
                            METRO is NOT an investment product, and we’re not providing any
                            financial advice.<br /> <br />
                            Metropoly.io LTD doesn’t own the ownership rights of the METRO token
                            and cannot change anything on the token itself (the smart contract). The
                            third-party auditing company Solidproof from Germany has proved and
                            audited this the 19.10.2022. <br /><br />
                            Please note: METRO is NOT representing a share of ownership in
                            Metropoly.io LTD, and holders of METRO have no voting or governance
                            rights and cannot benefit from earnings in the form of dividends.
                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">How can I start?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">1. Connect your Metamask or use WalletConnect (make sure that you have ETH). <br />
                            2. Discover and compare all available real estate NFTs. <br />
                            3. Buy your first real estate NFT without any paperwork.
                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">What is the minimum amount?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">Prices start from $100 for fractionalized ownership depending on the properties offered in the Metropoly Marketplace.

                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">How long should I hold a real estate NFT?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">It all depends on the strategy. The Metropoly Marketplace and other secondary marketplaces such as Opensea allow users to liquidate their assets and list them for sale at any given moment. <br />
                            <br />
                            However, we recommend that users hold their NFTs for at least five years. This is because the best returns on real estate occur over time, as users generate additional income from rent (i.e., yield) and the asset’s market value grows (i.e., capital appreciation). <br />
                            <br />
                            At Metropoly, we select properties with attractive long-term return potential, which stems from yield and capital appreciation.

                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">What services does Metropoly manage?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">It all depends on the strategy. The Metropoly Marketplace and other secondary marketplaces such as Opensea allow users to liquidate their assets and list them for sale at any given moment. <br />
                            <br />
                            Metropoly operates the platform and manages the real estate on behalf of the NFT owners until the sale (i.e., until a property has been sold and capital returned to NFT holders). This includes ensuring that all digital and other operations run smoothly and that assets are identified, evaluated appropriately, and managed responsibly. <br />
                            <br />

                            In addition to general administration, we eliminate all operational headaches for Holders, as they do not have to worry about tenant operations, leasing, maintenance, or any technical requirements.


                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">Why should I buy real estate NFTs?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">It all depends on the strategy. The Metropoly Real estate is a tangible and stable asset class that has been a leading performer throughout history. As a result, it is a favorite among large institutional and high-net-worth individuals for many reasons, including: <br />
                            <br />

                            1. Real estate grows in value over long periods and is less volatile than financial markets and cryptocurrencies, offering stability during turbulent times. <br />
                            2. Real estate is a hedge against inflation and currency depreciation and an excellent long-term store of value. <br />
                            3. Real estate is a productive asset that generates predictable income from rent. <br />
                            4. When included in a diversified portfolio of different asset classes, real estate enhances the portfolio’s risk-return profile.



                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">Can I visit the real estate?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">No. As part of our commitment to being fully transparent with our NFT buyers, all properties offered on the platform will include a complete 3D walkthrough, high-resolution photos, and inspection reports. However, due to the fact that we generally look to show properties that are already tenanted, visiting those units is not part of our service.


                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">What is the timer on the Launchpad?

                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">The timer represents the period to mint an NFT. Usually, this begins at around 30 days, but can range up to 90 days unless all of the fractionalized NFTs sell out before the auction timer ends. Therefore, we recommend that users purchase properties that they like as early as possible.


                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">What happens if a property on the Launchpad is not 100% funded after the timer ends?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">In such a case, the opportunity will be canceled and the amount for the real estate share (held in escrow) will be returned to the user’s wallet in its entirety. Users may then either withdraw the funds or decide to buy into another real estate NFT on Metropoly.


                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">How will users earn a return on Metropoly?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">The proportional share of the rental income will be distributed to the user’s wallet in USDC (ERC-20). The net distributions are calculated after all property-related costs are deducted from the rent, such as service charges, property management fees, Metropoly administration fees, maintenance, taxes, insurance, and other expenses. After receiving dividends, the funds will be shown on the profile’s dashboard. <br /><br />

                            If the property is sold in real life, the profit share will be distributed to users according to their NFT stake (i.e., after the deduction of all relevant transaction costs). <br /><br />

                            If a property is sold, the owners will need to burn their NFT to get their share from the sale. After the distribution of the funds generated through the sale, all obligations and benefits of the real estate NFT will be considered redeemed (i.e., with a gain or loss).



                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">What returns should be expected from a real estate NFT?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">We cannot guarantee anything. With this in mind, purchasing a real estate NFT can result in a complete loss of value, even though this is very unlikely. At Metropoly, our job is to identify and secure the most attractive real estate offerings for you. We examine properties from a total return standpoint, taking a balanced approach that factors yield from recurring rental income and potential capital appreciation into our selection process. We are also careful to balance the pursuit of high returns with value preservation and risk. <br /><br />

                            Our goal is to achieve a total annual return of up to 10% based on the experience of recent years, especially in cities such as Dubai. However, these numbers cannot be guaranteed, and many external factors depend on how a property performs. Therefore, every buyer of a real estate NFT is obligated to conduct their own independent research before purchasing one.



                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">What is gross yield?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">The gross yield is the percentage return that a specific property generates before deducting all expenses such as maintenance and management. <br /> <br />

                            For example, if a property is priced at $1,000,000 USD and rents for $120,000 USD per year, that property generates a gross income of $120,000 USD and a gross yield of 12%.



                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">What is net yield?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">The net yield of a property is the percentage return generated after deducting all expenses, such as management fees and maintenance costs. <br /><br />

                            For example, if a property is priced at $1,000,000 USD, rents for $100,000 USD per year, and costs $25,000 USD to maintain and cover all of the fees, then this property’s net income generates $75,000 USD and the net yield is 7.5%.



                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">What is capital appreciation?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">The capital appreciation of a property is the growth in the market value of the asset over time. <br /><br />

                            For example, if a property is acquired for $1,000,000 USD and the acquisition costs are $100,000 USD, the total amount is $1,100,000 USD. Assuming that the property is sold after several years at a price of $1,500,000 USD (i.e., the net selling price), then the capital appreciation is $400,000 USD, or 36.4% (i.e., $400,000 USD / $1,100,000 USD).



                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">How much will real estate NFT holders receive monthly?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">The amount received will be determined by the net yield of the property they own and the amount spent. <br /><br />

                            For example, if someone spent $100,000 USD into a property that generates a net yield of 7%, then they will receive $7,000 USD per year in rental income paid to their wallet on a monthly basis.




                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">Does it matter what price I get?

                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">Not really, since price is relative you will get the best anyways.

                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">How long is the deposit pool open?


                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">The pool is open for 30 Days.

                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">Does it matter on which day i deposit?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">No it doesnt. You get the same price if you deposit your ETH or BNBs on the 1st or 30th day

                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">Can I withdraw my funds once i have paid into the deposit pool?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">No you cannot. In order to prohibit price manipulation by whales who would withdraw at last second, withdrawals are not possible.

                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">Do I have to use this Community Fairlaunch or can I also buy on Pancakeswap like I usually do?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">No, for now you can only purchase here.
                        </p>
                    </details>


                </div>
                <div className="space-y-4 border border-black rounded-2xl pt-2 px-5 xs:px-2 sm:px-2 xs:w-[95%] xs:m-auto">
                    <details className="group">
                        <summary
                            className="flex items-center justify-between p-4 rounded-lg cursor-pointer border-b"
                        >
                            <h5 className="text-[18px] font-Poppins  font-[600]">Is this an ICO?
                            </h5>
                            <svg
                                className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </summary>

                        <p className=" text-[18px] font-Poppins sm:text-[14px] xs:text-[14px] font-[400] px-4 py-4 leading-relaxed ">No it is not. <br /> <br />
                            An initial coin offering (ICO) is the cryptocurrency industry’s equivalent of an initial public offering (IPO). A company seeking to raise money to create a new coin, app, or service can launch an ICO as a way to raise funds. Interested investors can buy into an initial coin offering to receive a new cryptocurrency token issued by the company. To do an ICO you need to do a KYC.

                        </p>
                    </details>


                </div> */}



            </div>
        </div>
    )
}
export default FAQsTab;