import React from "react";
import NftCard from "../../component/nftCard";





const Received = () => {
    return (
        <div className="xs:m-auto">
            <NftCard />
        </div>

    )
}
export default Received
    ;