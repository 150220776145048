import React from "react";
import { v4 as uuidv4 } from 'uuid';


import Rocket from "../assects/images/company/icons/rocket1.png"
import UserFlow from "../assects/images/company/icons/userflow.png"
import Boxes from "../assects/images/company/icons/boxes.png"
import Cd from "../assects/images/company/icons/cd.png";
import Tran from "../assects/images/tran.svg";
import property from "../assects/images/propertyManagement.svg";
import { useTranslation } from "react-i18next";


const BenefitData = [
    {
        id: uuidv4(),
        image: Rocket,
        title: 'Cashflow',
        subtitle: 'Rental income is a consistent and reliable way to increase cash flow, which helps achieve financial freedom.',
    },
    {
        id: uuidv4(),
        image: Boxes,
        title: 'Value Appreciation',
        subtitle: 'Real estate NFTs are backed by real-world properties with expected value appreciation to create long-term wealth.',
    },
    {
        id: uuidv4(),
        image: UserFlow,
        title: 'Passive Rental Income',
        subtitle: 'Earn genuinely passive income through monthly rental income that is automatically paid out in USDT.',
    },
    {
        id: uuidv4(),
        image: Cd,
        title: 'High Stability',
        subtitle: 'Real estate can provide a strong hedge against inflation as property values, and rental incomes increase yearly.',
    },
    {
        id: uuidv4(),
        image: property,
        title: 'Property Management',
        subtitle: 'The Metropoly team takes care of everything, from maintaining the property to ensuring that there’s always a tenant in place.',
    },
    {
        id: uuidv4(),
        image: Tran,
        title: 'Soon Available in Dubai',
        subtitle: 'Metropoly is soon available in Dubai, one of the fastest growing and most popular cities in the world.',
    },
]

const BenefitSection = () => {
    const {t} =useTranslation()
    return (
        <div className="bg-[#E8F7F8] py-16 2xl:px-32 xl:px-32 lg:px-32 md:px-12 sm:px-6 px-6" >
            <div className="text-center">
                <h2 className="font-[700] font-[Poppins] text-[30px]"> {t("Benefits")}</h2>
            </div>
            <div className="2xl:grid 2xl:grid-cols-3 xl:grid xl:grid-cols-3 lg:grid lg:grid-cols-3 md:grid md:grid-cols-2 sm:grid sm:grid-cols-2 grid grid-cols-1 gap-y-4 gap-x-5 pt-16">
                {
                    BenefitData.map((item, id) => (
                        <div key={id} className="bg-white px-4 pt-4 pb-16 space-y-3">
                            <img src={item.image} alt="" width={65} />
                            <h3 className="font-[700] font-[Poppins] text-[18px]">{t(item.title)}</h3>
                            <p className="font-[400] font-[Poppins] text-[14px] text-[#5B5B5B]">{t(item.subtitle)}</p>

                        </div>
                    ))
                }
            </div>

        </div>
    )
}
export default BenefitSection 
