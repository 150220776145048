import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Tick1 from "../assects/images/tick1.png"
import "./slider.css"


import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";



const RoadmapSection = () => {
    const {t} =useTranslation()
    
const cardData = [
    {
        id: 1,
        qa: "Phase 1",
        comp: t("COMPLETED"),
        phase: "Set up the Legal Structure",
        tickIcon: Tick1,
        paraData: [

            {
                id: uuidv4(),
                para: "Publication of the Metropoly Marketplace UX/UI Design",
            },
            {
                id: uuidv4(),
                para: "Publication of the Pitch Desk",
            },
            {
                id: uuidv4(),
                para: "Publication of the Website",
            },
            {
                id: uuidv4(),
                para: "Release of the NFT collection (Metropoly – Platinum Members Club)",
            },
            {
                id: uuidv4(),
                para: "Collaboration with Tenset",
            },
            {
                id: uuidv4(),
                para: "Smart Contract Audit with Solidproof",
            },
            {
                id: uuidv4(),
                para: "Partnerships with Travladd and DTC Group",
            },
        ]



    },
    {
        id: uuidv4(),
        qa: "Phase 2",
        comp: t("Current"),
        phase: "Release the first Beta Version of the Metropoly Marketplace",
        paraData: [

            {
                id: uuidv4(),
                para: "Release of the First Property on the Metropoly Marketplace",
            },
            {
                id: uuidv4(),
                para: "Implement the Inbox System and Favorites",
            },
            {
                id: uuidv4(),
                para: "Implement the Transaction History",
            },
            {
                id: uuidv4(),
                para: "Expand the Marketing Strategy",
            },
        ]
    },
    {
        id: uuidv4(),
        qa: "Phase 3",
        // comp: "",
        phase: "Release of the Beta Version of the App",
        paraData: [

            {
                id: uuidv4(),
                para: "Release of the Beta Version 2.0 of the Metropoly Marketplace",
            },
            {
                id: uuidv4(),
                para: "Implement Game-Fi Elements",
            },
            {
                id: uuidv4(),
                para: "Implement a Search Engine and Categorization",
            },
            {
                id: uuidv4(),
                para: "Implement a Customer Support System",
            },
            {
                id: uuidv4(),
                para: "Enable External Real Estate Companies to List Their RENs",
            },
        ]
    },
    {
        id: uuidv4(),
        qa: "Phase 4",
        // comp: "",
        phase: "Implement the Level System",
        paraData: [

            {
                id: uuidv4(),
                para: "Implement the In-App Trading Function",
            },
            {
                id: uuidv4(),
                para: "Implement the Lending System",
            },
            {
                id: uuidv4(),
                para: "Enable Credit Card Payment",
            },
            {
                id: uuidv4(),
                para: "Implement More Game-Fi Elements",
            },
        ]
    },
]

    return (
        <div className="bg-[#E8F7F8] pt-16 2xl:px-32 xl:px-32 lg:px-32 md:px-12 sm:px-6 px-6 " >

            <div className="text-center">
                <h2 className="font-[700] font-[Poppins] text-[30px]">Roadmap</h2>
            </div>
            <div className=" pt-16 w-[100%]" style={{paddingBottom:"-30px"}}>
                <div style={{ height: "80vh", position: "relative" }}>
                    <Swiper
                        slidesPerView={4}
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            500: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            750: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1124: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}

                        spaceBetween={20}
                        className="sliderWidth"
                        navigation={{
                            nextEl: ".image-swiper-button-next",
                            prevEl: ".image-swiper-button-prev",
                            disabledClass: "swiper-button-disabled"
                        }}
                        modules={[Navigation]}
                    >

                        {
                            cardData.map((item, id) => (
                                <SwiperSlide className="wrap" key={id} >
                                    <div className="bg-white px-4 pt-5 space-y-4 rounded-lg h-[72vh]">
                                        <div className="flex justify-between " >
                                            <h4 className="text-[#0072BA] text-[18px] font-[Poppins]">{item.qa}</h4>
                                            {
                                                item.id !== 1 ?
                                                    item.comp &&
                                                    <div className="bg-[#0072BA] flex items-center px-2 rounded-md">
                                                        <h4 className="text-[#FFFFFF]  text-[11px] font-[Poppins]">{(item.comp).toUpperCase()}</h4>
                                                    </div> :
                                                    <div className="bg-[#E8F7F8] flex items-center px-2 rounded-md">
                                                        <h4 className="text-[#000000]  text-[11px] font-[Poppins]">{t(item.comp).toUpperCase()}</h4>
                                                    </div>

                                            }
                                        </div>
                                        <h3 className="font-[600] font-[Poppins] text-[22px] leading-[25px]">{t(item.phase)} </h3>
                                        <div className="">
                                            {
                                                item.paraData.map((i, k) => (
                                                    <div className="flex">
                                                        {item.tickIcon ?
                                                            <img className="w-[18px] h-[18px] mr-2" src={item.tickIcon} alt="" /> : <></>}
                                                        <span key={k} className="font-[400] mb-4 font-[Poppins] text-[14px] leading-[20px]">{t(i.para)}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>


                                    </div>
                                </SwiperSlide>
                            ))
                        }

                    </Swiper>
                    <div className="swiper-button image-swiper-button-next">
                        <IoIosArrowForward />
                    </div>
                    <div className="swiper-button image-swiper-button-prev">
                        <IoIosArrowBack />
                    </div>
                </div>

            </div>

        </div>
    )
}
export default RoadmapSection 
