import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import File from "../../../../assects/images/claimfile.png"

import { useMoralis } from 'react-moralis';
import { ToastContainer, toast } from 'react-toastify';
import {formatCount} from "../../../../utils/formatNumber"

const History = ({ claimResults }) => {
    const [claimTable, setClaimTable] = useState([]);
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const { web3, isAuthenticated, user, Moralis } = useMoralis();

    const handleCopyUrl = (link) => {
        toast.success("Link Copied!", {
            position: toast.POSITION.TOP_RIGHT
        });
        navigator.clipboard.writeText(link);
    }
    const etherscan = "https://etherscan.io/tx/"

    const handleVisitEtherscan = (url) => {

        var win = window.open(etherscan + url, '_blank');
        win.focus();
    }
    const handleCutTXHash = (txHash) => {

        var first = txHash.slice(0, 8);
        var last = txHash.slice(-8);
        return first + "......" + last
    }
    const setAllClaimResult = () => {
        if (claimResults) {
            claimResults.map((index) => {
                let date = new Date(index.attributes.createdAt);
                let newData = {
                    id: uuidv4(),
                    tablerow: [{
                        date: month[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear(),
                        time: date.getHours() +":"+ date.getMinutes() +":"+ date.getSeconds(),
                        claim: formatCount(Moralis.Units.FromWei(index.attributes.currentClaim)),
                        tansection: index.attributes.transaction_hash,
                        icon: File,
                    }
                    ]
                }
                setClaimTable([...claimTable, newData])

            })
        }
    }
    useEffect(() => {
        if (claimResults) {
            setAllClaimResult();
        }
    }, [claimResults])
    return (
        <div className="pt-[40px] xs:py-[10px]">
            <div className="">
                <ToastContainer
                    position="top-right"
                    z-index="99"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
            <div className="w-[70%] m-auto xs:w-[100%] ms:w-[100%] ">
                <div className="overflow-x-auto relative rounded-xl">
                    <table className=" w-[100%] ">
                        <thead className="h-[63px] uppercase bg-[#2C8DFF] ">
                            <tr className=" text-left">
                                <th className="py-3 px-6 text-[16px] font-[600] font-[Poppins] text-white  ">
                                    Date
                                </th>
                                <th className="py-3 px-6 text-[16px] font-[600] font-[Poppins] text-white">
                                    Time
                                </th>
                                <th className="py-3 px-6 text-[16px] font-[600] font-[Poppins] text-white">
                                    Claimed Amount
                                </th>
                                <th className="py-3 px-6 text-[16px] font-[600] font-[Poppins] text-white">
                                    Transaction Hash
                                </th>

                            </tr>
                        </thead>
                        <tbody className="rounded-b-lg">
                            {
                                claimTable.map((item) => (

                                    <>
                                        <tr className="bg-[#FAFAFA] border rounded-b-lg  ">
                                            {
                                                item.tablerow.map((i) => (

                                                    <>
                                                        <td className="w-[25%] py-5 px-6 text-[14px] font-[500] font-[Poppins] rounded-b-lg">
                                                            {i.date}
                                                        </td>
                                                        <td className="w-[25%] py-5 px-6 text-[14px] font-[500] font-[Poppins] rounded-b-lg">
                                                            {i.time}
                                                        </td>
                                                        <td className="w-[25%] py-5 px-6 text-[14px] font-[500] font-[Poppins] rounded-b-lg">
                                                            {i.claim} <span className="text-[14px] font-[500] font-[Poppins] text-[gray]">METRO</span>
                                                        </td>
                                                        <td className=" py-5 px-4 text-[14px] font-[500] font-[Poppins] rounded-b-lg flex justify-between items-center ">
                                                            <p onClick={() => handleVisitEtherscan(i.tansection)}>{handleCutTXHash(i.tansection)}</p>
                                                            <img className=" h-[20px]  " src={i.icon} alt="icon" onClick={() => handleCopyUrl(i.tansection)} />

                                                        </td>

                                                    </>
                                                ))
                                            }

                                        </tr>
                                    </>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
                {/* <div className="flex justify-end py-[41px]">
                    <Button classes="bg-[#2892F3] w-[150px] h-[54px] text-white rounded-lg " text="Load More" />
                </div> */}

                {/* <div className="overflow-x-auto relative rounded-xl ">
                    <table className=" w-[100%] py-2 rounded-xl ">

                        <tbody className="rounded-xl ">
                            {
                                previousData.map((item) => (

                                    <>
                                        <tr className=" bg-[#FAFAFA] w-[100%%] border rounded-xl space-x-10 ">
                                            {
                                                item.tablerow.map((i) => (


                                                    <>

                                                        <div className="flex justify-between py-6 px-5 bounded-t-xl ">
                                                            <div className=" justify-between">
                                                                <h5 className="text-[14px] font-[500] font-[Poppins]">{i.title}</h5>

                                                            </div>
                                                            <div>
                                                                <h5 className="text-[14px] font-[500] font-[Poppins]">{i.subtitle}</h5>
                                                            </div>
                                                        </div>

                                                    </>
                                                ))
                                            }

                                        </tr>
                                    </>
                                ))
                            }


                        </tbody>
                    </table>
                </div> */}
            </div >

        </div>
    )
}
export default History;