import React, { useState, useEffect } from 'react';
import Button from "../../../components/Button/button";
import Amontimg from "../../../assects/images/amountImg.png"





const NftCard = ({ handleOpen, open, handleClaim }) => {


    return (
        <div className='pt-[40px] xs:pt-[10px]'>
            <div className='flex w-[280px] h-[305px] xs:m-auto sm:m-auto justify-center items-center py-2 rounded-[30px] shadow-2xl bg-[#ecf5f9] '>
                <div className='nft-background-img  '>
                    <div className='bg-overlay h-[100%] w-[250px] relative'>
                        <div className='flex justify-between items-center absolute bottom-5 w-[100%] px-4 '>
                            <div>
                                <h3 className='text-[18px] font-[Poppins] font-[600] text-[white]'>Tycoon NFT</h3>
                            </div>

                            <div>
                                <h5 className='text-[10px] text-[white] font-[Poppins] font-[500] pl-3'>NFT Price</h5>
                                <div className='flex items-center space-x-2'>
                                    <img className='w-[12px] h-[12px]' src={Amontimg} alt="" />
                                    <h4 className='text-[16px] text-[white] font-[Poppins] font-[600]'>1,000</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NftCard;