import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import { useMoralis, useMoralisWeb3Api, useMoralisQuery, useWeb3Transfer } from 'react-moralis';
import { useTranslation } from "react-i18next";


const VestingPeriod = () => {

        const { t } = useTranslation()
        const BonusData = [
            {
                id: uuidv4(),
                title: '1st ' + t("claim"),
                bonusValue: '10%',
                value: '24 hours after Launch',
            },
            {
                id: uuidv4(),
                title: '2nd ' + t("claim"),
                bonusValue: '10%',
                value: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '3rd ' + t("claim"),
                bonusValue: '10%',
                value: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '4th ' + t("claim"),
                bonusValue: '10%',
                value: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '5th ' + t("claim"),
                bonusValue: '10%',
                value: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '6th ' + t("claim"),
                bonusValue: '10%',
                value: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '7th ' + t("claim"),
                bonusValue: '10%',
                value: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '8th ' + t("claim"),
                bonusValue: '10%',
                value: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '9th ' + t("claim"),
                bonusValue: '10%',
                value: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '10th ' + t("claim"),
                bonusValue: '10%',
                value: 'after 10 days',
            },

        ]

    const [metroPrice, setMetroPrice] = useState(0);
    const [userWallet, setUserWallet] = useState("")
    const [userContribution, setUserContribution] = useState(0);

    const { Moralis, isAuthenticated, authenticate, user } = useMoralis();
    const getCurrentPrice = async () => {

        const readOptions = {
            contractAddress: "0x79Cf3b1273fD7E309c5D6D9acc65745c13cee397",
            functionName: "finalTokenPrice",
            chain: "goerli",
            abi: [{
                "inputs": [],
                "name": "finalTokenPrice",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }],
        };

        const message = await Moralis.executeFunction(readOptions);
        setMetroPrice(Moralis.Units.FromWei(parseInt(message)).slice(0, 7));
    }


    const getUserContribution = async () => {

        const readOptions = {
            contractAddress: "0x79Cf3b1273fD7E309c5D6D9acc65745c13cee397",
            functionName: "totalUserContribution",
            chain: "goerli",
            abi: [{
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "name": "totalUserContribution",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }],
            params: {
                "": userWallet
            }
        };

        const message = await Moralis.executeFunction(readOptions);
        setMetroPrice(Moralis.Units.FromWei(parseInt(message)).slice(0, 7));

        let contri = parseInt(message).toString().slice(0, parseInt(message).toString().length);
        setUserContribution((contri / 1000000).toLocaleString('en-US'));
    }

    return (
        <div className="">
            <div className="2xl:w-[70%] xl:w-[80%] lg:w-[80%]  sm:w-[100%] xs:w-[100%] m-auto   sm:px-0  xs:px-0 sm:pt-2 xs:pt-2">
                <div className="border">

                    <div className="flex justify-between py-4 px-5 xs:px-2 sm:px-2 border-b bg-[#ECF4FF]">
                        <h3 className="w-[44%] sm:w-[30%] xs:w-[30%]  text-[16px] sm:text-[14px] xs:text-[14px] sm:text-[14px] xs:text-[14px] font-semibold font-Poppins text-[#000000]">{t("Claims")}</h3>
                        <h3 className="w-[20%] text-[16px] sm:text-[14px] xs:text-[14px] sm:text-[14px] xs:text-[14px] font-semibold font-Poppins text-[#000000]">{t("Percentage")}</h3>
                        <h3 className="w-[30%] sm:w-[30%] xs:w-[40%] text-end text-[16px] sm:text-[14px] xs:text-[14px] sm:text-[14px] xs:text-[14px] font-semibold font-Poppins text-[#000000]">{t("Unlock Period")}</h3>
                    </div>
                    {

                        BonusData.map((item, id) => (
                            <div key={id} className="border-b flex justify-between py-3 px-5 xs:px-2 sm:px-2">
                                <h4 className="pl-3 xs:pl-0 sm:pl-0 w-[44%] sm:w-[30%] xs:w-[30%]  text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000] sm:text-[14px] xs:text-[14px]">{item.title}</h4>
                                <h4 className="pl-3 sm:pl-1 xs:pl-1 w-[21%] sm:w-[10%] text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000] sm:text-[14px] xs:text-[14px]">{item.bonusValue}</h4>
                                <h4 className="pl-3 w-[30%] sm:w-[30%] xs:w-[40%] text-end text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000] sm:text-[14px] xs:text-[14px]">{t(item.value)}</h4>
                            </div>

                        )
                        )

                    }

                </div>

            </div>
        </div>
    )
}
export default VestingPeriod;