import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import ButtonDropdown from "../../components/ButtonDropdown";
import Tab from '../tabs'
import Campaign from "./tabs/Campaign";
import RealEstateCalculator from "./tabs/RealEstateCalculator";
import FairLaunch from "./tabs/FairLaunch";
import FAQsTab from "./tabs/FAQsTab";
import CreateNftDropdown from "../../components/Dropdown/CreateNft";
import AddPlusIcon from '../../assects/svgs/add-plus.svg';
import Tokenomics from "./tabs/Tokenomics";
import VestingPeriod from "./tabs/VestingPeriod";
import Roadmap from "./tabs/Roadmap";
import InvestorSection from "../CoInvester"
import MetropolyContract from "../MetropolyContract";
import ReadSection from "../ReadSection";
import RoadmapSection from "../RoadmapSection";
import PartnersSection from "../Partners";
import yahoo from "../../assects/images/company/yahoo.png";
import bloomberg from "../../assects/images/company/bloomberg.png";
import btc from "../../assects/images/company/btc.com.png";


import handshake from "../../assects/images/company/hand.png"
import Testimonials from "../Testimonials";
import { useTranslation } from "react-i18next";

const TabSection = ({ handleRange }) => {
    const [createdNft, setCreatedNft] = useState(null);
    const { t } = useTranslation();
    const handleCampaign = () => {
        setCreatedNft(1);
    }


    useEffect(() => {
        handleCampaign();
    }, []);


    return (
        <div className=" font-[poppins]" >

            {/* <div className="mt-[30px] flex  justify-between border-b-2">
                <div className="flex space-x-[30px] md:space-x-6 sm:space-x-2 xs:space-x-2 px-20 sm:px-10 xs:px-6 py-0">
                    {/* <Tab handleActive={handleCampaign} text={'Campaign'} classes={` px-1 xs:px-0 ${createdNft === 1 ? ' text-[#2892F3] sm:text-[10px] xs:text-[10px]  bg-[#ffff] border-b-2 border-blue-400 ' : 'sm:text-[10px] xs:text-[10px] '}`} />
                    <Tab handleActive={handleRealEstate} text={'Fair Launch Structure'} classes={`px-1 xs:px-0 ${createdNft === 2 ? 'sm:hidden xs:hidden sm:text-[10px] xs:text-[10px]  text-[#2892F3] bg-[#ffff] border-b-2 border-blue-400' : 'sm:hidden xs:hidden  sm:text-[10px] xs:text-[10px] '}`} />
                    <Tab handleActive={handleRealEstate} text={'Structure'} classes={` px-1 xs:px-0 ${createdNft === 2 ? 'xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden sm:text-[10px] xs:text-[10px]  text-[#2892F3] bg-[#ffff] border-b-2 border-blue-400' : 'xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden  sm:text-[10px] xs:text-[10px] '}`} />
                    <Tab handleActive={handleFAQs} text={'Roadmap'} classes={` px-1 xs:px-0 ${createdNft === 3 ? 'sm:hidden xs:hidden sm:text-[10px] xs:text-[10px]  text-[#2892F3] bg-[#ffff] border-b-2 border-blue-400' : 'sm:hidden xs:hidden sm:text-[10px] xs:text-[10px] '}`} />
                    <Tab handleActive={handleFAQs} text={'Roadmap'} classes={`px-1 xs:px-0 ${createdNft === 3 ? 'xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden sm:text-[10px] xs:text-[10px]  text-[#2892F3] bg-[#ffff] border-b-2 border-blue-400' : 'xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden  sm:text-[10px] xs:text-[10px] '}`} />
                    <Tab handleActive={handleVesting} text={'Vesting Period '} classes={`px-1 xs:px-0 ${createdNft === 4 ? 'sm:hidden xs:hidden  sm:text-[10px] xs:text-[10px]  text-[#2892F3] bg-[#ffff] border-b-2 border-blue-400' : 'sm:hidden xs:hidden  sm:text-[10px] xs:text-[10px] '}`} />
                    <Tab handleActive={handleVesting} text={'Vesting'} classes={`px-1 xs:px-0 ${createdNft === 4 ? 'xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden sm:text-[10px] xs:text-[10px]  text-[#2892F3] bg-[#ffff] border-b-2 border-blue-400' : 'xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden  sm:text-[10px] xs:text-[10px] '}`} />
                    <Tab handleActive={handleTokenomics} text={'Tokenomics'} classes={`px-1 xs:px-0 ${createdNft === 5 ? 'sm:text-[10px] xs:text-[10px]  text-[#2892F3] bg-[#ffff] border-b-2 border-blue-400' : 'sm:text-[10px] xs:text-[10px] '}`} />
                    <Tab handleActive={handleRoadmap} text={'Frequently Asked Questions'} classes={` px-1 xs:px-0 ${createdNft === 6 ? 'sm:hidden xs:hidden  sm:text-[10px] xs:text-[10px]  text-[#2892F3] bg-[#ffff] border-b-2 border-blue-400' : 'sm:hidden xs:hidden  sm:text-[10px] xs:text-[10px] '}`} />
                    <Tab handleActive={handleRoadmap} text={'FAQs '} classes={`px-1 xs:px-0 ${createdNft === 6 ? 'xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden sm:text-[10px] xs:text-[10px]  text-[#2892F3] bg-[#ffff] border-b-2 border-blue-400' : 'xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden  sm:text-[10px] xs:text-[10px] '}`} /> *

                </div>
            </div> */}
            <div >
                {createdNft === 1 &&
                    <div >
                        <Campaign handleRange={handleRange} />

                        <ReadSection />
                        <Tokenomics />
                        <MetropolyContract />
                        <RoadmapSection />


                        {/* <div className="  py-16 2xl:pl-20 xl:px-20 lg:px-20 md:px-12 sm:px-6 px-6 sm:text-center xs:text-center md:text-center  " >
                            <div className="flex-row items-center 2xl:flex xl:flex lg:flex md:flex sm:block">
                                <div className="basis-2/4 space-y-5 ">
                                    <h3 className="text-[30px] font-[700] font-[Poppins] ">{t("Collaboration with World Class Blockchain Incubator")}</h3>
                                    <p className="text-[15px] font-[400] font-[Poppins]">{t("Metropoly has joined forces with Tenset, the incubator and masterminds behind Metahero (100x return from launch) and Everdome (80x return from launch).")}<br /><br />
                                        {t("Tenset’s Infinity Program and success-driven approach, sky-high returns on (previous) collaborations and cutting-edge solutions is making them the perfect partner to elevate Metropoly to new heights.")}
                                    </p>
                                </div>
                                <div className="basis-2/4 space-y-5 p-16 md:p-10 sm:p-6 xs:p-6">

                                    <img src={handshake} alt="" />
                                </div>
                            </div>

                        </div> */}
                        <Testimonials />
                        <span id="teams"></span>
                        {/* <InvestorSection className="pb-6" /> */}
                        <PartnersSection />
                        <FAQsTab />
                    </div>
                }

                {createdNft === 2 &&
                    <div className="px-20  py-9 sm:py-3 xs:py-3 sm:px-10 xs:px-6">
                        <FairLaunch />

                    </div>

                }

                {createdNft === 3 &&
                    <div className="px-20  py-9 sm:py-3 xs:py-3 sm:px-10 xs:px-6">
                        <Roadmap />

                    </div>

                }
                {createdNft === 4 &&
                    <div className="px-20  py-9 sm:py-3 xs:py-3 sm:px-10 xs:px-6">
                        <VestingPeriod />

                    </div>

                }

                {createdNft === 5 &&
                    <div className=" sm:py-3 xs:py-3 sm:px-0 xs:px-0">
                        <Tokenomics />

                    </div>

                }
                {createdNft === 6 &&
                    <div className="px-20 bg-[#E9F1F9]  py-9 sm:py-3 xs:py-3 sm:px-10 xs:pl-[1.2rem] xs:pr-[1.5rem]">


                    </div>

                }

            </div>

        </div>

    )
}
export default TabSection;