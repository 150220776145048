import React, { Suspense, useEffect, useState } from "react";
// import ReactGA from 'react-ga';
import { RWebShare } from "react-web-share";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Real from "../../assects/images/real.png"
import TabSection from "../../appcomponents/TabsSection";
import ProductDetalis from "../../appcomponents/ProductDetalis";
import RewardSection from "../../appcomponents/RewardsSection";
import Button from "../../components/Button/button";

import Twitter from "../../assects/images/twitter-lite.svg";
import Link from "../../assects/images/link.svg";
import Insta from "../../assects/images/insta.svg";
import Halo from "../../assects/images/halo.svg";
import Arrow from "../../assects/images/arrow-lite.svg";
import Share from "../../assects/images/share.png";
import ShareBlue from '../../assects/svgs/sharesvgblue.svg';
import Timer from "../../components/bubbleTimer/BubbleTimer";
import BenefitSection from "../../appcomponents/BenefitsSection";
import RoadmapSection from "../../appcomponents/RoadmapSection";
import ReadSection from "../../appcomponents/ReadSection";
import SecureSection from "../../appcomponents/SecureSection";
import DontMissSection from "../../appcomponents/DontMissOutSection";
import BetaVersionSection from "../../appcomponents/BetaVersionSection";
import FeaturedSection from "../../appcomponents/featuredSection";
import HowToBuySection from "../../appcomponents/HowToBuySection";
import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router-dom";



function Home({ handleOpen, handleNavbarMenu, handleClaim, open }) {

    const { t, i18n } = useTranslation()
    // const TRACKING_ID = "G-WWFPRFM6XR"; 
    // ReactGA.initialize(TRACKING_ID);

    const [range, setRange] = useState(1000);
    const [stageTimer, setStageTimer] = useState(100);
    const handleCopyUrl = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.success("Link Copied!", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const handleRange = (range) => {
        setRange(range)
    }

    return (
        <>
            <div className=" pt-4 relative z-">
                <div className="px-20 sm:px-10 xs:px-6">
                    {/* 
                    <div className="fixed right-5 bottom-0 z-[10]">
                        <Timer stageTimer={stageTimer} setStageTimer={setStageTimer} />
                    </div> */}

                    <div className="space-y-2 sm:space-y-1 xs:space-y-0 ">
                        <div className="flex justify-between items-start pt-6 ">
                            <div className="">
                                <h2 className="text-[30px] font-[700] font-[Poppins] leading-10">{t("Invest in Real Estate with Crypto in Seconds")}</h2>
                            </div>
                            <div className="md:auto lg:hidden xl:hidden 2xl:hidden flex pt-[4px] cursor-pointer">
                                <RWebShare
                                    data={{
                                        text: "Invest in Real Estate with Crypto in Seconds",
                                        url: window.location.href,
                                        title: "Invest in Real Estate with Crypto in Seconds",
                                    }}>
                                    <img src={Share} className="w-[30px] h-[15px] " alt="" />
                                </RWebShare>
                            </div>

                            <div className="flex space-x-2 md:hidden sm:hidden xs:hidden">

                                <Button classes="border hover:border-2 flex items-center justify-center rounded-md border-blue-300 text-[9px] p-2 w-[33px] h-[24px]" wallet={Link} onClick={handleCopyUrl} />
                                <a href='https://discord.com/invite/N4CFyMqCdW' target="_blank">
                                    <Button classes="flex items-center justify-center border hover:border-2 border-blue-300 text-[9px] p-2 w-[33px] h-[24px] rounded-md" wallet={Halo}
                                    />
                                </a>
                                <a href='https://www.instagram.com/metropoly_io' target="_blank">
                                    <Button classes="flex items-center justify-center border hover:border-2 border-blue-300 text-[9px] p-2 w-[33px] h-[24px] rounded-md" wallet={Insta}
                                    />
                                </a>
                                <a href='https://twitter.com/metropoly_io' target="_blank">

                                    <Button classes=" flex items-center justify-center border hover:border-2 border-blue-300 text-[9px] p-2 w-[33px] h-[24px] rounded-md" wallet={Twitter}
                                    />
                                </a>
                                <a href='https://t.me/metropoly_official' target="_blank">

                                    <Button classes="flex items-center justify-center border hover:border-2 border-blue-300 text-[9px] p-2 w-[33px] h-[24px] rounded-md" wallet={Arrow}
                                    />
                                </a>

                            </div>

                        </div>
                        <div className="">
                            <ToastContainer
                                position="top-right"
                                z-index="99"
                                autoClose={3000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </div>

                        <h5 className="text-[20px] sm:text-[12px] xs:text-[12px] text-[#929292] font-Inter  leading-[24px] py-1.5">{t("The world’s first NFT marketplace backed by real-world properties.")}</h5>
                        <div className="flex items-center space-x-1.5 sm:space-x-1 xs:space-x-1" id="realEstate">
                            <p className="bg-[#E9F1F9] sm:text-[10px] xs:text-[10px] text-[#929292] text-[10px] px-[6px]  py-[5px] rounded-sm">REAL ESTATE</p>
                            <p className="bg-[#F5F5F5] sm:text-[10px] xs:text-[10px] text-[#929292] text-[10px] px-[6px]  py-[5px] rounded-sm">ETHEREUM</p>
                            <p className="bg-[#F5F5F5] sm:text-[10px] xs:text-[10px] text-[#929292] text-[10px] px-[6px]  py-[5px] rounded-sm">B2C</p>
                            <p className="bg-[#F5F5F5] sm:text-[10px] xs:text-[10px] text-[#929292] text-[10px] px-[6px]  py-[5px] rounded-sm">PRESALE</p>

                        </div>



                    </div>
                    <div className="pb- ">

                        <ProductDetalis handleOpen={handleOpen} handleClaim={handleClaim} range={range} handleNavbarMenu={handleNavbarMenu} open={open} />

                    </div>
                    <div>

                    </div>
                </div>
                <div>
                    <FeaturedSection />
                </div>
                <div>
                    <SecureSection />
                </div>
                <div>
                    <BetaVersionSection />
                </div>
                <div>
                    <BenefitSection />
                </div>
                {/* <div>
                    <HowToBuySection />
                </div> */}
                <div>
                </div>
                {/* <div>
                    <DontMissSection />
                </div> */}

                {/* <div>
                    <div className="px-20 sm:px-10 xs:px-6 py-12 md:py-10  bg-[#E9F1F9]">
                        <div className="flex justify-between md:block md:justify-center  md:space-y-8 sm:block sm:justify-center  sm:space-y-8 xs:block xs:justify-center  xs:space-y-8">

                            <div className=" space-y-3 leading-[21px] flex items-center flex-col text-center  w-[30%] md:w-[100%] sm:w-[100%] xs:w-[100%]">
                                <img className="w-[45px] h-[45px]" src={Audity} alt="" />
                                <h3 className="text-[18px] sm:text-[14px] xs:text-[14px] font-[700] pt-2 text-[#252525] font-Poppins">Contract Audited by CertiK</h3>
                                <p className="text-[18px] sm:text-[14px] xs:text-[14px] font-Poppins text-center font-[400] text-[#252525] font-Poppins">One of the most trusted auditing companies worldwide.</p>
                            </div>
                            <div className=" space-y-3 leading-[21px] flex items-center flex-col text-center  w-[30%] md:w-[100%] sm:w-[100%] xs:w-[100%]">
                                <img className="w-[40px] h-[40px]" src={KYC} alt="" />
                                <h3 className="text-[18px] sm:text-[14px] xs:text-[14px] font-[700] pt-2 text-[#252525] font-Poppins">Dedicated and Fully Doxxed Team</h3>
                                <p className="text-[18px] sm:text-[14px] xs:text-[14px] font-Poppins text-center font-[400] text-[#252525] font-Poppins">Our international Team is experienced in building and scaling start-ups.</p>
                            </div>
                            <div className=" space-y-3 leading-[21px] flex items-center flex-col text-center  w-[30%] md:w-[100%] sm:w-[100%] xs:w-[100%]">
                                <div className="flex items-center">
                                    <img className="w-[28px] h-[28px]" src={USDC} alt="" />
                                    <img className="w-[40px] h-[40px]" src={ETH} alt="" />
                                    <img className="w-[40px] h-[40px]" src={BNB} alt="" />

                                </div>

                                <h3 className="text-[18px] sm:text-[14px] xs:text-[14px] font-[700] pt-2 text-[#252525] font-Poppins">USDT, ETH & BNB  </h3>
                                <p className="text-[18px] sm:text-[14px] xs:text-[14px] font-Poppins text-center font-[400] text-[#252525] font-Poppins"> Be the first to buy $METRO before we officially launch.   </p>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div >

                </div>

                {/* <p style={{ top: "-50px", position: "relative" }} id="rewards"></p> */}
                {/* <div >
                    <RewardSection handleRange={handleRange} className="pb-6" />
                </div> */}
                <div className="">
                    <TabSection handleRange={handleRange} />
                </div>


            </div>
        </>
    );
}

export default Home;
