import React from "react";
import { useTranslation } from "react-i18next";

// import BgMask from "../assects/images/BgMask.png"
import BetaVersion from "../assects/images/betaVersion.png"
import stars from "../assects/images/company/icons/stars.png"



const BetaVersionSection = () => {
    const {t} = useTranslation()
    return (
        <div className="  py-16 2xl:px-32 xl:px-32 lg:px-32 md:px-12 sm:px-6 px-6 " >
            <div className="w-full block items-center text-center 2xl:flex xl:flex lg:flex md:flex sm:flex flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col justify-center">
                <div className="2xl:w-[48%] xl:w-[48%] lg:w-[48%] md:w-[48%] sm:w-full w-full space-y-5 mb-10  justify-center lg:mr-4 xl:mr-4 2xl:mr-4">
                    <h3 className="text-[30px] font-[700] font-[Poppins] ">{t("Beta Is Live Now")}
                    </h3>
                    <p className="text-[15px] cfont-[400] font-[Poppins]">{t("Ready for the future of real estate? The Metropoly Marketplace Beta is live and ready for action! Keep in mind that these are just illustrations, but they showcase the incredible vision we have for this project. Check it out now and share your feedback to help shape the future.")}</p>
                    <div className="justify-center flex">
                        <a href="https://prototype.metropoly.io"><button className="bg-[#0072BA] text-white rounded-md flex mt-5 items-center py-2 px-7 space-x-3 uppercase">{t("Visit Metropoly Beta")}</button></a>
                    </div>

                    <div className="justify-center flex items-center">
                        <img src={stars} style={{ height: "100%", width: "12%" }} alt="" />
                        <p className="text-black rounded-md flex items-center py-2 px-2 space-x-3">{t("Trusted by 3,000+")}</p>
                    </div>

                </div>
                <div className="2xl:w-[45%] xl:w-[45%] lg:w-[45%] md:w-[45%] sm:w-full w-full lg:ml-4 xl:ml-4 2xl:ml-4">
                    <img className="" src={BetaVersion} alt="" />
                </div>
            </div>
        </div>
    )
}
export default BetaVersionSection
