import React, { useState, useEffect, useCallback } from "react";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useMoralis, useMoralisWeb3Api, useMoralisQuery } from 'react-moralis';
import Select, { components } from "react-select";
import Button from "../../../../components/Button/button";
import ClaimCard from "../../component/ClaimCard";
import StageCard from "../../component/StageCard";

import Iicon from "../../../../assects/images/Iicon.png"
import { useSelector, useDispatch } from 'react-redux'

import { ECDH } from "crypto";


const Dashboard = ({
    currentClaimed,
    metroTokensClaimed,
    userTotalTokens,
    userTotalBonusTokens,
    userContribution,
    userContributionId,
    ownedMrMetropoly,
}) => {

    const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];

    function formatCount(count, withAbbr = true, decimals = 2) {
        const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
        let result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
        if (withAbbr) {
            result += `${COUNT_ABBRS[i]}`;
        }
        return result;
    }
    const [pop, setPop] = useState(false);
    return (
        <div className="pt-[40px] xs:pt-[10px] sm:pt-[10px]">
            <div className="flex justify- xs:block">
                <div className=" w-[80%]  lg:w-[730px] xl:w-[738px] 2xl:w-[738px] xs:w-[100%] sm:w-[100%] h-[40vh] xs:h-[auto] sm:h-[auto] xs:pb-8" >
                    <div className="flex xs:block sm:block xs:space-y-5  pb-[38px] pt-0">
                        <div className="w-[50%] xs:w-[100%] space-y-1">
                            <h5 className="text-[#616161] text-[18px] font-[500] font-[Poppins]">Total Token Purchased
                                <div className={`${pop ? 'absolute z-99 w-[220px] right-0 -top-10   text-center py-2 px-3 bg-[#F7F8FA]  rounded-lg text-[#22222] text-sm' : 'hidden'}`}>
                                    <span className="">Metropoly has raised% of a minimum goal of $10k
                                    </span>
                                    <div className="absolute z-99 w-5 h-5 -bottom-1   right-1 bg-[#F7F8FA]   rotate-45"></div>
                                </div>
                            </h5>
                            <h3 className=" text-[20px] font-[600] font-[Poppins]">{formatCount(userTotalTokens)} <span className="pl-3 text-[16px] font-[500] font-[Poppins]">METRO</span></h3>
                            <p className="text-[#0072BA] text-[16px] font-[500] font-[Poppins]">You invested {formatCount(userContribution)} USD</p>
                        </div>
                        <div className="w-[50%] xs:w-[100%] space-y-1">
                            <h5 className="text-[#616161] text-[18px] font-[500] font-[Poppins]">Total Bonus</h5>
                            <h3 className=" text-[20px] font-[600] font-[Poppins] text-[#13CF8F]">{formatCount(userTotalBonusTokens)} <span className="pl-3 text-[16px] font-[500] font-[Poppins] text-[black]">METRO</span></h3>

                        </div>
                    </div>
                    <hr className="w-[75%] xs:w-[100%] sm:w-[100%]" />
                    <div className="flex xs:block sm:block xs:space-y-5 pb-[38px] pt-[34px]">
                        <div className="w-[50%] xs:w-[100%] xs:space-y-3 space-y-1">
                            <h5 className="text-[#616161] text-[18px] font-[500] font-[Poppins]">Total Tokens Claimed</h5>
                            <div className="flex xs:block xs:space-x-0 space-x-3">
                                <h3 className=" text-[20px] font-[600] font-[Poppins]">{formatCount(metroTokensClaimed)}<span className="pl-3 text-[16px] font-[500] font-[Poppins]">METRO</span></h3>
                                {/* <h3 className=" text-[20px] font-[600] font-[Poppins] text-[#13CF8F]">+12,500  <span className="text-[black] pl-3 text-[16px] font-[500] font-[Poppins]">Bonus</span></h3> */}

                            </div>
                        </div>
                        <div className="w-[50%] xs:w-[100%] xs:space-y-3 space-y-1">
                            <h5 className="text-[#616161] text-[18px] font-[500] font-[Poppins]">Tokens Eligible for Next Distribution</h5>
                            <div className="flex space-x-3 xs:block xs:space-x-0">
                                <h3 className=" text-[20px] font-[600] font-[Poppins]">5,000<span className="pl-3 text-[16px] font-[500] font-[Poppins]">METRO</span></h3>
                                <h3 className=" text-[20px] font-[600] font-[Poppins] text-[#13CF8F]">+2,500  <span className="text-[black] pl-3 text-[16px] font-[500] font-[Poppins]">Bonus</span></h3>

                            </div>
                        </div>
                    </div>
                    <hr className="w-[75%] xs:w-[100%] sm:w-[100%]" />
                    <div className="flex xs:block sm:block  xs:space-y-5 pb-5 pt-6">
                        <div className="w-[50%] xs:w-[100%] xs:space-y-3 space-y-1">
                            <h5 className="text-[#616161]  text-[18px] font-[500] font-[Poppins]">Remaining tokens to claim</h5>
                            <div className="flex space-x-3 xs:block xs:space-x-0">
                                <h3 className=" text-[20px] font-[600] font-[Poppins]">{formatCount(userTotalTokens - metroTokensClaimed)}<span className="pl-3 text-[16px] font-[500] font-[Poppins]">METRO</span></h3>
                                <h3 className=" text-[20px] font-[600] font-[Poppins] text-[#13CF8F]">+35,000  <span className="text-[black] pl-3 text-[16px] font-[500] font-[Poppins]">Bonus</span></h3>
                            </div>
                        </div>
                        <div className="w-[50%] xs:w-[100%] space-y-1">
                            <h5 className="text-[#616161] text-[18px] font-[500] font-[Poppins]">Next claim date</h5>
                            <h3 className="bg-[#93caff2e] text-[#0072BA] w-[200px] flex items-center justify-center h-[55px] text-[25px] font-[500] font-[Poppins]">After Presale</h3>

                        </div>
                    </div>
                </div>

                <div className=" w-[242px] xs:m-auto sm:m-auto flex flex-end">
                    <div className="w-[242px] space-y-3">
                        <ClaimCard />
                        <div className="">
                            <Button classes="bg-[#2892F3] text-[white] font-[Poppins] text-[24px] w-[241px] h-[65px] rounded-lg font-[700]" text="Claim" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative border border-blue-500 rounded-xl my-[68px] px-6 py-8 space-y-3">
                <div className="space-y-0 leading-3">
                    <label htmlFor="" className="text-[26px] font-[Poppins] font-[800] text-[#0072BA]">Claims Dates</label>
                    <div className="popover__wrapper pt-2">
                        <Slider
                            trackStyle={{ background: "#0072BA" }}
                            activeDotStyle={{ background: "#0072BA" }}
                            handleRender={renderProps => {
                                return (
                                    <div {...renderProps.props}>
                                        <div className="space-y-2 w-[150px] relative py-4 -top-[85px] -left-3 bg-[#0072BA] text-center before:absolute before:bg-[#0072BA] before:h-[20px] before:w-[20px] before:-bottom-2 before:origin-center before:rotate-45 before:left-2" >
                                            <h3 className="text-white text-[11px] font-[700] text-[Poppins]">Stage 2</h3>
                                            <p className="popover__message text-white text-[11px] font-[700] text-[Poppins]">35,000 METRO</p>
                                            <span className="" ></span>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </div>
                </div>
                <div className=" flex items-center w-full dark:text-gray-50">
                    <button aria-label="Slide back" type="button" className="absolute left-[-45px] z-30 p-2 ml-10  focus:dark:bg-gray-400 focus:ring-2 focus:ring-blue-2 focus:ring-blue-400 focus:rounded-full">
                        <svg width="8" height="14" fill="none" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
                            <path d="M7 1L1 7L7 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </button>
                    <div className="flex bg--stages overflow-auto margin-0 py-3  gap-x-4 scrollbarclaim">
                        <StageCard />
                    </div>
                    <button aria-label="Slide forward" id="next" className="absolute right-[-45px] z-5 p-2 mr-10 focus:outline-none focus:rounded-full focus:dark:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-blue-400">
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
                            <path d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </button>
                </div>


            </div>

        </div>

    )
}
export default Dashboard;