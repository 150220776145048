
import React, { useState, useEffect } from 'react';
import Button from "../../../components/Button/button";





const ClaimCard = ({ handleOpen, open, handleClaim }) => {


    return (
        <div>
            <div className='w-[242px] flex justify-center py-2 rounded-xl shadow-2xl'>
                <div className='Claim-background-img'>
                    <div className='bg-overlay h-[100%] relative'>
                        <div className='flex justify-between items-center absolute bottom-2 w-[100%] px-4 '>
                            <div>
                                <h5 className='text-[11px] font-[Poppins] font-[500] text-[white]'>NFTs Recieved</h5>
                                <h3 className='text-[24px] font-[Poppins] font-[800] text-[white]'>1</h3>
                            </div>
                            <div>
                                <Button classes="bg-white text-[#0072BA] font-[Poppins] font-[800] rounded-lg px-5 py-2" text="View" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ClaimCard;
