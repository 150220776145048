import React from 'react';



const Giveaway = () => {
  return (
    <div className='container-fulid bg-[#ecf4ff]'>
      <div className='flex px-20 py-12 justify-between'>
        <div className='w-[50%]'>
          <h2 className='font-[Poppins] text-[40px] text-black font-[700]'>Win a Beautiful Burj Khalifa Apartment Worth 1 Million USD!  </h2>
          <br />
          <p className=' font-[Poppins] text-black text-[18px] '>We’re giving one lucky person the opportunity to own a massive property in Dubai: a Burj Khalifa apartment worth One Million USD. The winner will also earn up to $100k per year from rent proceeds.</p>
          <br />
          <p className='font-[Poppins] text-black text-[18px]'>To win, you need to complete all the tasks in this contest and must hold at least $100 worth of the Metro token at time of the draw.</p><br/>
          <p className='font-[Poppins] text-black text-[18px]'>Tip: The more friends you invite, and the more Metro you own, the higher your chance of winning are.</p>
          <button className='font-[Poppins] bg-[#2075c2] text-white w-[190px] h-[45px] rounded-md my-5 text-[18px] font-[600]'>Before Stage 10 Ends</button>
          <div className='pt-5'>
            <ol className='list-decimal pl-8 text-[18px] font-[Poppins]'>
              <li>Enter your crypto wallet address</li>
              <li>Follow @metropoly_io on Twitter</li>
              <li>Retweet @metropoly on Twitter</li>
              <li>Tweet about Metropoly and Tag 2 friends</li>
              <li>Join Metropoly on Telegram
              </li>
              <li>Join our Discord community</li>
              <li>Visit and follow Metropoly on Instagram</li>
              <li>Share with friends for extra entries</li>
              <li>Visit Metropoly website</li>
              <li>Sign up for our newsletter</li>
            </ol>
          </div>
          <div className='py-12 space-y-3'>
            <p className='font-[Poppins] text-black text-[18px]'>
              Win a massive Burj Khalifa apartment worth 1 Million USD!  Earn up to 100'000 USD yearly for the rest of your life!*
            </p>
            <p className='font-[Poppins] text-black text-[18px]'>
              Description:  We’re giving one lucky person the opportunity to own a unique property in Dubai: a Burj Khalifa apartment worth One Million USD and receive the certificate in NFTs. In addition, the winner will earn up to $100k per year from rent proceeds paid out in stablecoins like USDT.  Available from anywhere worldwide and fully manageable on the Metropoly Marketplace by Metropoly.io LTD.
            </p>
            <p className='font-[Poppins] text-black text-[18px]'>
              Our experienced team takes care of the property, tenants, rental payments, and more. The property can also be transferred to the new winner if he wishes.
            </p>
            <div className='pt-5'>
              <ol className='list-disc pl-8 text-[18px] font-[Poppins]'>
                <li>Beautiful Burj Khalifa Penthouse worth 1 '000' 000 USD.  </li>
                <li>Up to 100'000 yearly rental income FOR LIFE! </li>
                <li>Manageable from the Metropoly Dashboard. </li>
                <li>Property Management included. </li>
                <li>The Shares can be sold on the Metropoly Marketplace.</li>

              </ol>
            </div>
            <p className='font-[Poppins] text-black text-[18px]'>
              This is your Financial Freedom Ticket. To win, you must complete all the tasks in this contest and hold at least $100 worth of the METRO token at the time of the draw.
            </p>
            <p className='font-[Poppins] text-black text-[18px]'>
              Tip: The more friends you invite and the more METRO you own, the higher your chance of winning is.
            </p>
            <p className='font-[Poppins] text-black text-[18px]'>
              Buy now $100 worth of METRO.
            </p>
            <p className='font-[Poppins] text-black text-[12px]'>

              <span className='font-[600]'> Disclaimer:</span>  These results have been achieved in the past (especially with short-term rentals).

            </p>
            <p className='font-[Poppins] text-black text-[12px] '>
              Still, the rental income varies and depends on different factors like demand, property prices, and economic situation and cannot be guaranteed at any given time.*
            </p>
            <p className='font-[Poppins] text-black text-[12px]'>
              Buy now $100 worth of METRO.
            </p>
            <p className='font-[Poppins] text-black text-[12px]'>
              <span className='font-[600]'> PLEASE NOTE:</span> All presale stages must be successfully concluded to make this giveaway happen and ensure the prize pool. The draw will be in the first week after the presale ends, and a reliable third-party legal company in Dubai, UAE, will take care of the draw to ensure that the data is correct and the players have a fair chance to win.
            </p>
          </div>
        </div>
        {/* <div className='w-[39%] '>
          <a className="e-widget no-button w-[100%]" href="https://gleam.io/Zb2di/metropoly-150000-giveaway" rel="nofollow">Metropoly $150,000 Giveaway</a>
        </div> */}
        <iframe src="https://gleam.io/Zb2di/metropoly-150000-giveaway" name="iframe_a" height="1490px" width="39%" title="Iframe Example"></iframe>
      </div>
    </div>
  )
}

export default Giveaway;