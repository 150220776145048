import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assects/icons/userlogo.png"
import { useNavigate } from 'react-router-dom';

import { useMoralis } from 'react-moralis';
import WalletDropdown from "../../components/Dropdowns/WalletDrop";

const ClaimHome = ({ handleOpen, open, handleClaim }) => {

    const navigate = useNavigate();
    const userIsConnected = useCallback(() => navigate('/mypage', { replace: true }), [navigate]);
    const { isAuthenticated } = useMoralis();

    // console.log(handleOpen, "handleOpen")


    useEffect(() => {
        if (isAuthenticated)
            userIsConnected()
    }, [isAuthenticated])

    return (

        <div className="h-[88vh] w-full flex items-center justify-center ">
            <div className="space-y-6  flex flex-col items-center justify-center h-[342px] w-[385px]">
                <img className="h-[116px] w-[116px]" src={logo} alt="" />
                <div className="space-y-10 flex justify-center items-center flex-col">
                    <h3 className="text-[47px] font-[Poppins] font-[600] text-[#2892F3]">Claim page</h3>
                    <button className="text-[#FFFFFF] w-[182px] h-[47px] bg-[#2892F3] font-Poppins font-[600] text-[18px] rounded-xl" type="button" onClick={() => handleOpen()}>Connect Wallet</button>

                </div>

            </div>
        </div>


    )
}
export default ClaimHome;