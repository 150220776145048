import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';


import Download from "../assects/images/download.svg"
import whitepaper from "../assects/images/whitepaper.png"


import whitepaper2 from "../assects/pdf/Metropoly Whitepaper.pdf";



const ReadSection = () => {
    const {t} = useTranslation();
    return (<>
        <div className=" py-16 2xl:pl-32 xl:px-32 lg:px-32 md:px-12 sm:px-6 px-6 sm:text-center xs:text-center md:text-center  " >
            <div className="block items-center 2xl:flex xl:flex lg:flex md:flex sm:block">
                <div className="space-y-5">
                    <h3 className="text-[30px] font-[700] font-[Poppins] ">{t("Read the Metropoly Whitepaper")}</h3>
                    <p className="text-[15px] cfont-[400] font-[Poppins] 2xl:pr-20 xl:pr-20 lg:pr-20">{t("Our team has extensive experience in working for both institutional real estate investment and leading blockchain projects, with billions of USD in assets under management. To gain a deeper understanding of Metropoly and our future plans, be sure to check out our comprehensive Whitepaper. It provides valuable insights into our project and its potential impact in the long term.")}</p>
                    <div className="sm:justify-center sm:flex md:justify-center md:flex xs:justify-center xs:flex lg:justify-center lg:flex">
                        <button className="bg-[#0072BA] text-white rounded-md flex items-center py-2 px-6 space-x-3">

                            <span><img src={Download} alt="" /></span><a href={whitepaper2}>{t("READ THE WHITEPAPER")}</a></button></div>
                </div>
                <div>
                    <img src={whitepaper} alt="" />
                </div>
            </div>

        </div></>
    )
}
export default ReadSection 
