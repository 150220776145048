
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';


import Stagecardicon from "../../../assects/images/stagecard.png"
import AmountImg from "../../../assects/images/amountImg.png"


import { useMoralis, useChain } from 'react-moralis';

const stageData = [
    {
        id: uuidv4(),
        title: "Stage 1",
        amount: "100,000",
        bonusAmount: "35,000",
        percentage: "35",
        days: "19th Dec. 3pm UTC"
    },
    {
        id: uuidv4(),
        title: "Stage 2",
        amount: "135,000",
        bonusAmount: "40,500",
        percentage: "30",
        days: "30th Dec. 3pm UTC"
    },
    {
        id: uuidv4(),
        title: "Stage 3",
        amount: "175,000",
        bonusAmount: "43,875",
        percentage: "25",
        days: "10th Jan. 3pm UTC"
    },
    {
        id: uuidv4(),
        title: "Stage 4",
        amount: "219,375",
        bonusAmount: "43,875",
        percentage: "20",
        days: "1021st Jan. 3pm UTC"
    },
    {
        id: uuidv4(),
        title: "Stage 5",
        amount: "263,250",
        bonusAmount: "35,000",
        percentage: "15",
        days: "1st feb. 3pm UTC"
    },
    {
        id: uuidv4(),
        title: "Stage 6",
        amount: "302,738",
        bonusAmount: "30,274",
        percentage: "10",
        days: "1011th Feb. 3pm UTC"
    },
    {
        id: uuidv4(),
        title: "Stage 7",
        amount: "333,012",
        bonusAmount: "16,650",
        percentage: "5",
        days: "122nd Feb. 3pm UTC0"
    },
    {
        id: uuidv4(),
        title: "Stage 8",
        amount: "333,012",
        bonusAmount: "16,650",
        percentage: "5",
        days: "122nd Feb. 3pm UTC0"
    },
    {
        id: uuidv4(),
        title: "Stage 9",
        amount: "333,012",
        bonusAmount: "16,650",
        percentage: "5",
        days: "122nd Feb. 3pm UTC0"
    },
]




const StageCard = ({ handleOpen, open, handleClaim }) => {


    return (
        <>
            {
                stageData.map((item) => (
                    <div className=' -z-10 bg-[#ECF4FF] h-[162px] w-[142px] px-3 py-2 rounded-xl shadow-xl'>
                        <div>
                            <div className='pb-5 flex items-center justify-center space-x-1'>
                                <h5 className='text-[10px] font-[Poppins] text-[#2892F3] font-[500]'>{item.title}</h5>
                                <img className='w-[6px] h-[6px]' src={Stagecardicon} alt="" />
                            </div>
                            <div className='flex justify-center'>
                                <h3 className='text-[17px] font-[Poppins] font-[700]'>{item.amount} <span className="text-[7px] font-[Poppins] font-[600]" >METRO</span></h3>
                            </div>
                            <div className='pt- pl-3'>
                                <h5 className='text-[9px] font-[Poppins] text-[#2892F3] font-[500]'>Bouns</h5>
                            </div>
                            <div className='bg-[white] rounded-b-2xl px-3 py-1'>
                                <div className='flex items-center space-x-2'>
                                    <img src={AmountImg} className="mb-[3px] w-[14px] h-[14px]" alt="" />
                                    <h3 className="text-[16px] font-[Poppins] text-[#00B476] font-[800]">+{item.bonusAmount}</h3>
                                </div>
                                <h5 className='text-end text-[14px] font-[Poppins] font-[400]'>+{item.percentage}%</h5>
                            </div>
                            <div className='text-center pt-2'>
                                <h5 className='text-[7px] font-[Poppins] font-[600]'>{item.days} Days</h5>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>

    )
}
export default StageCard;
