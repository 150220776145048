import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import Layout from './layout';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { MoralisProvider } from "react-moralis";
import { store } from './app/store'
import { Provider } from 'react-redux'
import '@rainbow-me/rainbowkit/styles.css';
import "./i18n";
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';

import { mainnet, bsc } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import { configureChains, createConfig, WagmiConfig } from 'wagmi';

import { trustWallet, coinbaseWallet, walletConnectWallet, metaMaskWallet } from '@rainbow-me/rainbowkit/wallets';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, bsc],
  [
    alchemyProvider({ apiKey: "yMG0m1at7WFGUSZiKD5m8ox7Ok9mp1kM" }),
    publicProvider()
  ]
);
const projectId = '015539e49e098463bb0fde7dd050b631';

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      coinbaseWallet({ projectId, chains }),
    ],
  },
]);


const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MoralisProvider serverUrl="https://osrc6bvftjcv.grandmoralis.com:2053/server" appId="BapdkIiLR6HMorA8MiVgOGCCp8dzCQyTzdchsRyP">
      <Provider store={store}>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider modalSize="compact" chains={chains}>
            <Layout />
          </RainbowKitProvider>
        </WagmiConfig>
      </Provider>,
    </MoralisProvider>
  </React.StrictMode>,
);

reportWebVitals();