import React, { useState } from "react";
import Button from "../Button/button";
import { v4 as uuidv4 } from 'uuid';
import WalletConnectIcon from '../../assects/icons/walletconnect.png';
import Meta from '../../assects/icons/meta.png';
import Walletconnect from '../../assects/icons/walletconnect1.png';
import Trust from '../../assects/icons/trust.png';
import Wallet3 from '../../assects/icons/wallet3.png';
import Secux from '../../assects/icons/secux.png';
import Ambire from '../../assects/icons/ambire.png';
import Wallet from '../../assects/icons/wallet.png';
import Apollox from '../../assects/icons/apollox.png';
import Gamestopwallet from '../../assects/icons/gamestopwallet.png';
import Zerion from '../../assects/icons/zerion.png';
import Sequence from '../../assects/icons/sequence.png';
// import Punkwallet1 from '../../assects/icons/punkwallet1.png';
import Punkwallet from '../../assects/icons/punkWallet.png';


const AllWallets = [
    {
        id: uuidv4(),
        icon: Meta,
        name: 'MetaMask'
    },
    {
        id: uuidv4(),
        icon: Walletconnect,    
        name: 'WalletConnect'
    }, {
        id: uuidv4(),
        icon: Trust,
        name: 'Trust Wallet '
    }, 

]

const initialFilters = {
    search: ""
}

const WallectConnect = () => {
    const [filters, setFilters] = useState(initialFilters)
    const [Active, setActive] = useState(1);

    const handleSearchWallet = (e) => {
        setFilters((prev) => ({
            ...prev,
            search: e.target.value
        }))
    }

    const handleActiveQR = () => {
        setActive(1);
    }
    const handleActiveDesktop = () => {
        setActive(2);
    }

    return (
        <div className=" overflow-auto overflow-hidden absolute top-27 mt-10 right-0 flex justify-start  w-[550px]  h-modal md:h-full ">
            <div className={`relative  p-4 h-[546px] w-[486px]`}>
               
                <div className={`flex justify-center items-center flex-col relative bg-white rounded-lg shadow dark:bg-gray-700  ${Active == 1 ? 'p-6' : 'p-5'}`}>

                    <div className="flex justify-center items-center bg-[#D4D5D9] w-[382px] h-[32px] rounded-lg shadow pl-2 pr-2">
                        <Button onClick={handleActiveQR} text={'QR Code'} classes={`${Active == 1 ? 'bg-white rounded-md ' : ''} w-[180px] h-[24px] active:rounded-sm text-black flex justify-center items-center font-medium text-xs font-[poppins]`} />
                        <Button onClick={handleActiveDesktop} text={'Desktop'} classes={`${Active == 2 ? 'bg-white rounded-md ' : ''} w-[180px] h-[24px] active:rounded-sm text-black flex justify-center items-center font-medium text-xs font-[poppins]`} />
                    </div>
                    {Active == 1 &&
                        <>
                            <div className="pt-8">
                                {/* <img className="w-[384px] h-[384px]" src={QR_Code} alt="qr code" /> */}
                            </div>
                            <div className="flex items-center justify-center pt-5 ">
                                <p className=" font-medium text-xs font-[poppins]">
                                    Copy to clipboard
                                </p>
                            </div>
                        </>

                    }
                    {
                        Active == 2 &&
                        <div className="w-[100%]">
                            <div className="flex items-center justify-center pt-5 border-gray-200 dark:border-gray-600">
                                <p className=" font-medium text-[12px] font-[poppins] py-2">
                                Choose your preferred wallet
                                </p>
                            </div>
                       
                            <div className="grid grid-cols-3 mt-8 flex justify-between">
                                {
                                    AllWallets.map((item, id) => (
                                        <>
                                           
                                                    <div key={id} className="flex flex-col justify-center items-center space-y-4 mt-5">
                                                        <img className=" h-[43px]" src={item.icon} alt={item.name} />
                                                        <h6 className="font-[600] text-[#6B7380] text-[14px] font-[poppins]">{item.name}</h6>
                                                    </div>
                                               
                                        </>

                                    ))
                                }
                            </div>
                          

                        </div>
                    }
                </div>
            </div>
        </div>

    )
}

export default WallectConnect;