import React, { useEffect, useCallback, useState } from "react";
import { useMoralis, useMoralisWeb3Api, useMoralisQuery } from 'react-moralis';


function Tracker() {

    const Web3Api = useMoralisWeb3Api();
    const { Moralis, isInitializing, isInitialized, initialize, isWeb3Enabled, authenticate, user
    } = useMoralis();
    const [totalInvestment, setTotalInvestment] = useState(0)
    const [totalTransfer, setTotalTransfer] = useState(0)
    const [data, setData] = useState(null)
    const [totalAverage, setTotalAverage] = useState([])
    const [scorpPart, setScorpPart] = useState([])
    const [wallStreetPart, setWallStreetPart] = useState([])
    const [deelancePart, setDeelancePart] = useState([])
    const [launchpadPart, setLaunchpadPart] = useState([])
    const [totalDate, setTotalDate] = useState([])
    const [totalCountPerDay, setTotalCountPerDay] = useState([])
    const [totalUsdtPerDay, setTotalUsdtPerDay] = useState([])
    const [totalEthPerDay, setTotalEthPerDay] = useState([])
    const [typeOfSearch, setTypeOfSearch] = useState(false)
    const [currentQuery, setCurrentQuery] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [ethPrice, setEthPrice] = useState(1);

    const scorpETH = useMoralisQuery(
        "scorpionETH",
        (query) => query.limit(5000).select("amount", "block_timestamp", "contributor").ascending("amount"),
        []
    );
    const scorpBNB = useMoralisQuery(
        "scorpionBNB",
        (query) => query.limit(5000).select("amount", "block_timestamp", "contributor").ascending("amount"),
        []
    );
    const wallStreet = useMoralisQuery(
        "WallStreet",
        (query) => query.limit(30000).select("usdEq", "block_timestamp"),
        []
    );

    const wallStreetBNB = useMoralisQuery(
        "wallStreetBNB",
        (query) => query.limit(30000).select("usdEq", "block_timestamp"),
        []
    );

    const launchpadETH = useMoralisQuery(
        "LaunchpadETH",
        (query) => query.limit(20000).select("usdEq", "block_timestamp"),
        []
    );
    const launchpadBNB = useMoralisQuery(
        "LaunchpadBNB",
        (query) => query.limit(20000).select("usdEq", "block_timestamp"),
        []
    );

    const thuglife = useMoralisQuery(
        "thuglife",
        (query) => query.limit(5000).select("usdEq", "block_timestamp"),
        []
    );
    const deelance = useMoralisQuery(
        "Deelance",
        (query) => query.limit(20000).select("amountPaid", "block_timestamp", "purchaseToken"),
        []
    );

    const deelanceBNB = useMoralisQuery(
        "deelanceBNB",
        (query) => query.limit(5000).select("amountPaid", "block_timestamp", "purchaseToken"),
        []
    );
    const chimpzee = useMoralisQuery(
        "chimpzee",
        (query) => query.limit(20000).select("amountPaid", "block_timestamp", "purchaseToken"),
        []
    );
    const ecoterra = useMoralisQuery(
        "EcoterraDepositsFinal",
        (query) => query.limit(20000).select("user_usdt_trans", "block_timestamp"),
        []
    );


    function truncateToDecimals(num, dec = 2) {
        const calcDec = Math.pow(10, dec);
        return Math.trunc(num * calcDec) / calcDec;
    }

    const getEthPrice = useCallback(async (value) => {
        const resultInUSD = await Web3Api.token.getTokenPrice({
            address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
        })
        setEthPrice(truncateToDecimals(resultInUSD.usdPrice));
        return truncateToDecimals(resultInUSD.usdPrice)
    }, [Web3Api.token, ethPrice])

    const scorpETHQuery = useCallback(async () => {
        setTypeOfSearch(false)
        setIsLoading(true)
        setCurrentQuery("SCORPION")
        let participants = []
        await scorpETH.fetch({
            onSuccess: (participantsResult) => {
                console.log(JSON.parse(JSON.stringify(participantsResult)))
                let data = JSON.parse(JSON.stringify(participantsResult))// Create an object to store the total amount for each contributor
                let contributors = {};

                data.forEach((item) => {
                    if (!contributors[item.contributor]) {
                        contributors[item.contributor] = {
                            totalAmount: 0,
                            transactionCount: 0,
                            latestTransaction: item.createdAt
                        };
                    }

                    contributors[item.contributor].totalAmount += parseInt(item.amount);
                    contributors[item.contributor].transactionCount += 1;

                    if (new Date(item.createdAt) > new Date(contributors[item.contributor].latestTransaction)) {
                        contributors[item.contributor].latestTransaction = item.createdAt;
                    }
                });

                let sortedContributors = Object.keys(contributors)
                    .map(key => ({ contributor: key, ...contributors[key] }))
                    .sort((a, b) => b.totalAmount - a.totalAmount)
                    .slice(0, 20);

                sortedContributors.forEach(contributor => {
                    console.log(`Contributor: ${contributor.contributor}, Total Amount: ${contributor.totalAmount}, Transaction Count: ${contributor.transactionCount}, Latest Transaction: ${contributor.latestTransaction}`);
                });
                participantsResult.map((index) => {
                    participants.push(index)
                })
            },
            onError: (error) => {
                console.log(error)
            },
        });

        await scorpBNB.fetch({
            onSuccess: (participantsResult) => {
                console.log(JSON.parse(JSON.stringify(participantsResult)))
                let data = JSON.parse(JSON.stringify(participantsResult))
                let contributors = {};

                data.forEach((item) => {
                    if (!contributors[item.contributor]) {
                        contributors[item.contributor] = {
                            totalAmount: 0,
                            transactionCount: 0,
                            latestTransaction: item.createdAt
                        };
                    }

                    contributors[item.contributor].totalAmount += parseInt(item.amount);
                    contributors[item.contributor].transactionCount += 1;

                    if (new Date(item.createdAt) > new Date(contributors[item.contributor].latestTransaction)) {
                        contributors[item.contributor].latestTransaction = item.createdAt;
                    }
                });

                let sortedContributors = Object.keys(contributors)
                    .map(key => ({ contributor: key, ...contributors[key] }))
                    .sort((a, b) => b.totalAmount - a.totalAmount)
                    .slice(0, 20);

                sortedContributors.forEach(contributor => {
                    console.log(`Contributor: ${contributor.contributor}, Total Amount: ${contributor.totalAmount}, Transaction Count: ${contributor.transactionCount}, Latest Transaction: ${contributor.latestTransaction}`);
                });
                participantsResult.map((index) => {
                    participants.push(index)
                })
            },
            onError: (error) => {
                console.log(error)
            },
        });
        setScorpPart(participants)
    }, [fetch]);

    const thuglifeQuery = useCallback(async () => {

        setTypeOfSearch(false)
        setIsLoading(true)
        setCurrentQuery("Thug life")
        await thuglife.fetch({
            onSuccess: (participantsResult) => {

                let test = 0;
                setTotalTransfer(participantsResult.length)
                const data = {};
                participantsResult.map((index) => {

                    const usdt = index.attributes.usdEq / 1e18;
                    const timestamp = index.attributes.block_timestamp
                    const date = new Date(timestamp);
                    date.setHours(0, 0, 0, 0)
                    const dateString = date.toISOString().split("T")[0];

                    if (!data[dateString]) {
                        data[dateString] = {
                            usdtSum: 0,
                            count: 0
                        }
                    }


                    data[dateString].usdtSum += usdt;
                    data[dateString].count++;
                    test += Number(index.attributes.usdEq);
                })

                const data1 = []
                const totPerDay = []
                const totDate = []
                const totUsdtPerDay = []
                Object.keys(data).forEach((dateString) => {
                    const average = data[dateString].usdtSum / data[dateString].count;
                    data1.push(average)
                    totPerDay.push(data[dateString].count)
                    totDate.push(dateString)
                    totUsdtPerDay.push(data[dateString].usdtSum)
                })
                setTotalAverage(data1)
                setTotalCountPerDay(totPerDay)
                setTotalDate(totDate)
                setTotalUsdtPerDay(totUsdtPerDay)
                setTotalInvestment(test / 1e18)
                setIsLoading(false)
            }
        });

    }, [fetch]);


    const wallStreetQuery = useCallback(async () => {

        setTypeOfSearch(false)
        setIsLoading(true)
        setCurrentQuery("Wall Street Memes")
        let participants = []
        await wallStreet.fetch({
            onSuccess: (participantsResult) => {
                participantsResult.map((index) => {
                    participants.push(index)
                })
            },
            onError: (error) => {
            },
        });

        await wallStreetBNB.fetch({
            onSuccess: (participantsResult) => {
                participantsResult.map((index) => {
                    participants.push(index)
                })
            },
            onError: (error) => {
            },
        });
        setWallStreetPart(participants)

    }, [fetch]);


    const launchpad = useCallback(async () => {

        setTypeOfSearch(false)
        setIsLoading(true)
        setCurrentQuery("Launchpad")
        let participants = []
        await launchpadETH.fetch({
            onSuccess: (participantsResult) => {
                participantsResult.map((index) => {
                    participants.push(index)
                })
            },
            onError: (error) => {
            },
        });

        await launchpadBNB.fetch({
            onSuccess: (participantsResult) => {
                participantsResult.map((index) => {
                    participants.push(index)
                })
            },
            onError: (error) => {
            },
        });
        setLaunchpadPart(participants)

    }, [fetch]);

    const ecoterraQuery = useCallback(() => {
        setTypeOfSearch(false)
        setCurrentQuery("Ecoterra")
        setIsLoading(true)
        ecoterra.fetch({
            onSuccess: (participantsResult) => {
                let test = 0;
                const data = {};
                setTotalTransfer(participantsResult.length)
                participantsResult.map((index) => {
                    const usdt = index.attributes.user_usdt_trans / 1000000;
                    const timestamp = index.attributes.block_timestamp
                    const date = new Date(timestamp);
                    date.setHours(0, 0, 0, 0)
                    const dateString = date.toISOString().split("T")[0];

                    if (!data[dateString]) {
                        data[dateString] = {
                            usdtSum: 0,
                            count: 0
                        }
                    }


                    data[dateString].usdtSum += usdt;
                    data[dateString].count++;
                    test += Number(index.attributes.user_usdt_trans);
                })
                const data1 = []
                const totPerDay = []
                const totDate = []
                const totUsdtPerDay = []
                Object.keys(data).forEach((dateString) => {
                    const average = data[dateString].usdtSum / data[dateString].count;
                    data1.push(average)
                    totPerDay.push(data[dateString].count)
                    totDate.push(dateString)
                    totUsdtPerDay.push(data[dateString].usdtSum)
                })
                setTotalAverage(data1)
                setTotalCountPerDay(totPerDay)
                setTotalDate(totDate)
                setTotalUsdtPerDay(totUsdtPerDay)
                setTotalInvestment(test / 1000000)
                setIsLoading(false)

            },
            onError: (error) => {
            },
        });
    }, [fetch]);

    const chimpzeeQuery = useCallback(async () => {
        let currentEthPrice = await getEthPrice()
        setIsLoading(true)
        setCurrentQuery("Chimpzee")
        setTypeOfSearch(true)
        chimpzee.fetch({
            onSuccess: (participantsResult) => {

                let test = 0;
                let test2 = 0;
                const data = {};
                setTotalTransfer(participantsResult.length)
                participantsResult.map((index) => {
                    let usdt = 0
                    let eth = 0
                    if (index.attributes.purchaseToken === "0xdac17f958d2ee523a2206206994597c13d831ec7")
                        usdt = index.attributes.amountPaid / 1e18;
                    else
                        eth = index.attributes.amountPaid / 1e18;
                    const timestamp = index.attributes.block_timestamp
                    const date = new Date(timestamp);
                    date.setHours(0, 0, 0, 0)
                    const dateString = date.toISOString().split("T")[0];

                    if (!data[dateString]) {
                        data[dateString] = {
                            usdtSum: 0,
                            ethSum: 0,
                            count: 0
                        }
                    }


                    if (index.attributes.purchaseToken === "0xdac17f958d2ee523a2206206994597c13d831ec7")
                        data[dateString].usdtSum += usdt;
                    else
                        data[dateString].ethSum += eth;
                    data[dateString].count++;

                    console.log(data[dateString])
                    test += Number(usdt);
                    test2 += Number(eth);
                })
                const data1 = []
                const totPerDay = []
                const totDate = []
                const totUsdtPerDay = []
                const toEthPerDay = []
                Object.keys(data).forEach((dateString) => {
                    const average = data[dateString].usdtSum + (data[dateString].ethSum * currentEthPrice) / data[dateString].count;
                    data1.push(average)
                    totPerDay.push(data[dateString].count)
                    totDate.push(dateString)
                    totUsdtPerDay.push(data[dateString].usdtSum)
                    toEthPerDay.push(data[dateString].ethSum)
                })
                setTotalAverage(data1)
                setTotalCountPerDay(totPerDay)
                setTotalDate(totDate)
                setTotalUsdtPerDay(totUsdtPerDay)
                setTotalEthPerDay(toEthPerDay)
                setTotalInvestment(test + (test2 * currentEthPrice))
                setIsLoading(false)
            },
            onError: (error) => {
            },
        });
    }, [fetch]);

    const deelanceQuery = useCallback(async () => {

        setTypeOfSearch(false)
        setIsLoading(true)
        setCurrentQuery("DEELANCE")
        let participants = []
        await deelance.fetch({
            onSuccess: (participantsResult) => {
                participantsResult.map((index) => {
                    participants.push(index)
                })
            },
            onError: (error) => {
            },
        });

        await deelanceBNB.fetch({
            onSuccess: (participantsResult) => {
                participantsResult.map((index) => {
                    participants.push(index)
                })
            },
            onError: (error) => {
            },
        });
        setDeelancePart(participants)
    }, [fetch]);

    useEffect(() => {
        (async () => {
            try {
                Moralis.start({
                    appId: "BapdkIiLR6HMorA8MiVgOGCCp8dzCQyTzdchsRyP",
                    serverUrl: "https://osrc6bvftjcv.grandmoralis.com:2053/server"
                })
                async function fetchData() {
                    // objectIdQuery();
                    getEthPrice()
                }
                if (!isInitialized && !isInitializing) {
                    initialize({
                        appId: "BapdkIiLR6HMorA8MiVgOGCCp8dzCQyTzdchsRyP",
                        serverUrl: "https://osrc6bvftjcv.grandmoralis.com:2053/server"
                    });

                }

                if (isInitialized && isWeb3Enabled) {
                    fetchData();
                }
                getEthPrice()
                // objectIdQuery();
            } catch (error) {
                console.log("error", error);
            }
        }
        )()
    }, [])


    useEffect(() => {

        let test = 0;
        const data = {};
        setTotalTransfer(launchpadPart.length)
        launchpadPart.map((index) => {
            const usdt = index.attributes.usdEq / 1e18;
            const timestamp = index.attributes.block_timestamp
            const date = new Date(timestamp);
            date.setHours(0, 0, 0, 0)
            const dateString = date.toISOString().split("T")[0];

            if (!data[dateString]) {
                data[dateString] = {
                    usdtSum: 0,
                    count: 0
                }
            }


            data[dateString].usdtSum += usdt;
            data[dateString].count++;
            test += Number(index.attributes.usdEq);
        })
        const data1 = []
        const totPerDay = []
        const totDate = []
        const totUsdtPerDay = []
        Object.keys(data).forEach((dateString) => {
            const average = data[dateString].usdtSum / data[dateString].count;
            data1.push(average)
            totPerDay.push(data[dateString].count)
            totDate.push(dateString)
            totUsdtPerDay.push(data[dateString].usdtSum)
        })
        setTotalAverage(data1)
        setTotalCountPerDay(totPerDay)
        setTotalDate(totDate)
        setTotalUsdtPerDay(totUsdtPerDay)
        setTotalInvestment(test / 1e18)
        setIsLoading(false)

    }, [launchpadPart])
    useEffect(() => {

        let currentEthPrice = ethPrice
        let test = 0;
        let test2 = 0;
        const data = {};
        setTotalTransfer(deelancePart.length)
        deelancePart.map((index) => {
            let usdt = 0
            let eth = 0
            if (index.attributes.purchaseToken === "0xdac17f958d2ee523a2206206994597c13d831ec7")
                usdt = index.attributes.amountPaid / 1e18;
            else
                eth = index.attributes.amountPaid / 1e18;
            const timestamp = index.attributes.block_timestamp
            const date = new Date(timestamp);
            date.setHours(0, 0, 0, 0)
            const dateString = date.toISOString().split("T")[0];

            if (!data[dateString]) {
                data[dateString] = {
                    usdtSum: 0,
                    ethSum: 0,
                    count: 0
                }
            }


            if (index.attributes.purchaseToken === "0xdac17f958d2ee523a2206206994597c13d831ec7")
                data[dateString].usdtSum += usdt;
            else
                data[dateString].ethSum += eth;
            data[dateString].count++;

            console.log(data[dateString])
            test += Number(usdt);
            test2 += Number(eth);
        })
        const data1 = []
        const totPerDay = []
        const totDate = []
        const totUsdtPerDay = []
        const toEthPerDay = []
        Object.keys(data).forEach((dateString) => {
            const average = data[dateString].usdtSum + (data[dateString].ethSum * currentEthPrice) / data[dateString].count;
            data1.push(average)
            totPerDay.push(data[dateString].count)
            totDate.push(dateString)
            totUsdtPerDay.push(data[dateString].usdtSum)
            toEthPerDay.push(data[dateString].ethSum)
        })
        setTotalAverage(data1)
        setTotalCountPerDay(totPerDay)
        setTotalDate(totDate)
        setTotalUsdtPerDay(totUsdtPerDay)
        setTotalEthPerDay(toEthPerDay)
        setTotalInvestment(test + (test2 * currentEthPrice))
        setIsLoading(false)
    }, [deelancePart])
    useEffect(() => {
        let test = 0;
        const data = {};
        setTotalTransfer(wallStreetPart.length)
        wallStreetPart.map((index) => {
            const usdt = index.attributes.usdEq / 1e18;
            const timestamp = index.attributes.block_timestamp
            const date = new Date(timestamp);
            date.setHours(0, 0, 0, 0)
            const dateString = date.toISOString().split("T")[0];

            if (!data[dateString]) {
                data[dateString] = {
                    usdtSum: 0,
                    count: 0
                }
            }


            data[dateString].usdtSum += usdt;
            data[dateString].count++;
            test += Number(index.attributes.usdEq);
        })
        const data1 = []
        const totPerDay = []
        const totDate = []
        const totUsdtPerDay = []
        Object.keys(data).forEach((dateString) => {
            const average = data[dateString].usdtSum / data[dateString].count;
            data1.push(average)
            totPerDay.push(data[dateString].count)
            totDate.push(dateString)
            totUsdtPerDay.push(data[dateString].usdtSum)
        })
        setTotalAverage(data1)
        setTotalCountPerDay(totPerDay)
        setTotalDate(totDate)
        setTotalUsdtPerDay(totUsdtPerDay)
        setTotalInvestment(test / 1e18)
        setIsLoading(false)

    }, [wallStreetPart])

    useEffect(() => {
        let test = 0;
        const data = {};
        setTotalTransfer(scorpPart.length)
        scorpPart.map((index) => {

            const usdt = index.attributes.amount / 1e6;
            const timestamp = index.attributes.block_timestamp
            const date = new Date(timestamp);
            date.setHours(0, 0, 0, 0)
            const dateString = date.toISOString().split("T")[0];

            if (!data[dateString]) {
                data[dateString] = {
                    usdtSum: 0,
                    count: 0
                }
            }

            data[dateString].usdtSum += usdt;
            data[dateString].count++;
            test += Number(index.attributes.amount);
        })
        const data1 = []
        const totPerDay = []
        const totDate = []
        const totUsdtPerDay = []
        Object.keys(data).forEach((dateString) => {
            const average = data[dateString].usdtSum / data[dateString].count;
            data1.push(average)
            totPerDay.push(data[dateString].count)
            totDate.push(dateString)
            totUsdtPerDay.push(data[dateString].usdtSum)
        })
        setTotalAverage(data1)
        setTotalCountPerDay(totPerDay)
        setTotalDate(totDate)
        setTotalUsdtPerDay(totUsdtPerDay)
        setTotalInvestment(test / 1e6)
        setIsLoading(false)
    }, [scorpPart])
    return (
        <>
            <div className="container mt-4 flex flex-col space-y-6 text-center">
                <div>
                    <button className="border-2 border-black px-5 py-2 mr-2 rounded-lg" onClick={getEthPrice}>Fetch EthPrice</button>
                    <button className="border-2 border-black px-5 py-2 mr-2 rounded-lg" onClick={ecoterraQuery}>Fetch Ecoterra</button>
                    <button className="border-2 border-black px-5 py-2 mr-2 rounded-lg" onClick={deelanceQuery}>Fetch Deelance</button>
                    <button className="border-2 border-black px-5 py-2 mr-2 rounded-lg" onClick={chimpzeeQuery}>Fetch Chimpzee</button>
                </div>
                <div>
                    <button className="border-2 border-black px-5 py-2 mr-2 rounded-lg" onClick={scorpETHQuery}>Fetch Scorpion</button>
                    <button className="border-2 border-black px-5 py-2 mr-2 rounded-lg" onClick={launchpad}>Fetch LAUNCHPAD</button>
                    <button className="border-2 border-black px-5 py-2 mr-2 rounded-lg" onClick={thuglifeQuery}>Fetch Thug Life</button>
                    <button className="border-2 border-black px-5 py-2 mr-2 rounded-lg" onClick={wallStreetQuery}>Fetch WallStreetMeme</button>
                    <button className="border-2 border-black py-2 px-5 rounded-lg" onClick={authenticate}>Connect</button>
                </div>
                <div className="flex flex-col space-y-2">
                    <div>
                        <span className="font-bold">ETH PRICE:</span> {ethPrice}
                    </div>
                    <div>
                        <span className="font-bold">Tracking:</span> {currentQuery}
                    </div>
                    <div>
                        <span className="font-bold">Total Transactions:</span> {totalTransfer}
                    </div>
                    <div>
                        <span className="font-bold">Total Investment:</span> ${totalInvestment.toFixed(2)}
                    </div>
                </div>

                <div className="flex flex-col space-y-2 items-center">
                    <div className="flex items-center font-bold w-fit">
                        <p className="w-44">DATE</p>
                        <p className="w-44">AVERAGE</p>
                        <p className="w-44">NUMBER OF TX</p>
                        <p className="w-44">TOTAL INVESTMENT</p>
                    </div>
                    {!isLoading ? totalDate ?
                        totalDate.sort((a, b) => new Date(b) - new Date(a)).map((date, index) => {
                            return <div className="flex items-center py-1 border-2 w-fit">
                                <p className="w-44">{date}</p>
                                <p className="w-44">${totalAverage[index].toFixed(2)}</p>
                                <p className="w-44">{totalCountPerDay[index]}</p>
                                <p className="w-44">${typeOfSearch ? (totalUsdtPerDay[index] + (totalEthPerDay[index] * ethPrice)).toFixed(2) : (totalUsdtPerDay[index]).toFixed(2)}</p>
                                {/*`Date: {date} - Average: ${totalAverage[index].toFixed(2)} - Number of TX: {totalCountPerDay[index]} - Total Investment: ${typeOfSearch ? (totalUsdtPerDay[index] + (totalEthPerDay[index] * ethPrice)).toFixed(2) : (totalUsdtPerDay[index]).toFixed(2)} <br />`*/}
                            </div>
                        }) : ""
                        : "Fetching ...."
                    }
                </div>
            </div>
        </>
    );
}

export default Tracker;
