import React from "react";

const Tab = ({ text, icon, handleActive, classes }) => {


    return (
        <div onClick={handleActive} className={`w-[auto]  h-[40px] cursor-pointer  flex justify-between items-center  rounded-t-[8px]   font-[400]  font-[poppins] ${classes} `}>
            <img className="pr-3 xs:pr-1 xs:h-[12px]" src={icon} alt="" />
            {text}
        </div>
    )
}

export default Tab;