import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Tick1 from "../.././../assects/images/tick1.png"


const phaseData = [
    {
        id: uuidv4(),
        title: 'Phase 1 - Set up the Legal Structure',
        labels: [
            {
                id: uuidv4(),
                label: 'Register Company',
                check: 'false',
                tick: 'true'
            },
            {
                id: uuidv4(),
                label: 'Publication of the Metropoly Marketplace UX/UI Design',
                tick: 'true'

            },
            {
                id: uuidv4(),
                label: 'Publication of the Pitch Desk'

            },
            {
                id: uuidv4(),
                label: 'Publication of the Website',

            },
            {
                id: uuidv4(),
                label: 'Release of the NFT collection Metropoly - Platinum Members Club',

            },

        ]
    },
    {
        id: uuidv4(),
        title: 'Phase 2 - Release the first beta version of Metropoly Marketplace',
        labels: [
            {
                id: uuidv4(),
                label: 'Release the first property on the Metropoly Marketplace'
            },
            {
                id: uuidv4(),
                label: 'Implementing the inbox system and Favorites'
            },
            {
                id: uuidv4(),
                label: 'Implementing the Transaction History'
            },
            {
                id: uuidv4(),
                label: 'Expanding the Marketing Strategy'
            },

        ]
    },
    {
        id: uuidv4(),
        title: 'Phase 3 - Release of the beta Version of the App',
        labels: [
            {
                id: uuidv4(),
                label: 'Release the first property on the Metropoly Marketplace'
            },
            {
                id: uuidv4(),
                label: 'Implementing the inbox system and Favorites'
            },
            {
                id: uuidv4(),
                label: 'Implementing the Transaction History'
            },
            {
                id: uuidv4(),
                label: 'Expanding the Marketing Strategy'
            },

        ]
    },
    {
        id: uuidv4(),
        title: 'Phase 4 - Implementing the Level System',
        labels: [
            {
                id: uuidv4(),
                label: 'Implementing the In-App Trading Function'
            },
            {
                id: uuidv4(),
                label: 'Implementing the Lending Syatem'
            },
            {
                id: uuidv4(),
                label: 'Enabling credit card payment'
            },
            {
                id: uuidv4(),
                label: 'Implementing more Game-Fi Elements'
            },

        ]
    },
    {
        id: uuidv4(),
        title: 'Phase 5 - Providing VIP Customer Support',
        labels: [
            {
                id: uuidv4(),
                label: 'Implementing more data for Price Prediction'
            },
            {
                id: uuidv4(),
                label: 'Release a Training Course to attract more customers'
            },
            {
                id: uuidv4(),
                label: 'Implementing an Integrated Decentralized Wallet System'
            },
            {
                id: uuidv4(),
                label: 'Implementing a Cashback Option'
            },

        ]
    },
]

const Roadmap = () => {
    // const [selectedID, setSelectedID] = useState([]);
    // const handleSelect = (id) => {
    //     if (selectedID.includes(id)) {
    //         console.log("yes")
    //         let newIDs = selectedID.filter((item) => {
    //             return item !== id
    //         })
    //         setSelectedID(newIDs)
    //     } else (
    //         setSelectedID(prev => ([...prev, id]))
    //     )
    // }
    // console.log(selectedID, "sss")

    return (
        <div className="">
            <div className="py-6 sm:py-2 xs:py-2 sm:px-4">
                <h3 className="pb-6 sm:pb-0 xs:pb-0 text-center text-[30px] font-[700] font-Poppins">
                    Roadmap</h3>
            </div>

            {
                phaseData.map((item, id) => (
                    <div key={id} className="2xl:w-[80%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[100%] xs:w-[100%]  m-auto">
                        <div className="pb-4">
                            <h2 className="text-[19px] font-[600] font-Poppins py-6">{item.title}</h2>
                        </div>
                        {
                            item.labels.map((i, id) => (
                                <div key={id} className="flex  mb-4  border-l-4 border-[#0072BA]" >
                                    <div className="2xl:w-full xl:w-full w-[1011px] h-[92px]  bg-[#0072BA] ml-3 text-sm font-medium text-white rounded-lg flex flex-start items-center 2xl:px-6 xl:px-6 lg:px-6 md:px-6 sm:px-4 xs:px-4">
                                        {
                                            i.tick === 'true' ? (
                                                <div className={`2xl:w-[48px]  xl:w-[48px] lg:w-[48px] md:w-[48px] sm:w-[40px] xs:w-[40px] 2xl:h-[45px] xl:h-[45px] lg:h-[45px] md:h-[45px] sm:h-[35px] xs:h-[35px] p-2 pointer bg-white rounded-full flex items-center justify-center`}>
                                                    <img className="w-[20px] h-[20px]" src={Tick1} alt="tick" />

                                                </div>

                                            ) : (
                                                <div className={`2xl:w-[48px]  xl:w-[48px] lg:w-[48px] md:w-[48px] sm:w-[40px] xs:w-[40px] 2xl:h-[45px] xl:h-[45px] lg:h-[45px] md:h-[45px] sm:h-[35px] xs:h-[35px] p-2 pointer bg-transparent border border-white border-2 rounded-full`}>

                                                </div>
                                            )
                                        }
                                        <label for="" class="  py-4 2xl:mx-5 xl:mx-6 lg:mx-6 md:mx-6 sm:mx-4 xs:mx-4 w-full text-[14px] font-[500] font-Poppins">{i.label}</label>
                                    </div>

                                    {/* <div onClick={() => handleSelect(i?.id)} className="w-[1011px] h-[92px] cursor-pointer bg-[#0072BA] ml-3 text-sm font-medium text-white rounded-lg flex flex-start items-center xl:px-6 lg:px-6 md:px-6 sm:px-4 xs:px-4">
                                        <div className={` cursor-pointer xl:w-[45px] lg:w-[45px] md:w-[45px] sm:w-[35px] xs:w-[35px] xl:h-[45px] lg:h-[45px] md:h-[45px] sm:h-[35px] xs:h-[35px] p-2 pointer ${selectedID.includes(i?.id) ? '  bg-white rounded-full' : '  bg-transparent border border-white border-2 rounded-full'}`}>
                                            {
                                                (selectedID.includes(i?.id)) ? (
                                                    <img src={Tick1} alt="tick" />

                                                ) : (
                                                    ''
                                                )
                                            }
                                        </div>
                                        <label for="" class=" cursor-pointer py-4 xl:mx-6 lg:mx-6 md:mx-6 sm:mx-4 xs:mx-4 w-full text-[14px] font-[500] font-Poppins">{i.label}</label>
                                    </div> */}

                                </div>
                            ))
                        }
                    </div>
                ))
            }












            {/* <div className="flex justify-between md:block sm:block xs:block space-x-10 sm:space-x-0 xs:space-x-0 sm:space-y-10 xs:space-y-10 px-[120px] md:px-[20px] sm:px-[10px] xs:px-[6px]">

                <div className="pt-10 sm:pt-2 xs:pt-2">
                    <div className=" w-[auto] h-[100%] space-y-4 lg:hidden xl:hidden 2xl:hidden sm:pb-12 md:pb-10 xs:pb-12">
                        <h3 className="text-[19px] font-[900] font-inter">Phase 1 - Set up the Legal Structure</h3>
                        <div className="px-7">
                            <ul>
                                <li className="list-disc text-[14px] font-[400] font-inter ">Publication of the Metropoly Marketplace UX/UI Design
                                </li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Publication of the Pitch Desk</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Publication of the Website</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Release of the NFT collection (Metropoly – Platinum Members Club)</li>
                            </ul>
                        </div>
                    </div>
                    <div className=" w-[420px]   h-[190px] md:hidden sm:hidden xs:hidden"></div>
                    <div className=" w-[420px] md:w-[100%] sm:w-[100%] xs:w-[100%] h-[160px] sm:h-[100%] md:h-[100%] md:pb-10 sm:pb-12 xs:h-[100%] xs:pb-12 space-y-4">
                        <h3 className="text-[19px] font-[900] font-inter">Phase 2 -  Release the first Beta Version of the Metropoly Marketplace</h3>
                        <div className="px-7">
                            <ul>
                                <li className="list-disc text-[14px] font-[400] font-inter ">Release the first property on the Metropoly Marketplace</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing the Inbox System and Favorites</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing the Transaction History</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Expanding the Marketing Strategy</li>
                            </ul>
                        </div>
                    </div>
                    <div className=" w-[420px] md:hidden sm:hidden xs:hidden h-[220px]"></div>
                    <div className=" w-[100%]  w-[auto] sm:h-[100%] sm:pb-12 xs:h-[100%] xs:pb-12 space-y-4  lg:hidden xl:hidden 2xl:hidden  h-[160px]">
                        <h3 className="text-[19px] font-[900] font-inter">Phase 3 - Release of the Beta Version of the App</h3>
                        <div className="px-7">
                            <ul>
                                <li className="list-disc text-[14px] font-[400] font-inter ">Release the first property on the Metropoly Marketplace
                                </li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing the Inbox System and Favorites
                                </li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing the Transaction History</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Expanding the Marketing Strategy</li>
                            </ul>
                        </div>
                    </div>
                    <div className=" w-[420px]  md:w-[100%] sm:w-[100%]  xs:w-[100%] h-[160px] space-y-4">
                        <h3 className="text-[19px] font-[900] font-inter">Phase 4 -  Implementing the Level System</h3>
                        <div className="px-7">
                            <ul>
                                <li className="list-disc text-[14px] font-[400] font-inter ">Implementing the In-App Trading Function</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing the Lending System</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Enabling credit card payment</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing more Game-Fi Elements</li>
                            </ul>
                        </div>
                    </div>
                    <div className="md:hidden sm:hidden xs:hidden  w-[420px] h-[200px]"></div>

                </div>
                <div className="drop-shadow-4xl bg-white rounded-full p-3 md:hidden sm:hidden xs:hidden">
                    <div className=" bg-[#0072BA] rounded-full p-4 shadow-2xl h-[164vh] w-[40px] ">
                        <div className="relative before:absolute before:bg-white before:right-[-11px] before:top-[20px] before:w-[30px] before:h-[30px] before:rounded-full after:absolute after:w-[22px] after:h-[22px] after:bg-[#0072ba] after:right-[-7px] after:top-[24px] after:rounded-full "></div>
                        <div className="relative before:absolute before:bg-white before:right-[-11px] before:top-[200px] before:w-[30px] before:h-[30px] before:rounded-full after:absolute after:w-[22px] after:h-[22px] after:bg-[#0072ba] after:right-[-7px] after:top-[204px] after:rounded-full "></div>
                        <div className="relative before:absolute before:bg-white before:right-[-11px] before:top-[395px] before:w-[30px] before:h-[30px] before:rounded-full after:absolute after:w-[22px] after:h-[22px] after:bg-[#0072ba] after:right-[-7px] after:top-[399px] after:rounded-full "></div>
                        <div className="relative before:absolute before:bg-white before:right-[-11px] before:top-[579px] before:w-[30px] before:h-[30px] before:rounded-full after:absolute after:w-[22px] after:h-[22px] after:bg-[#0072ba] after:right-[-7px] after:top-[583px] after:rounded-full "></div>
                        <div className="relative before:absolute before:bg-white before:right-[-11px] before:top-[775px] before:w-[30px] before:h-[30px] before:rounded-full after:absolute after:w-[22px] after:h-[22px] after:bg-[#0072ba] after:right-[-7px] after:top-[779px] after:rounded-full "></div>


                    </div>
                </div>
                <div className=" pt-10 md:hidden xs:hidden sm:hidden">

                    <div className=" sm:hidden xs:hidden w-[420px] h-[160px] space-y-4">
                        <h3 className="text-[19px] font-[900] font-inter">Phase 1 - Set up the Legal Structure</h3>
                        <div className="px-7">
                            <ul>
                                <li className="list-disc text-[14px] font-[400] font-inter ">Publication of the Metropoly Marketplace UX/UI Design
                                </li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Publication of the Pitch Desk</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Publication of the Website</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Release of the NFT collection (Metropoly – Platinum Members Club)</li>
                            </ul>
                        </div>
                    </div>
                    <div className=" w-[420px] h-[220px]"></div>
                    <div className=" w-[420px] h-[160px] space-y-4">
                        <h3 className="text-[19px] font-[900] font-inter">Phase 3 - Release of the Beta Version of the App</h3>
                        <div className="px-7">
                            <ul>
                                <li className="list-disc text-[14px] font-[400] font-inter ">Release the first property on the Metropoly Marketplace
                                </li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing the Inbox System and Favorites
                                </li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing the Transaction History</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Expanding the Marketing Strategy</li>
                            </ul>
                        </div>
                    </div>
                    <div className=" w-[420px] h-[220px]"></div>
                    <div className=" w-[420px] h-[160px] space-y-4">
                        <h3 className="text-[19px] font-[900] font-inter">Phase 5 - Providing VIP Customer Support</h3>
                        <div className="px-7">
                            <ul>
                                <li className="list-disc text-[14px] font-[400] font-inter ">Implementing more data for Price Predictions
                                </li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Release a Training Course to attract more customers</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing an Integrated Decentralized Wallet System</li>
                                <li className="list-disc text-[14px] font-[400] font-inter">Implementing a Cashback Option</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div> */}
        </div>
    )
}
export default Roadmap;