import React, { useEffect, useState, useCallback } from "react";
import { useMoralis, useMoralisWeb3Api, useMoralisQuery } from 'react-moralis';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Tab from '../tabs'
import Dashboardicon from '../../../assects/images/Dashboard.svg';
import Dashboardblue from '../../../assects/images/Dashboardblue.svg';
import Vestingicon from '../../../assects/svgs/safety-outlined.svg';
import Vestingblue from '../../../assects/svgs/safety-outlined_active.svg';
import Historyicon from '../../../assects/images/History.svg';
import Historyblue from '../../../assects/images/Historyblue.svg';
import Receivedicon from '../../../assects/images/Received.svg';
import Receivedblue from '../../../assects/images/Receivedblue.svg';


import Dashboard from "./tabs/Dashboard";
import Vesting from "./tabs/Vesting";
import History from "./tabs/History";
import Received from "./tabs/Received";




const TabSection = () => {
    const navigate = useNavigate()
    const [createdNft, setCreatedNft] = useState(null);
    const [handleOpenNft, setHandleOpenNft] = useState(false);
    // const [nftBox, setNftBox] = useState(3);

    const handleDashboard = () => {
        setCreatedNft(1);
    }
    const handleVesting = () => {
        setCreatedNft(2);
    }
    const handleHistory = () => {
        setCreatedNft(3);
    }
    const handleReceived = () => {
        setCreatedNft(4);
    }

    const userData = JSON.parse(useSelector((state) => state.userData.user))
    const { web3, isAuthenticated, user, Moralis } = useMoralis();
    const [currentClaimed, setCurrentClaimed] = useState(0);

    const [metroTokensClaimed, setMetroTokensClaimed] = useState(0);
    const [userTotalTokens, setUserTotalTokens] = useState(0);
    const [userTotalBonusTokens, setUserTotalBonusTokens] = useState(0);
    const [userContribution, setUserContribution] = useState(0);
    const [userContributionId, setUserContributionId] = useState([]);
    // const [userMrMetropoly, setUserMrMetropoly] = useState(managera);
    const [ownedMrMetropoly, setOwnedMrMetropoly] = useState("0");
    const [claimResults, setClaimResults] = useState("0");




    const myContribution = useMoralisQuery(
        "ContributorsLatest",
        (query) => query.equalTo("contributor", userData.ethAddress),
        [],
        {
            live: true,
        }
    );

    const myClaims = useMoralisQuery(
        "Claims",
        (query) => query.equalTo("contributor", userData.ethAddress),
        [],
        {
            live: true,
        }
    );


    const objectIdQuery = useCallback(() => {
        if (isAuthenticated) {
            myContribution.fetch({
                onSuccess: (contributionResult) => {
                    let contri = 0;
                    let contributionId = [];
                    contributionResult.map((index) => {
                        contri += Number(index.attributes.amount);
                        contributionId.push(Number(index.attributes.contributeId))
                    })
                    setUserContributionId(contributionId)
                    setUserContribution(Number(contri / 1000000))

                },
            });

            myClaims.fetch({
                onSuccess: (claimResult) => {
                    setClaimResults(claimResult)
                    claimResult.map((index) => {
                        setMetroTokensClaimed(Moralis.Units.FromWei(index.attributes.totalUserTokensClaimed))
                        setCurrentClaimed(Moralis.Units.FromWei(index.attributes.currentClaim))
                        setUserTotalTokens(Moralis.Units.FromWei(index.attributes.totalUserTokens))
                        setUserTotalBonusTokens(Moralis.Units.FromWei(index.attributes.totalUserBonusTokens))
                    })
                },
            });

        }
    }, [fetch]);

    useEffect(() => {
        if (isAuthenticated) {
            objectIdQuery();
        }
    }, [isAuthenticated])
    useEffect(() => {
        handleDashboard();
    }, []);


    return (
        <div className=" font-[poppins]" >

            <div className="mt-[50px] xs:mt-[0px] sm:mt-[0px] flex  justify-between border-b">
                <div className="flex  space-x-[88px] md:space-x-6 sm:space-x-6 xs:space-x-0   sm:px-10 xs:px-0 py-0 xs:pb-6">


                    <Tab handleActive={handleDashboard} icon={createdNft === 1 ? Dashboardblue : Dashboardicon} text={'Dashboard'} classes={` px-1 xs:px-0 xs:hidden sm:hidden ${createdNft === 1 ? ' xs:text-[14px] text-[20px] text-[#0072BA] bg-[#ffff] border-b-2 pb-5  border-blue-400 ' : '  xs:text-[14px] text-[20px] pb-5 '}`} />

                    <Tab handleActive={handleDashboard} icon={createdNft === 1 ? Dashboardblue : Dashboardicon} text={'Dashboard'} classes={` px-1 xs:px-0 xs:pr-4 ${createdNft === 1 ? ' xs:text-[16px] text-[20px] text-[#0072BA] bg-[#ffff] border-b-2 pb-5  border-blue-400 xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden ' : ' xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden xs:text-[16px] text-[20px] pb-5 '}`} />


                    <Tab handleActive={handleVesting} icon={createdNft === 2 ? Vestingblue : Vestingicon} text={'Vesting Plan'} classes={` px-1 xs:px-0 xs:hidden sm:hidden ${createdNft === 2 ? ' xs:text-[14px] text-[20px]  text-[#0072BA] bg-[#ffff] border-b-2 pb-5  border-blue-400' : ' xs:text-[14px] text-[20px] pb-5 '}`} />

                    <Tab handleActive={handleVesting} icon={createdNft === 2 ? Vestingblue : Vestingicon} text={'Vesting'} classes={` px-1 xs:px-0  xs:pr-4${createdNft === 2 ? ' xs:text-[16px] text-[20px]   text-[#0072BA] bg-[#ffff] border-b-2 pb-5  border-blue-400 xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden ' : ' xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden xs:text-[16px] text-[20px] pb-5 '}`} />

                    <Tab handleActive={handleHistory} icon={createdNft === 3 ? Historyblue : Historyicon} text={'Claim History'} classes={` px-1 xs:px-0 xs:hidden sm:hidden ${createdNft === 3 ? ' xs:text-[14px] text-[20px]  text-[#0072BA] bg-[#ffff] border-b-2 pb-5  border-blue-400' : ' xs:text-[14px] text-[20px] pb-5 '}`} />

                    <Tab handleActive={handleHistory} icon={createdNft === 3 ? Historyblue : Historyicon} text={'History'} classes={` px-1 xs:px-0 xs:pr-4 ${createdNft === 3 ? ' xs:text-[16px] text-[20px]   text-[#0072BA] bg-[#ffff] border-b-2 pb-5  border-blue-400 xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden ' : ' xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden xs:text-[16px] text-[20px] pb-5 '}`} />

                    <Tab handleActive={handleReceived} icon={createdNft === 4 ? Receivedblue : Receivedicon} text={'NFTS Received'} classes={` px-1 xs:px-0 xs:hidden sm:hidden ${createdNft === 4 ? ' xs:text-[14px] text-[20px]  text-[#0072BA] bg-[#ffff] border-b-2 pb-5  border-blue-400' : ' xs:text-[14px] text-[20px] pb-5'}`} />

                    <Tab handleActive={handleReceived} icon={createdNft === 4 ? Receivedblue : Receivedicon} text={'NFTS'} classes={` px-1 xs:px-0 xs:pr-4 ${createdNft === 4 ? ' xs:text-[16px] text-[20px]   text-[#0072BA] bg-[#ffff] border-b-2 pb-5  border-blue-400 xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden ' : ' xs:auto sm:auto md:hidden lg:hidden xl:hidden 2xl:hidden xs:text-[16px] text-[20px] pb-5 '}`} />





                </div>
            </div>
            <div className="px-11 xs:px-0 sm:px-0" >
                {createdNft === 1 &&
                    <div >
                        <Dashboard
                            currentClaimed={currentClaimed}
                            metroTokensClaimed={metroTokensClaimed}
                            userTotalTokens={userTotalTokens}
                            userTotalBonusTokens={userTotalBonusTokens}
                            userContribution={userContribution}
                            userContributionId={userContributionId}
                            ownedMrMetropoly={ownedMrMetropoly}
                        />
                    </div>
                }

                {createdNft === 2 &&
                    <div className="">
                        <Vesting />

                    </div>

                }
                {createdNft === 3 &&
                    <div className="">
                        <History claimResults={claimResults} />

                    </div>

                }
                {createdNft === 4 &&
                    <div className="">
                        <Received />

                    </div>

                }





            </div>

        </div>

    )
}
export default TabSection;