// import React, { useState } from "react";
// import { v4 as uuidv4 } from 'uuid';





// const Vesting = ({ item }) => {


//     return (
//         <>
//             <div>
//                 asdljivb
//             </div>
//             {/* <div className=" 2xl:w-[70%] xl:w-[80%] lg:w-[80%] mx-auto">
//                 <div className="">
//                     <div className="text-center sm:pt-2 xs:pt-2 pb-10">
//                         <h3 className="text-[30px] sm:text-[24px] xs:text-[24px] font-semibold font-Poppins text-[#000000]">Metro Token</h3>
//                     </div>
//                     <div className="border-t border-l border-r ">

//                         {

//                             tableData.map((item, id) => (
//                                 <div className="border-b flex justify-between py-3 px-5 xs:px-3">
//                                     <h4 className="text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000]">{item.title}</h4>
//                                     <h4 className="text-[16px]  sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000]">{item.value}</h4>
//                                 </div>

//                             )
//                             )

//                         }
//                     </div>
//                 </div>
//                 <div className="">
//                     <div className="text-center py-10">
//                         <h3 className="text-[30px] sm:text-[24px] xs:text-[24px] font-semibold font-Poppins text-[#000000]">Presale Structure</h3>
//                     </div>
//                     <div className="border-t border-l border-r  ">

//                         {

//                             PresaleData.map((item, id) => (
//                                 <div className="border-b flex justify-between py-3 px-5 xs:px-3">
//                                     <h4 className="text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000]">{item.title}</h4>
//                                     <h4 className="text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins text-[#000000] xs:text-right">{item.value}</h4>
//                                 </div>

//                             )
//                             )

//                         }
//                     </div>
//                 </div>

//                 <div className="">
//                     <div className="text-center py-10">
//                         <h3 className="text-[30px] sm:text-[24px] xs:text-[24px] font-semibold font-Poppins text-[#000000]">Bonus System in 10 Stages, Max: 50% Bonus</h3>
//                     </div>
//                     <div className="border-t border-l border-r  ">
//                         <div className="flex justify-between xs:items-center xs:text-center py-4 px-5 xs:px-0 xs:pr-3 border-b bg-[#ECF4FF]">
//                             <h3 className="w-[44%] xs:w-[40%] text-[18px] sm:text-[14px] xs:text-[14px] font-semibold font-Poppins text-[#000000]">Presale Stages</h3>
//                             <h3 className="w-[20%] text-[18px] sm:text-[14px] xs:text-[14px] font-semibold font-Poppins text-[#000000]">Bonus %</h3>
//                             <h3 className="w-[30%] text-end text-[18px] sm:text-[14px] xs:text-[14px] font-semibold font-Poppins text-[#000000]">Days</h3>
//                         </div>

//                         {

//                             BonusData.map((item, id) => (
//                                 <div className="border-b flex justify-between py-3 px-5 xs:px-3 xs:test-center">
//                                     <h4 className="pl-4 xs:pl-0 w-[44%] text-[16px] font-[400] font-Poppins text-[#000000] sm:text-[14px] xs:text-[14px]">{item.title}</h4>
//                                     <h4 className="pl-4 xs:pl-0 w-[21%] text-[16px] font-[400] font-Poppins text-[#000000] sm:text-[14px] xs:text-[14px]">{item.bonusValue}</h4>
//                                     <h4 className="pl-4 xs:pl-0 w-[30%] text-end text-[16px] font-[400] font-Poppins text-[#000000] sm:text-[14px] xs:text-[14px]">{item.value}</h4>
//                                 </div>

//                             )
//                             )

//                         }
//                     </div>
//                 </div >

//             </div> */}

//         </>
//     )
// }
// export default Vesting;
import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';



const tableData = [
    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '1st claim',
            },
            {
                id: uuidv4(),
                title: '19th Dec. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: '24 hours after Launch',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },

        ]
    },
    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '2dn claim',
            },
            {
                id: uuidv4(),
                title: '30th Dec. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '10,000',
            },

        ]
    },
    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '3rd claim',
            },
            {
                id: uuidv4(),
                title: '10th Jan. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '15,000',
            },

        ]
    },
    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '4th claim',
            },
            {
                id: uuidv4(),
                title: '21st Jan. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '20,000',
            },

        ]
    },
    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '5th claim',
            },
            {
                id: uuidv4(),
                title: '1st Feb. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '25,000',
            },

        ]
    },
    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '6th claim',
            },
            {
                id: uuidv4(),
                title: '11th Feb. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '30,000',
            },

        ]
    },
    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '7th claim',
            },
            {
                id: uuidv4(),
                title: '22nd Feb. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '35,000',
            },

        ]
    },
    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '8th claim',
            },
            {
                id: uuidv4(),
                title: '5th March. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '40,000',
            },

        ]
    },

    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '9th claim',
            },
            {
                id: uuidv4(),
                title: '16th March. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '45,000',
            },

        ]
    },

    {
        id: uuidv4(),
        tablerow: [

            {
                id: uuidv4(),
                title: '10th claim',
            },
            {
                id: uuidv4(),
                title: '27th March. 3pm UTC',
            },
            {
                id: uuidv4(),
                title: '10%',
            },
            {
                id: uuidv4(),
                title: 'after 10 days',
            },
            {
                id: uuidv4(),
                title: '1,000,000 METRO',
            },
            {
                id: uuidv4(),
                title: '5,000',
            },
            {
                id: uuidv4(),
                title: '50,000',
            },

        ]
    },




]


const Vesting = ({ item }) => {


    return (
        <>
            <div className="py-[40px] xs:py-[10px]">
                <div className="overflow-x-auto relative rounded-t-lg">
                    <table className="  w-[100%] sm:w-[800px] xs:w-[800px] ">
                        <thead className="h-[54px] uppercase bg-[#2892F3] ">
                            <tr className="space-y-10 text-left">
                                <th className="py-3 px-6 text-[16px] font-[600] text-white font-[Poppins] w-[200px]">
                                    Vesting Rounds
                                </th>
                                <th className="py-3 px-6 text-[16px] font-[600] text-white font-[Poppins]">
                                    Date
                                </th>
                                <th className="py-3 px-6 text-[16px] font-[600] text-white font-[Poppins]">
                                    Percentage
                                </th>
                                <th className="py-3 px-6 text-[16px] font-[600] text-white font-[Poppins]">
                                    Unlock Period
                                </th>
                                <th className="py-3 px-6 text-[16px] font-[600] text-white font-[Poppins]">
                                    Tokens
                                </th>
                                <th className="py-3 px-6 text-[16px] font-[600] text-white font-[Poppins]" >
                                    Bonus
                                </th>
                                <th className="py-3 px-6 text-[16px] font-[600] text-white font-[Poppins]">
                                    Total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.map((item) => (

                                    <tr className="bg-white border-b space-x-10 ">
                                        {
                                            item.tablerow.map((i) => (

                                                <td className="py-4 px-6 text-[16px] font-[400] font-[Poppins] ">
                                                    {i.title}
                                                </td>
                                            ))
                                        }

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>


        </>
    )
}
export default Vesting;