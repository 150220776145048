import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {}
}

const user = {
  user: {},
  value: {}
}

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    getUserData: (state) => {
      state.value = state
    },
    updateUserData: (state, action) => {
      state.user = action.payload
    },
    updateUserContribution: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { getUserData , updateUserData, updateUserContribution } = userDataSlice.actions

export default userDataSlice.reducer