import React from "react";


import Chain from "../assects/images/company/chain.png"
import tenset from "../assects/images/company/tenset.png"
import dtc from "../assects/images/company/dtc.png"
import transak from "../assects/images/company/transak.png"

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "./slider.css"

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";

const PartnersSection = ({ teamSection }) => {
    const {t} = useTranslation();
    return (
        <div className="bg-[#ffffff]" >

            <div className="sm:px-10 xs:px-6 py-[80px] 2xl:w-[75%] xl:w-[85%] lg:[80%] md:[80%] m-auto ">

                <div className=" ">
                    <h3 className=" text-center text-[30px]  font-[700] text[#F4F4F4] font-[Poppins]">{t("Partnerships")}</h3>
                </div>
                <div className="grid grid-cols-4 gap-8 justify-between items-center sm:px-10 xs:px-6 md:space-y-14 sm:space-y-14 xs:space-y-12 md:block sm:block xs:block pt-[36px]">

                </div>

                <div className=" w-[100%]">
                    <div style={{ height: "20vh", position: "relative" }}>
                        <Swiper
                            slidesPerView={4}
                            breakpoints={{
                                360: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                500: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                750: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                1124: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },
                            }}

                            spaceBetween={10}
                            className="sliderWidth "
                            navigation={{
                                nextEl: ".image-swiper-button-next",
                                prevEl: ".image-swiper-button-prev",
                                disabledClass: "swiper-button-disabled"
                            }}
                            modules={[Navigation]}
                        >

                            <SwiperSlide className="wrap"  >
                                <div className="bg-white px-4 pt-5 space-y-4 rounded-lg swiper-center">
                                    <img src={Chain} alt="" />

                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="wrap"  >
                                <div className="bg-white px-4 pt-5 space-y-4 rounded-lg swiper-center">
                                    <img src={dtc} alt="" />

                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="wrap"  >
                                <div className="bg-white px-4 pt-5 space-y-4 rounded-lg swiper-center">
                                    <img src={tenset} alt="" />

                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="wrap"  >
                                <div className="bg-white px-4 pt-5 space-y-4 rounded-lg swiper-center">
                                    <img src={transak} alt="" />

                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className="swiper-button2 image-swiper-button-next">
                            <IoIosArrowForward />
                        </div>
                        <div className="swiper-button2 image-swiper-button-prev">
                            <IoIosArrowBack />
                        </div>
                    </div>

                </div>


            </div>

        </div>
    )
}
export default PartnersSection 
