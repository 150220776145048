import React, { useState, useEffect } from "react";
import Button from "../Button/button";
import MetaMaskIcon from '../../assects/icons/metamask.png';
import WalletConnectIcon from '../../assects/icons/walletconnect.png';
import MyWallet from "./MyWallet";
import { useMoralis } from 'react-moralis';
import { isMobile, isDesktop } from 'react-device-detect';
import detectEthereumProvider from '@metamask/detect-provider'
import { updateUserData } from '../../app/feature/userData/userDataSlice'
import { useDispatch } from 'react-redux'
import { notifyInfo } from "../../utils/notify"

const WalletDropdown = ({ open, handleClose, handleOpen, handleClaim }) => {

  const { Moralis, user, isAuthenticated, authenticate, isInitialized, isInitializing, isWeb3EnableLoading, initialize, enableWeb3, isWeb3Enabled } = useMoralis();
  const [connecting, setConnecting] = useState(false);
  const dispatch = useDispatch()
  const toastId = React.useRef(1);
  const [flag, setFlag] = useState(false);

  const handleOpenMetaMask = async () => {
    if (!connecting) {
      setConnecting(true)
      await detectEthereumProvider().then(async (provider) => {
        if (isMobile) {
          if (provider) {
            await authenticate({ signingMessage: "Welcome to Metropoly!" }).then(() => {
              setConnecting(false)
            });
          } else
            window.open("https://metamask.app.link/dapp/presale.metropoly.io/")
          // window.open("https://metamask.app.link/dapp/staging-metropoly.netlify.app")

        }
        else {
          await authenticate({ signingMessage: "Welcome to Metropoly!" }).then(() => {
            setConnecting(false)
          });
        }
      });
    }
    else {
      notifyInfo("Please wait.", toastId)
    }
  }

  const handleWalletConnect = async () => {
    if (!connecting) {
      setConnecting(true)
      if (isDesktop) {
        await authenticate({
          signingMessage: "Welcome to Metropoly!",
          provider: "walletconnect"
        })
      }
      else {
        await detectEthereumProvider().then(async (provider) => {
          if (provider) {
            await authenticate({
              signingMessage: "Welcome to Metropoly!"
            })
          }
          else {
            // notifyInfo("Please allow pop up to open Trust Wallet.")
            // window.open("trust://open_url?coin_id=60&url=https://staging-metropoly.netlify.app/?utm_source=Trust_iOS_Browser#")
            // window.open("trust://open_url?coin_id=60&url=https://presale.metropoly.io/?utm_source=Trust_iOS_Browser#")

            await authenticate({
              signingMessage: "Welcome to Metropoly!",
              provider: "walletconnect"
            })
          }
        })
      }
    }
    else {
      notifyInfo("Please wait.", toastId)
    }
  }


  useEffect(() => {
    if (isAuthenticated) {
      dispatch(updateUserData(JSON.stringify(user.attributes)))
    }
  }, [isAuthenticated])


  useEffect(() => {
    (async () => {
      try {
        if (!flag) {
          if (!isInitialized && !isInitializing) {
            await initialize({
              appId: "BapdkIiLR6HMorA8MiVgOGCCp8dzCQyTzdchsRyP",
              serverUrl: "https://osrc6bvftjcv.grandmoralis.com:2053/server"
            });
          }
          if (isInitialized && !isWeb3Enabled && !isWeb3EnableLoading) {
            await enableWeb3();
            setFlag(true)
          }
        }

      } catch (error) {
        setFlag(true)
        notifyInfo("Please navigate using a DApp browser.")
        console.log("error", error);
      }
    }
    )()
  }, [])
  return (
    <div className=''>
      <div className="z-10 absolute right-3 sm:-right-0 xs:-right-0 top-[60px]  sm:w-full xs:w-full w-[100%] bg-[transparent] h-[90vh]  block" onClick={handleClose} >
        {
          !isAuthenticated ? (

            <div className="sm:px-3 xs:px-3 ml-auto w-[424px] bg-[#F7F9FA] rounded divide-y divide-gray-100 shadow ">
              <div>
                <div className=" py-6 border-b border-b-2">
                  <h3 className="px-6 sm:px-0 xs:px-0 text-poppins font-semibold">My Wallet</h3>

                </div>
                <div className="p-6 sm:p-0 xs:p-0">
                  <div className="">
                    <p className="pb-6">If you don’t have a wallet yet, you can select a provider and create a new one.</p>
                    <div className="w-full pb-6">
                      <ul className="bg-white border border-grey-500 rounded-lg">
                        <li className="border-b" onClick={() => handleOpenMetaMask()}>
                          <a href="#" className="flex justify-between items-center w-full block hover:bg-white py-3 px-2">
                            <div className="w-1/3 flex items-center">
                              <img className="h-[28px] w-[28px]" src={MetaMaskIcon} alt="meta mask" />
                              <h3 className="pl-3 text-sm font-semibold">MetaMask</h3>
                            </div>
                            <div className="w-1/3">
                              <Button text="Popular" classes="bg-[#2892F3] rounded-lg  h-[32px] w-[88px] font-semibold text-sm font-[poppins] text-white text-center" />

                            </div>
                          </a>
                        </li>
                        <li className="border-b" onClick={() => handleWalletConnect()}>
                          <a href="#" className="flex justify-between items-center w-full block hover:bg-white py-3 px-2">
                            <div className="w-1/3 flex items-center py-2 ">
                              <img className="h-[14.82px] w-[27px]" src={WalletConnectIcon} alt="meta mask" />
                              <h3 className="pl-3 text-sm font-semibold">WalletConnect</h3>
                            </div>
                          </a>
                        </li>
                        <li className="border-b" onClick={() => handleOpenMetaMask()}>
                          <a href="#" className="flex justify-center items-center w-full block hover:bg-white py-3 px-2">
                            <div className="flex items-center py-2">
                              <h3 className="pl-3 text-sm font-semibold">Create a new Wallet</h3>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          ) : (
            <>

              <MyWallet handleClaim={handleClaim} />

            </>
          )
        }



      </div>

    </div>


  )
}

export default WalletDropdown;
