import React, { useState, useEffect } from "react";
import { useMoralis, useMoralisWeb3Api, useMoralisQuery } from 'react-moralis';
import Select, { components } from "react-select";
import Tokenomics from "./Tokenomics";

import Button from "../../../components/Button/button";
import { v4 as uuidv4 } from 'uuid';
import PackagesCard from "../../PackagesCard";
import whitepaper from "../../../assects/pdf/Metropoly Whitepaper.pdf";
import pitchDeck from "../../../assects/pdf/Metropoly Pitch Deck.pdf";

import Increase from "../../../assects/images/beta.png"
import Worldwide from "../../../assects/images/worldwide1.png"
import Dubai from "../../../assects/images/dubai1.png"
import Ellipse from "../../../assects/images/Ellipse-60.png"
import Revolutionizing from "../../../assects/images/revolutionizing.png"
import Step1 from "../../../assects/images/step-1.png"
import Step2 from "../../../assects/images/step-2.png"
import Step3 from "../../../assects/images/step33.png"
import Propertyimg from "../../../assects/images/propertyimg.png"
import Market from "../../../assects/images/market.png"
import Rental from "../../../assects/images/rental.png"
import Portfolio from "../../../assects/images/portfolio.png"
import Cashflow from "../../../assects/images/Cashflow.png"
import Appreciation from "../../../assects/images/Value Appreciation.png"
import Passive from "../../../assects/images/passiveincome.png"
import Stability from "../../../assects/images/High Stability.png"
import File from "../../../assects/images/file.svg"
import Student from "../../../assects/images/studentpng.png"
import Broker from "../../../assects/images/brokerpng.png"
import Manager from "../../../assects/images/managerpng.png"
import Boss from "../../../assects/images/bosspng.png"
import Tycoon from "../../../assects/images/tycoonpng.png"
import Did from "../../../assects/images/did.png";
import Tick from "../.././../assects/images/tick.png"
import Cross from "../../../assects/images/cross.png"
import Tick1 from "../.././../assects/images/tick1.png"
import Remove from "../.././../assects/images/remove1.png"
import Home from "../../../assects/images/home.png"
import Metropoly from "../../../assects/images/metropoly.png"
import Mpoly from "../../../assects/logo/mpoly.png"
import whitepaperbg from "../.././../assects/images/metropolywhitepapermock-up-v3.png"
import { useTranslation } from "react-i18next";



const BonusData = [
    {
        id: uuidv4(),
        title: 'Get started with as little as $100',
        tickIcon: Tick1,
        crossIcon: Remove,
    },
    {
        id: uuidv4(),
        title: 'Invest in properties worldwide',
        tickIcon: Tick1,
        crossIcon: Remove,
    },
    {
        id: uuidv4(),
        title: 'Hedge Against Inflation',
        tickIcon: Tick1,
        // crossIcon: Tick1,
    },
    {
        id: uuidv4(),
        title: 'No Hidden Fees',
        tickIcon: Tick1,
        crossIcon: Remove,
    },
    {
        id: uuidv4(),
        title: 'No Paperwork',
        tickIcon: Tick1,
        crossIcon: Remove,
    },
    {
        id: uuidv4(),
        title: 'No need for property management',
        tickIcon: Tick1,
        crossIcon: Remove,
    },
    {
        id: uuidv4(),
        title: 'Generate monthly rental income',
        tickIcon: Tick1,
        // crossIcon: Tick1,

    },
    {
        id: uuidv4(),
        title: 'Long term price appreciation',
        tickIcon: Tick1,
        // crossIcon: Tick1,
    },
    {
        id: uuidv4(),
        title: 'No need to find tenants',
        tickIcon: Tick1,
        crossIcon: Remove,
    },
    {
        id: uuidv4(),
        title: 'Time to buy & Sell a property on average',
        time: '20 Sec',
        date: "60 Days",
    },
    // {
    //     id: uuidv4(),
    //     title: 'Long term price appreciation',
    //     tickIcon: Tick1,
    //     crossIcon: Tick1,
    // },
    // {
    //     id: uuidv4(),
    //     title: 'Borrow against the property',
    //     tickIcon: Tick1,
    //     crossIcon: Tick1,
    // },
]

const PackagesCardData = [
    {
        id: uuidv4(),
        image: Student,
        title: "Student Package",
        minAmount: 100,
        amount: "$100 - $999",
        subtitle: "Receive",
        packageCard: [
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Early Access to Metropoly Beta',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: '1x Metropoly Student NFT',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Earn METRO Tokens',
            },
            {
                id: uuidv4(),
                icon: Cross,
                li: 'No Real Estate NFTs',
            },
            {
                id: uuidv4(),
                icon: Cross,
                li: 'No Cashback',
            }
        ],
    },
    {
        id: uuidv4(),
        title: "Broker Package",
        subtitle: "Receive",
        minAmount: 1000,
        amount: "$1,000 - $9,999",
        image: Broker,
        packageCard: [
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Early Access to Metropoly Beta',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: '1x Metropoly Broker NFT',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Earn METRO Tokens',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Real Estate NFT worth USD 100',
            },
            {
                id: uuidv4(),
                icon: Cross,
                li: 'No Cashback',
            }
        ],
    },
    {
        id: uuidv4(),
        title: "Manager Package",
        subtitle: "Receive",
        minAmount: 10000,
        amount: "$10,000 - $24,999",
        image: Manager,
        packageCard: [
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Early Access to Metropoly Beta',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: '1x Metropoly Manager NFT',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Earn METRO Tokens',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Real Estate NFTs worth USD 1000',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: '1% Cashback on Metropoly ',
            }
        ],
    },
    {
        id: uuidv4(),
        title: "Boss Package",
        subtitle: "Receive",
        minAmount: 25000,
        amount: "$25,000 - $99,999",
        image: Boss,
        packageCard: [
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Early Access to Metropoly Beta',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: '1x Metropoly Boss NFT',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Earn METRO Tokens',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Real Estate NFTs worth USD 2,500',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: '2.5% Cashback on Metropoly ',
            }
        ],
    },
    {
        id: uuidv4(),
        title: "Tycoon Package",
        subtitle: "Receive",
        minAmount: 100000,
        amount: "100,000+",
        image: Tycoon,
        packageCard: [
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Early Access to Metropoly Beta',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: '1x Metropoly Tycoon NFT',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Earn METRO Tokens',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: 'Real Estate NFTs worth USD 10,000',
            },
            {
                id: uuidv4(),
                icon: Tick,
                li: '5% Cashback on Metropoly ',
            }
        ],
    },

]
const Campaign = ({ handleRange }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className="pt-12 pb-10 sm:pt-5 sm:pb-10 xs:pt-5 xs:pb-10 md:pb-10 bg-gray-50">
                <span style={{ top: "-100px", position: "relative" }} id="metropoly" ></span>
                <div className="pl-20 md:px-20  sm:px-10  xs:px-6 ">
                    <h3 className=" text-[30px] font-[700] text-center">{t("Revolutionizing the Real Estate Industry")}</h3>
                    <div className="flex justify-center items-center w-[559px] sm:w-[100%] xs:w-[100%] m-auto ">
                        <img src={Revolutionizing} alt="" />
                    </div>
                    <p className=" font-Poppins text-center leading-[27px] text-[#5b5b5b] sm:leading-[21px] xs:leading-[21px] text-[18px]  sm:text-[14px] xs:text-[14px] font-[400] 2xl:px-56 xl:px-56 lg:px-56 md:px-10 sm:px-0  xs:px-0 ">{t("Did you know that 98% of real estate transactions are still done offline, taking an average of 60 days to complete? Metropoly is leading the charge to digitize the multi-trillion-dollar real estate industry. With our platform, you can buy and sell fractional ownership of income-generating properties as NFTs in seconds, just like trading stocks. Our mission is to make real estate investment accessible to everyone, regardless of location or credit score. Join us in revolutionizing the way we invest in real estate.")}
                    </p>

                </div>

            </div>
            <div className="flex justify-between md:block sm:block xs:block ">
                <div className="w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] mb-12">
                    <div className="flex items-center justify-center py-12 lg:hidden xl:hidden 2xl:hidden">
                        <hr className="w-[60px] h-[3px] bg-gray-500" />

                    </div>
                    <div className="2xl:pt-12 xl:pt-12 lg:pt-12 pl-20 md:px-20  sm:px-10  xs:px-6    md:py-0 sm:py-0 xs:py-0 sm:px-10 xs:px-6">


                        <div className=" pr-[27px] sm:pr-0 xs:pr-0  space-y-11">
                            <div>
                                <h3 className=" text-[30px] font-[700] font-[Poppins] text-center">{t("Cashflow and truly Passive Income")}</h3>
                            </div>
                            <div className="px-20 md:px-0 sm:px-0 xs:px-0 flex justify-between items-center ">
                                <img className="2xl:w-[26%] xl:w-[26%] lg:w-[30%] md:w-[25%] sm:w-[25%] xs:w-[25%]" src={Propertyimg} alt="" />
                                <img className="rounded-xl drop-shadow-lg 2xl:w-[40%] xl:w-[40%] lg:w-[40%] md:w-[40%] sm:w-[40%] xs:w-[35%]" src={Market} alt="" />
                                <img className="2xl:w-[26%] xl:w-[26%] lg:w-[25%] md:w-[25%] sm:w-[25%] xs:w-[25%]" src={Rental} alt="" />
                            </div>
                            <div>
                                {/* 2xl:px-28 xl:px-16 lg:px-14 md:px-10 sm:px-0 xs:px-0 */}
                                <p className=" text-[16px] sm:text-[14px]  md:text-center xs:text-center lg:text-center xs:text-[14px] font-[400] text-[#5B5B5B] font-Poppins  2xl:px-56 xl:px-56 lg:px-56 md:px-10 sm:px-0  xs:px-0 ">{t("No matter the location, time, or market conditions – people will always need a roof over their heads. This makes rental properties one of the most important asset classes to build long-term wealth and generate cashflow and passive income.")}


                                </p>
                            </div>
                        </div>
                        <div className="flex items-center justify-center py-12 ">
                            <hr className="w-[60px] h-[3px] bg-gray-500" />

                        </div>

                        <div className="  space-y-2 pr-[27px] sm:pr-0 xs:pr-0">
                            <div>
                                <h3 className=" text-[30px] font-[700] font-[Poppins] text-center">{t("It’s time to diversify your crypto portfolio")}</h3>
                            </div>
                            <div className="flex justify-center items-center w-[645px] sm:w-[100%] xs:w-[100%] m-auto">
                                <img src={Portfolio} alt="" />
                            </div>
                            <div>
                                <p className="md:text-center xs:text-center lg:text-center  text-[18px] sm:text-[14px] xs:text-[14px] font-[400] text-[#5B5B5B] font-Poppins 2xl:px-56 xl:px-56 lg:px-56 md:px-10 sm:px-0  xs:px-0 ">{t("During the current bear market, most of us have suffered significant losses. In the process, we realized more than ever that it is crucial to have a diversified portfolio to protect our wealth.")}

                                    <br />
                                    <br />
                                    {t("Crypto is highly volatile, and keeping fiat currencies in a bank account is not recommended due to inflation. So, Investing in something easy to understand and tangible like real estate just makes sense.")}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center justify-center py-12 ">
                            <hr className="w-[60px] h-[3px] bg-gray-500" />

                        </div>

                        <div className=" space-y-6 pr-[27px] sm:pr-0 xs:pr-0 ">
                            <div className="px-12">
                                <h3 className="  text-[30px] font-[700] font-[Poppins] text-center">{t("It’s Never Been Easier to Invest in Real Estate")}</h3>
                            </div>
                            <div className="space-y-4 s">
                                <p className="md:text-center lg:text-center xs:text-center text-[18px] sm:text-[14px] xs:text-[14px] font-[400] text-[#5B5B5B] font-Poppins 2xl:px-56 xl:px-56 lg:px-56 md:px-10 sm:px-0  xs:px-0 ">{t("Real estate is by far the best long-term investment according to US citizens.* However, the international real estate market is inaccessible, illiquid, and complicated for most people. Fortunately, that’s where we come in!")}

                                </p>
                                <p className="md:text-center xs:text-center text-[18px] sm:text-[14px] xs:text-[14px] font-[400] text-[#5B5B5B] font-Poppins 2xl:px-56 xl:px-56 lg:px-56 md:px-10 sm:px-0  xs:px-0 ">
                                    {t("With Metropoly’s blockchain based solution you can invest in income-generating properties within seconds from anywhere in the world with as little as $100 and still get all the advantages of being a real estate investor.")}

                                </p>
                            </div>
                            <div className="pt-6 ">

                                <div className="2xl:px-56 xl:px-56 lg:px-56 md:px-10 sm:px-0  xs:px-0 ">
                                    <table className="w-full">
                                        <thead className="text-xs text-gray-700 capatilize">
                                            <tr className="border-b 2xl:text-[16px] xl:text-[16px] lg:text-[16px] sm:[12px] xs:[12px] font-Poppins font-[500] text-[#000000] my-auto">
                                                <th className="2xl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-1.5 xs:py-1.5 2xl:px-4 xl:px-4 lg:px-4 md:px-6 sm:px-3 xs:px-3 bg-[#F5F5F5] text-left w-[55%]  sm:w-[50%] xs:w-[50%]">
                                                    {t("Features")}
                                                </th>
                                                <th className="2xl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-1.5 xs:py-1.5 2xl:px-4 xl:px-4 lg:px-4 md:px-4 sm:px-3 xs:px-3 space-x-1.5  w-[202px]  sm:w-[25%] xs:w-[25%]">
                                                    <img src={Mpoly} className="xs:hidden w-[140px]" alt="metropoly" style={{ margin: '0 auto' }} />
                                                    <img className="2xl:hidden xl:hidden lg:hidden md:hidden w-[31px] h-[31px]" src={Home} alt="metropoly" style={{ margin: '0 auto' }} />
                                                    {/* <img className="mx-auto my-auto w-[20px] h-[20px]" src={Home} alt="" />
                                                <img className="mx-auto my-auto w-[79px] h-[10px]  xs:hidden" src={Metropoly} alt="" /> */}
                                                </th>
                                                <th className="2xl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-1.5 xs:py-1.5 2xl:px-2 xl:px-2 lg:px-2 md:px-2 sm:px-3 xs:px-3   bg-[#F5F5F5] w-[202px]  sm:w-[25%] xs:w-[25%]">
                                                    {t("Traditional real estate investment")}
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                BonusData.map((item, id) => (
                                                    <tr className="bg-white border-b  my-auto" key={id}>
                                                        <th className="2xl:py-2 xl:py-2 lg:py-2 md:py-2 sm:py-1.5 xs:py-1.5 2xl:px-4 xl:px-4 lg:px-4 md:px-6 sm:px-3 xs:px-3 font-normal 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[12px] xs:text-[12px] text-gray-900  bg-[#F5F5F5] text-left">
                                                            {t(item.title)}
                                                        </th>

                                                        <th className="2xl:py-2 xl:py-2 lg:py-2 md:py-2 sm:py-1.5 xs:py-1.5 2xl:px-6 xl:px-6 lg:px-6 md:px-6 sm:px-3 xs:px-3 mx-auto">
                                                            {
                                                                item.tickIcon ? (
                                                                    <img className="w-[18px] h-[18px] mx-auto" src={item.tickIcon} alt="" />

                                                                ) : (
                                                                    <h6 className="text-[14px] font-Poppins font-normal font-semibold ">{t(item.time)}</h6>
                                                                )
                                                            }
                                                        </th>
                                                        <th className="2xl:py-4 xl:py-4 lg:py-4 md:py-4 sm:py-1.5 xs:py-1.5 2xl:px-4  xl:px-4 lg:px-4 md:px-4 sm:px-1 xs:px-1 bg-[#F5F5F5] text-center">
                                                            {item.crossIcon ? (
                                                                <img className="w-[12px] h-[12px] m-auto " src={item.crossIcon} alt="" />

                                                            ) : (
                                                                item.date ? (
                                                                    <h6 className="text-[14px]  font-Poppins font-semibold">{t(item.date)}</h6>
                                                                ) : (
                                                                    <img className="w-[18px] h-[18px] m-auto " src={item.tickIcon} alt="" />
                                                                )
                                                            )
                                                            }

                                                        </th>


                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>


                        {/* <div className="flex items-center justify-center pt-3 pb-2">
                        <hr className="w-[60px] h-[3px] bg-gray-500" />

                    </div> */}
                    </div>

                </div>
                <div className="flex items-center justify-center pb-12 lg:hidden xl:hidden 2xl:hidden">
                    <hr className="w-[60px] h-[3px] bg-gray-500 -mb-20" />

                </div>
                {/* <div className="2xl:py-12 xl:py-12 lg:py-12  md:py-0 sm:py-0 xs:py-0 sm:px-10 xs:px-6 w-[30%] md:w-[100%] sm:w-[100%] xs:w-[100%]  rounded-lg">
                    <div className=" sticky top-10 hidden lg:block  xl:block 2xl:block">
                        <div className=" pr-20 md:px-20 pt-[41px] md:pt-0 sm:pt-0 xs:pt-0 sm:px-10 xs:px-6 ">
                            <div className="space-y-4">
                                <h3 className="text-[26px] sm:text-[18px] xs:text-[18px] font-[600] font-Poppins] ">{t("Packages")}</h3>
                                <p className="text-[16px] sm:text-[14px] xs:text-[14px] font-[500] text-[#5B5B5B] font-Poppins pb-4">{t("Buy $METRO now and get exclusive rewards. The more you buy in, the more you unlock. These perks will never be available again.")}

                                </p>
                            </div>
                            <div className="overflow-auto h-[90vh] sm:h-[auto] xs:h-[auto] sm:scrollbar-none xs:scrollbar-none  p-0 scrollbar sm:px-0  xs:px-0 sm:border-none xs:border-none flex flex-col justify-between">
                                {
                                    PackagesCardData.map((item, id) => (
                                        <div className="" key={id}>
                                            <PackagesCard receiveDetail1={t("Early Access to Metropoly Beta")} Package={item.title} packageReceive={item.subtitle} packageAmount={item.amount} cardimg={item.image} packageCard={item.packageCard} itemId={item.id} minAmount={item.minAmount} handleRange={handleRange} />
                                        </div>
                                    )
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div> */}


            </div>
        </>
    )
}
export default Campaign;