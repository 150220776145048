import React from "react";
import cointelegraph from "../../src/assects/images/company/cointelegraph.png";
import yahoo from "../../src/assects/images/company/yahoo.png";
import bloomberg from "../../src/assects/images/company/bloomberg.png";
import btc from "../../src/assects/images/company/btc.com.png";
import coinspeaker from "../../src/assects/images/company/coinspeaker.png";
import cryptonews from "../../src/assects/images/company/cryptonews.png";
import cmc from "../../src/assects/images/company/cmc.png";
import empty from "../../src/assects/images/company/empty_box.png";
import uni from "../../src/assects/images/uniswap.png";
import xt from "../../src/assects/images/xt.png";
import { useTranslation } from "react-i18next";
import { notify } from "../utils/notify";

import copyIcon from "../assects/images/company/icons/copy-solid.svg"
import { useMoralis } from 'react-moralis';
const imgData = [
    {
        id: 7,
        imgName: btc,
        link: "https://news.bitcoin.com/metropoly-pre-sale-raised-300000-after-releasing-beta/"
    },
    {
        id: 5,
        imgName: coinspeaker,
        link: "https://www.coinspeaker.com/metropoly-presale-raised-over-350k/"
    },
    {
        id: 3,
        imgName: yahoo,
        link: "https://finance.yahoo.com/news/metropoly-metro-token-presale-starting-121500323.html"
    },
    {
        id: 4,
        imgName: bloomberg,
        link: "https://www.bloomberg.com/press-releases/2022-10-25/metropoly-s-metro-token-presale-starting-soon"
    }
]


const imgData2 = [
    {
        id: 2,
        imgName: cointelegraph,
        link: "https://cointelegraph.com/press-releases/metropoly-pre-sale-raised-300k-after-releasing-beta"
    },
    {
        id: 8,
        imgName: cmc,
        link: "https://coinmarketcap.com/community/articles/63fc93b1fd311907dea2813a/"
    },
    {
        id: 6,
        imgName: cryptonews,
        link: "https://cryptonews.com/news/metropoly-planning-disrupt-real-estate-industry-through-blockchain-it-best-new-token-of-december-2022.htm"
    },
]

const imgData3 = [
    {
        id: 2,
        imgName: uni,
        link: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7"
    },
    {
        id: 1,
        imgName: xt,
        link: "https://www.xt.com/tradePro/metro_usdt"
    },
]

const FeaturedSection = () => {

    const handleCopy = () => {
        navigator.clipboard.writeText("0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7");
        notify("Contract Copied!")
    }

    const { web3 } = useMoralis();
    const handleAddMetro = async () => {
        const tokenAddress = '0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7';
        const tokenSymbol = 'METRO';
        const tokenDecimals = 18;

        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await web3.provider.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                    },
                },
            });

            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Your loss!');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const { t } = useTranslation()
    return (
        <>

            <div className="w-full 2xl:px-44 xl:px-44 px-2 bg-[#0072BA] py-3" >
                <div className="text-center text-white ">
                    <div className="">
                        <h2 className="px-2 font-[700] font-[Poppins] text-[18px] lg:text-[14px] md:text-[14px] xs:text-[14px]">$METRO Contract Address: 0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7
                            <span className="pl-2 md:hidden xs:hidden lg:hidden">
                                <button id="nav-button" className='border-2 border-inherit col-span-1 rounded px-2' onClick={handleAddMetro}> Add To Metamask
                                </button>

                            </span>
                            <span className="pl-2">

                                <button id="nav-button" className='border-2 border-inherit col-span-1 rounded px-2' onClick={handleCopy}> Copy Address
                                </button></span>
                        </h2>
                    </div>
                </div>
            </div>
            {/* <div className="w-full 2xl:px-44 xl:px-44 px-10 bg-[#0072BA] py-3" >
                <div className="text-center text-white ">
                    <div className="grid grid-cols-6">
                        <h2 className="px-2 font-[400] font-[Poppins] text-[20px] col-span-4">$METRO Contract Address: 0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7
                        
                        </h2>
                        <button id="nav-button" className='border-2 border-inherit col-span-1 rounded px-2' onClick={handleAddMetro}> Add To Metamask
                        </button>
                        
                        <button id="nav-button" className='border-2 border-inherit col-span-1 rounded px-2' onClick={handleCopy}> Copy Address
                        </button>
                    </div>
                </div>
            </div> */}
            <div className="w-full 2xl:px-44 xl:px-44 px-10  bg-gray-50 py-12" >
                <div className="text-center">
                    {/* <h2 className="px-2 2xl:hidden xl:hidden font-[700] font-[Poppins] text-[30px]">{t("$METRO Launches")}</h2>
                    <h2 className="px-2 font-[700] 2xl:hidden xl:hidden font-[Poppins] text-[30px]">{t("May 1st")}</h2> */}
                    <h2 className="px-2 font-[700] font-[Poppins] text-[30px]">{t("Listed on")}</h2>
                </div>
                <div className="pt-5 xl:px-[150px] 2xl:px-[150px] grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-2 xs:grid-cols-2 sm:grid-cols-2  flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-evenly sm:justify-evenly justify-evenly flex-wrap" style={{
                    alignItems: "center", justifyItems: "center"
                }}>
                    {
                        imgData3.map((item, id) => (
                            <a href={item.link} key={id}>
                                <img className="items-center content-center" src={item.imgName} width={200} alt="" />
                            </a>
                        ))
                    }

                </div>
            </div>
            <div className="w-full 2xl:px-44 xl:px-44 px-10  bg-gray-50 py-12" >
                <div className="text-center">
                    <h2 className="font-[700] font-[Poppins] text-[30px]">{t("Featured In")}</h2>
                </div>
                <div className="pt-5 grid grid-cols-4 lg:grid-cols-2 md:grid-cols-2 gap-2 xs:grid-cols-2 sm:grid-cols-2 w-full flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-evenly sm:justify-evenly justify-evenly flex-wrap" style={{
                    alignItems: "center", justifyItems: "center"
                }}>
                    {
                        imgData.map((item, id) => (
                            <a href={item.link} key={id}>
                                <img className="items-center content-center" src={item.imgName} width={200} alt="" />
                            </a>
                        ))
                    }

                </div>

                <div className="py-5 grid grid-cols-3 lg:grid-cols-1 md:grid-cols-1 gap-2 xs:grid-cols-1 sm:grid-cols-1 gap-4 w-full flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-evenly sm:justify-evenly justify-evenly flex-wrap" style={{
                    alignItems: "center", justifyItems: "center"
                }}>
                    {
                        imgData2.map((item, id) => (
                            <a href={item.link} key={id}>
                                <img className="items-center content-center" src={item.imgName} width={200} alt="" />
                            </a>
                        ))
                    }

                </div>
            </div>

        </>
    )
}
export default FeaturedSection