import React, { useState, useEffect, useCallback } from "react";
import Button from "../Button/button";
import Logout from '../../assects/icons/logout.png';
import nft1 from "../../assects/images/nft1.png"
import nft2 from "../../assects/images/nft2.png"
import nft3 from "../../assects/images/nft3.png"
import nft4 from "../../assects/images/nft4.png"
import nft5 from "../../assects/images/nft5.png"
import homeWallet1 from "../../assects/images/homeWallet1.png"
import homeWallet2 from "../../assects/images/homeWallet2.png"
import add from "../../assects/images/addicon.svg"
import addwallet from "../../assects/images/addwallet.svg"
import { useMoralis, useMoralisSubscription, useMoralisWeb3Api, useChain } from "react-moralis";
import { v4 as uuidv4 } from 'uuid';
import Loading from "../../appcomponents/loading";
import { notify, notifyInfo, notifyWarning, notifyWithPromise } from "../../utils/notify"
import { nftContract, crowdsaleContract, crowdsaleContractOld, currentChain, currentChainOld, bnbcrowdsaleContract, bnbcrowdsaleContractOld, bnbcurrentChain, bnbcurrentChainOld } from "../../utils/constants"
import { useTranslation } from 'react-i18next';
import { update, updateApproval, updateError } from "../../utils/notify"

import { disconnect } from '@wagmi/core'

import { useContractRead } from 'wagmi'

import { useContractWrite } from 'wagmi'
import { toast } from "react-toastify";


const MyWallet = ({ accounts }) => {
    const { i18n } = useTranslation()
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [today, setToday] = useState(month[new Date().getMonth()] + " " + new Date().getDate() + ", " + new Date().getFullYear());
    const Web3Api = useMoralisWeb3Api();
    const { web3, user, logout, Moralis } = useMoralis();
    const [userTotalTokens, setUserTotalTokens] = useState(0);
    const [allUserContributionETHBNB, setAllUserContributionETHBNB] = useState(0);
    const [userBonus, setUserBonus] = useState(0);
    const [userBonusFromBNB, setUserBonusFromBNB] = useState(0);
    const [userMrMetropoly, setUserMrMetropoly] = useState(nft1);
    const [ownedMrMetropoly, setOwnedMrMetropoly] = useState("0");
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [claimAvailable, setClaimAvailable] = useState(false);
    const { switchNetwork, chainId } = useChain();
    const [userMetro, setUserMetro] = useState(0);
    const [userContriBNB, setUserContriBNB] = useState(0);
    const [userContriETH, setUserContriETH] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const [userContributionNewFromBNB, setUserContributionNewFromBNB] = useState(0);
    const [userContributionNew, setUserContributionNew] = useState(0);

    const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];

    const { write: stake } = useContractWrite({
        address: '0x9a128f300a28cfb5c5a4ecc1296e9b55a6bd2364',
        abi: [
            {
                "inputs": [],
                "name": "reserveSlot",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            }],
        functionName: 'reserveSlot',
        chainId: 1
    })


    const { data: hasReserve } = useContractRead({
        address: '0x9a128f300a28cfb5c5a4ecc1296e9b55a6bd2364',
        abi: [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "name": "hasReserved",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }],
        functionName: 'hasReserved',
        chainId: 1,
        args: [accounts],
    })
    const { write, error, isError } = useContractWrite({
        address: '0xD8444eF1A23A6811994fc557921949E3327967CE',
        abi: [{
            "inputs": [],
            "name": "claimTokens",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }],
        functionName: 'claimTokens',
        chainId: 1,
        onError(error) {
            console.log('Error', error)
            const error_message = "ContractFunctionExecutionError: The contract function \"claimTokens\" reverted with the following reason:\nYou are not eligible to claim.";

            const eligible_to_claim_regex = /You are not eligible to claim./;

            const eligible_to_claim_message = eligible_to_claim_regex.exec(error_message)[0];
            if (eligible_to_claim_regex === "You are not eligible to claim.")
                toast.error(eligible_to_claim_message)
        },
    })
    function formatCount(count, withAbbr = true, decimals = 2) {
        if (userBonus === 0 && userBonusFromBNB === 0 && userContributionNewFromBNB === 0 && userContributionNew === 0) { return 0 }
        const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
        let result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
        if (withAbbr) {
            result += `${COUNT_ABBRS[i]}`;
        }
        return result;
    }

    function formatCount2(count, withAbbr = true, decimals = 2) {
        const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
        let result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
        if (withAbbr) {
            result += `${COUNT_ABBRS[i]}`;
        }
        return result;
    }

    const { data: getUserContributionBNB, error: contriBNBError, refetch: refreshContributionBNB } = useContractRead({
        address: bnbcrowdsaleContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserContribiton",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserContribiton',
        chainId: 56,
        args: [accounts]
    })

    const { data: getUserContributionETH } = useContractRead({
        address: crowdsaleContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserContribiton",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserContribiton',
        chainId: 1,
        args: [accounts]
    })

    const { data: getUserTokens, isLoading } = useContractRead({
        address: '0xD8444eF1A23A6811994fc557921949E3327967CE',
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserTokens",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserTokens',
        chainId: 1,
        args: [accounts],
    })
    const combineContribution = (num) => {
        console.log(num)
        let res = num
        if (res > 999)
            res = formatCount2(res)
        console.log(res)
        setUserMetro(res)
    }
    const combineBonuses = (userBonus, userBonusFromBNB) => {
        let result = "0";
        result = (userBonus / 10 ** 18) + (userBonusFromBNB / 10 ** 18);
        if (result > 999)
            result = formatCount(result)
        if (result === "NaNundefined")
            return result = "loading..."
        return result;
    }
    const walletData = [
        {
            id: uuidv4(),
            image: homeWallet1,
            title: 'METRO',
            value: 0
        },
        {
            id: uuidv4(),
            image: homeWallet2,
            title: 'BONUS',
            value: combineBonuses(userBonus, userBonusFromBNB),
        },
        {
            id: 3,
            image: userMrMetropoly,
            title: 'Mr. Metropoly NFT',
            value: ownedMrMetropoly,
        }
    ]
    const toastId = React.useRef(1);


    const handleLogout = async () => {
        await disconnect()
        notify("User logged out.", toastId)
        logout();
    }

    const notAvail = () => {
        notifyWarning("Claim is not yet available.")
    }


    // const totalNewContributionETH = useMoralisQuery(
    //     "EthContributors",
    //     (query) => query.limit(1500),
    // );

    // const totalNewContributionBNB = useMoralisQuery(
    //     "BscContributors",
    //     (query) => query.limit(1500),
    // );


    // const testFetch = useMoralisQuery(
    //     "BscContributors",
    //     (query) => query.equalTo,
    // );

    // function truncateToDecimals(num, dec = 2) {
    //     const calcDec = Math.pow(10, dec);
    //     return Math.trunc(num * calcDec) / calcDec;
    // }

    // const getTotalContribution = useCallback(() => {
    //     if (isAuthenticated) {
    //         if (user) {
    //             totalNewContributionBNB.fetch({
    //                 onSuccess: (participantsResult) => {
    //                     // The object was retrieved successfully.
    //                     let contri = 0;
    //                     let contributionId = [];
    //                     let allContri = 0;
    //                     let bonus = 0;
    //                     participantsResult.map((index) => {
    //                         if (index.attributes.contributor === user.get("ethAddress")) {
    //                             contri += Number(index.attributes.amount);
    //                             contributionId.push(Number(index.attributes.contributeId))
    //                         }
    //                         allContri += Number(index.attributes.amount);
    //                     })
    //                     setUserContributionNewFromBNB(Number(contri / 1000000))
    //                     setallNewBNBContribution(allContri)
    //                     setLoading(false)

    //                 },
    //                 onError: (error) => {
    //                     console.log(error)
    //                 }
    //             });

    //             totalNewContributionETH.fetch({
    //                 onSuccess: (participantsResult) => {
    //                     // The object was retrieved successfully.
    //                     let contri = 0;
    //                     let contributionId = [];
    //                     let allContri = 0;
    //                     let bonus = 0;
    //                     participantsResult.map((index) => {
    //                         if (index.attributes.contributor === user.get("ethAddress")) {

    //                             contri += Number(index.attributes.amount);
    //                             contributionId.push(Number(index.attributes.contributeId))
    //                         }
    //                         allContri += Number(index.attributes.amount);
    //                     })
    //                     // console.log(contributionId)
    //                     setUserBonus(truncateToDecimals(bonus))
    //                     setUserContributionNew(Number(contri / 1000000))
    //                     setallNewContribution(allContri)
    //                     setLoading(false)
    //                 },
    //                 onError: (error) => {
    //                     console.log(error)
    //                 }
    //             });
    //         }
    //     }
    // }, [fetch]);


    const handleAddMetro = async () => {
        const tokenAddress = '0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7';
        const tokenSymbol = 'METRO';
        const tokenDecimals = 18;

        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await web3.provider.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                    },
                },
            });

            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Your loss!');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleStake = async () => {

        try {
            if (chainId !== "0x1") {
                await switchNetwork("0x1");
            }
        }
        catch (error) {

        }
        // if (userContribution === "0") {
        //     notifyWarning("You are not eligible to claim.", toastId);
        //     return;
        // }

        // notifyWarning("Claim period has not started yet.", toastId)
        if (!isError)
            stake?.()
        if (errorMessage)
            notifyWarning(errorMessage, toastId)
    }
    const handleClaimToken = async () => {

        try {
            if (chainId !== "0x1") {
                await switchNetwork("0x1");
            }
        }
        catch (error) {

        }
        // if (userContribution === "0") {
        //     notifyWarning("You are not eligible to claim.", toastId);
        //     return;
        // }

        // notifyWarning("Claim period has not started yet.", toastId)
        if (!isError)
            write?.()
        if (errorMessage)
            notifyWarning(errorMessage, toastId)
    }
    // const getMrMetropoly = useCallback(async () => {

    //     const nft = await Web3Api.account.getNFTsForContract({
    //         token_address: nftContract,
    //         chain: currentChain
    //     }).then((e) => {
    //         if (e) {
    //             let metadata = JSON.parse(e.result[0].metadata);
    //             if (metadata) {
    //                 setUserMrMetropoly(metadata.image)
    //                 setOwnedMrMetropoly(metadata.name)
    //             }
    //             setUserMrMetropoly(nft1)
    //             setOwnedMrMetropoly("0")
    //         }
    //     })


    //     // setUserMrMetropoly(nft)
    // }, [userMrMetropoly])


    // const getUserTokens = async () => {

    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: currentChain,
    //             address: crowdsaleContract,
    //             function_name: "getUserTokens",
    //             abi: [{
    //                 "inputs": [
    //                     {
    //                         "internalType": "address",
    //                         "name": "_userAddress",
    //                         "type": "address"
    //                     }
    //                 ],
    //                 "name": "getUserTokens",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {
    //                 _userAddress: user.get("ethAddress")
    //             }
    //         })
    //         .then((response) => {
    //             setUserTokens(response)
    //         });

    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: bnbcurrentChain,
    //             address: bnbcrowdsaleContract,
    //             function_name: "getUserTokens",
    //             abi: [{
    //                 "inputs": [
    //                     {
    //                         "internalType": "address",
    //                         "name": "_userAddress",
    //                         "type": "address"
    //                     }
    //                 ],
    //                 "name": "getUserTokens",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {
    //                 _userAddress: user.get("ethAddress")
    //             }
    //         })
    //         .then((response) => {
    //             setUserTokensBNB(response)
    //         });

    // }

    // const getUserBonus = async () => {

    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: bnbcurrentChain,
    //             address: bnbcrowdsaleContract,
    //             function_name: "getUserBonusTokens",
    //             abi: [{
    //                 "inputs": [
    //                     {
    //                         "internalType": "address",
    //                         "name": "_userAddress",
    //                         "type": "address"
    //                     }
    //                 ],
    //                 "name": "getUserBonusTokens",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {
    //                 _userAddress: user.get("ethAddress")
    //             }
    //         })
    //         .then((response) => {
    //             setUserBonusFromBNB(response)
    //         });


    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: currentChain,
    //             address: crowdsaleContract,
    //             function_name: "getUserBonusTokens",
    //             abi: [{
    //                 "inputs": [
    //                     {
    //                         "internalType": "address",
    //                         "name": "_userAddress",
    //                         "type": "address"
    //                     }
    //                 ],
    //                 "name": "getUserBonusTokens",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {
    //                 _userAddress: user.get("ethAddress")
    //             }
    //         })
    //         .then((response) => {
    //             setUserBonus(response)
    //         });
    // }
    // const getUserContribution = async () => {

    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: bnbcurrentChain,
    //             address: bnbcrowdsaleContract,
    //             function_name: "getUserContribiton",
    //             abi: [{
    //                 "inputs": [
    //                     {
    //                         "internalType": "address",
    //                         "name": "_userAddress",
    //                         "type": "address"
    //                     }
    //                 ],
    //                 "name": "getUserContribiton",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {
    //                 _userAddress: user.get("ethAddress")
    //             }
    //         })
    //         .then((response) => {
    //             console.log(response)
    //             setUserContributionNewFromBNB(Number(response / 1000000))
    //             setallNewBNBContribution(response)
    //         });

    //     await Moralis.Web3API.native
    //         .runContractFunction({
    //             chain: currentChain,
    //             address: crowdsaleContract,
    //             function_name: "getUserContribiton",
    //             abi: [{
    //                 "inputs": [
    //                     {
    //                         "internalType": "address",
    //                         "name": "_userAddress",
    //                         "type": "address"
    //                     }
    //                 ],
    //                 "name": "getUserContribiton",
    //                 "outputs": [
    //                     {
    //                         "internalType": "uint256",
    //                         "name": "",
    //                         "type": "uint256"
    //                     }
    //                 ],
    //                 "stateMutability": "view",
    //                 "type": "function"
    //             }],
    //             params: {
    //                 _userAddress: user.get("ethAddress")
    //             }
    //         })
    //         .then((response) => {
    //             console.log(response)
    //             setUserContributionNew(Number(response / 1000000))
    //             setallNewContribution(response)
    //         });
    // }


    useEffect(() => {
        console.log(contriBNBError)
    }, [contriBNBError])

    useEffect(() => {
        combineContribution(parseInt(getUserTokens) / 1e18)
        let contBNB = parseInt(getUserContributionBNB)
        let contETH = parseInt(getUserContributionETH)
        if (!contBNB)
            console.log(refreshContributionBNB?.data)

        if (contBNB > 0)
            contBNB = contBNB / 1000000
        if (contETH > 0)
            contETH = contETH / 1000000
        setUserContriBNB(contBNB)
        setUserContriETH(contETH)
        if (contBNB + contETH) {
            let allusercontri = Number(contBNB + contETH);
            setAllUserContributionETHBNB(allusercontri)
            if (allusercontri >= 100 && allusercontri <= 1000)
                setUserMrMetropoly(nft1)
            else if (allusercontri > 1000 && allusercontri <= 9999)
                setUserMrMetropoly(nft2)
            else if (allusercontri > 9999 && allusercontri <= 24999)
                setUserMrMetropoly(nft3)
            else if (allusercontri > 24999 && allusercontri <= 99999)
                setUserMrMetropoly(nft4)
            else if (allusercontri > 99999)
                setUserMrMetropoly(nft5)

        }
    }, [isLoading, userContriBNB, userContriETH])



    return (
        <>
            <div className=" h-[750px] xs:w-[340px]  sm:w-[400px] overflow-auto wallet walletthumb absolute right-0 xs:right-0 sm:right-0 -top-14 w-[424px] bg-[#0072BA] rounded-2xl block border">
                <div className="px-6 pt-5 ">
                    <div className="w-full flex justify-between items-start mb-4 ">
                        <div className="flex space-x-3">
                            {/* {
                                    window.location.pathname === '/mypage' ?
                                        <Link to='/'>
                                            <Button text={'Go back'} classes="bg-white flex items-center justify-center w-[162px] h-[37px] text-black rounded-full  font-[poppins] font-[600] text-[16px]" onClick={notAvail} />

                                        </Link> :
                                        <Link to='/mypage'>
                                            <Button text={'My Dashboard'} classes="bg-white flex items-center justify-center w-[162px] h-[37px] text-black rounded-full  font-[poppins] font-[600] text-[16px]" onClick={notAvail} />

                                        </Link>
                                } */}

                            <Button text={'My Dashboard'} classes="bg-white flex items-center justify-center w-[162px] h-[37px] text-black rounded-full  font-[poppins] font-[600] text-[16px]" onClick={notAvail} />

                        </div>
                        <div>
                            <img className="w-[34px] h-[34px] cursor-pointer" src={Logout} alt="avater" onClick={handleLogout} />
                        </div>

                    </div>
                    <div className="mt-[60px] mb-[50px] rounded-[10px] h-[140px] font-Poppins flex justify-center p-6  flex-col bg-[#428cbb] items-center shadow-xl text-white ">
                        <h4 className="font-[700] font-[Poppins] text-[16px]">Total Contribution</h4>
                        {
                            loading1 ?

                                <h2 className="text-[34px] font-[700] font-[Poppins]">Fetching...</h2>
                                :
                                // <h2 className="text-[34px] font-[700] font-[Poppins]"> <span className="text-[#3ad324]">$</span> {formatCount(allUserContributionETHBNB)} USD</h2>
                                <h2 className="text-[34px] font-[700] font-[Poppins]"> <span className="text-[#3ad324]">$</span> {userContriBNB + userContriETH} USD</h2>
                        }

                        {/* <h4 className="font-[700] font-[Poppins] text-[16px]">10’000 USD</h4> */}
                    </div>

                </div>

                <div className="bg-[#ffff]  mt-9 px-4 py-6 rounded-t-[40px]">
                    <div className="mt-[15px] mb-[15px]">
                        <h3 className="text-center font-[600] font-[poppins] text-[30px] text-[#2075C2]">{today}</h3>
                    </div>

                    {
                        loading2 ?

                            <h3 className="text-center font-[600] font-[poppins] text-[12px] text-[#2075C2]">fetching...</h3>
                            :
                            <></>
                    }
                    <div className="bg-[#ffff] rounded-b-xl p-6 rounded-t-[40px] space-y-4">

                        <div className="pb-6">

                            {
                                walletData.map((item) => (

                                    <>
                                        {item.title === "BONUS" ? <>
                                            {item.value !== 0 ? <>
                                                <div className="flex items-center justify-between py-3 px-4 border-b">
                                                    <div>
                                                        <div className="flex items-center space-x-2">
                                                            <img className="w-[35px] h-[35px]" src={item.image} alt="" />
                                                            <h5 className="text-[#222222] font-[600] text-[16px] font-Poppins">{item.title}</h5>
                                                        </div>

                                                    </div>

                                                    {item.id === 3 ? <> {allUserContributionETHBNB >= 100 ? <>
                                                        <h3 className="text-[#222222] font-[700] text-[24px] font-Poppins"> {1}</h3></> : <>
                                                        <h3 className="text-[#222222] font-[700] text-[24px] font-Poppins"> {item.value}</h3></>}
                                                    </> : <>
                                                        <h3 className="text-[#222222] font-[700] text-[24px] font-Poppins"> {item.value}</h3></>}
                                                </div>
                                            </> : <></>}
                                        </>
                                            : <>
                                                <div className="flex items-center justify-between py-3 px-4 border-b">
                                                    <div>
                                                        <div className="flex items-center space-x-2">
                                                            <img className="w-[35px] h-[35px]" src={item.image} alt="" />
                                                            <h5 className="text-[#222222] font-[600] text-[16px] font-Poppins">{item.title}</h5>
                                                        </div>

                                                    </div>

                                                    {item.id === 3 ? <> {allUserContributionETHBNB >= 100 ? <>
                                                        <h3 className="text-[#222222] font-[700] text-[24px] font-Poppins"> {1}</h3></> : <>
                                                        <h3 className="text-[#222222] font-[700] text-[24px] font-Poppins"> {item.value}</h3></>}
                                                    </> : <>
                                                        <h3 className="text-[#222222] font-[700] text-[24px] font-Poppins"> {!isLoading ? userMetro : 0}</h3></>}
                                                </div>
                                            </>}
                                    </>

                                ))
                            }
                        </div>

                        <div className="w-full py-0 ">
                            {
                                hasReserve ?
                                    <Button text={'Stake Now'} classes="h-[55px] text-white items-center font-bold text-xl font-[poppins] my-1 rounded-2xl w-full bg-[#c9c9c9] shadow-sm" onClick={handleStake} />

                                    :
                                    <Button text={'Stake Now'} classes="h-[55px] text-white items-center font-bold text-xl font-[poppins] my-1 rounded-2xl w-full bg-[#0072BA] shadow-sm" onClick={handleStake} />

                            }
                            {
                                hasReserve ?
                                    <>
                                        <Button text={'Claim METRO'} classes="h-[55px] text-white items-center font-bold text-xl font-[poppins] rounded-2xl w-full bg-[#c9c9c9] shadow-sm" />
                                    </> :
                                    <>
                                        <Button text={'Claim METRO'} classes="h-[55px] text-white items-center font-bold text-xl font-[poppins] rounded-2xl w-full bg-[#0072BA] shadow-sm" onClick={handleClaimToken} />
                                    </>
                            }
                            <br></br>

                            <h5 className="text-[#000000] font-[500] text-center text-[13px] font-Poppins underline" onClick={() => notifyInfo
                                ("Basic Staking Rewards are at 20% APY, and VIP Staking Rewards are at 50% for $METRO holders who have never claimed their tokens.")}>
                                How does the Staking works?
                            </h5>
                            <br></br>
                            <div className="flex justify-between" onClick={() => { handleAddMetro() }}>
                                <p className="font-[Poppins] text-[16px] font-[600] text-[#0072BA]">Add METRO to Metamask</p>
                                <div className="flex justify- bg-[#0072BA] w-[53px] h-[26px] rounded-full p-1 space-x-1 items-center">
                                    <img src={addwallet} alt="" />
                                    <img className="w-[15px] h-[15px]" src={add} alt="" />
                                </div>
                            </div>
                            {/* <br></br>
                            <h5 className="text-[#000000] font-[500] text-center text-[13px] font-Poppins">Update: Because of the unexpected high gas fees on the Ethereum Blockchain (exceeding 100 GWEI), deploying the vesting contract is currently not feasible. Moreover, it’s not beneficial for our holders, as claiming their tokens would cost around $50 to $100 due to the high gas fees.
                            </h5>
                            <br></br>
                            <h5 className="text-[#000000] font-[500] text-center text-[13px] font-Poppins">
                                We have decided to hold off on deployment until the GWEI falls within a more reasonable range of 30-40 GWEI. We expect the deployment between 2-7 AM UTC. We will inform you once it’s done.</h5> */}
                        </div>
                        {/* <button onClick={handleAddMetro}>Add Metro on Metamask</button> */}
                        {/* {claimAvailable ? <>
                            <div className="w-full py-0 ">  
                                <Button text={'Claim METRO'} classes="h-[55px] text-white items-center font-bold text-xl font-[poppins] rounded-2xl w-full bg-[#0072BA] shadow-sm" onClick={handleClaimToken} />
                            </div></> :
                            <div className="w-full py-0 ">
                                <Button text={'Claim METRO'} classes="h-[55px] text-white items-center font-bold text-xl font-[poppins] rounded-2xl w-full bg-[#d5d5d5] shadow-sm" onClick={handleClaimToken} />
                            </div>
                        } */}
                        {/* <div className="text-center px-6 ">
                                <p className="font-semibold font-[poppins] text-[16px] text-[#838383]">Already claimed Tokens: {metroTokensClaimed} Metro</p>
                            </div> */}
                    </div>
                </div>
                <div className="mywalletbg-img  pt-[6.5rem] ">
                    <div className="px-11 space-y-4 pb-[2rem]">


                        {/* <div className="flex justify-between">
                            <p className="font-[Poppins] text-[16px] font-[600] text-[white]">Claimed Tokens:</p>
                            <p className="font-[Poppins] text-[16px] font-[600] "> {formatCount(metroTokensClaimed)} METRO</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="font-[Poppins] text-[16px] font-[600] text-[white]">Remaining Tokens:</p>
                            <p className="font-[Poppins] text-[16px] font-[600] "> {formatCount(userTotalTokens - metroTokensClaimed)} METRO</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="font-[Poppins] text-[16px] font-[600] text-[white]"> Next Claim Date:</p>
                            <p className="font-[Poppins] text-[16px] font-[600]"> After Presale</p>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default MyWallet