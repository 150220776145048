import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Pages/Home";
import Tracker from "./Pages/Track";
import MyPage from "./Pages/Claim/MyPage";
import ClaimHome from "./Pages/Claim/ClaimPage";
import ClaimDashboard from "./Pages/Claim";
import Giveaway from "./Pages/Giveaway";

function App({ handleOpen, open, handleNavbarMenu }) {

  return (
    <>
      <Suspense fallback={<h1>loading</h1>}></Suspense>
      <Routes>
        <Route path="/" element={<Home handleOpen={handleOpen} handleNavbarMenu={handleNavbarMenu} />} />
      </Routes>
      <Routes>
        <Route path="/track-private" element={<Tracker />} />
      </Routes>
      <Routes>
        <Route path="/giveaway" element={<Giveaway />} />
      </Routes>
      <Routes>
        <Route path="/claimhome" element={<ClaimDashboard open={open} handleOpen={handleOpen} />} />
      </Routes>
      <Routes>
        <Route path="/mypage" element={<MyPage />} />
      </Routes>

    </>
  );
}

export default App;
