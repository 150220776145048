import React from "react";
import { notify } from "../utils/notify";
import copyIcon from "../assects/images/company/icons/copy-solid.svg"
import { useTranslation } from "react-i18next";
const MetropolyContract = () => {

    const handleCopy = () => {
        navigator.clipboard.writeText("0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7");
        notify("Contract Copied!")
    }

    const { t } = useTranslation();
    return (
        <>
            <div className="  py-12 px-20 lg:px-8 md:px-8 sm:px-8 xs:px-8 bg-[#ECF4FF]"  >
                <div className="2xl:px-32 xl:px-32 lg:px-15 md:px-6 sm:px-2  ">
                    <center>
                        <div>
                            <h2 className="font-[700] font-[Poppins] text-[30px]">{t("Token Details")}</h2>
                            <p className="leading-[25px] text-[15px] font-[400] font-[Poppins] text-[#000000] mb-2 py-4">
                                {t("Use the contract information below to add the Metropoly token to your wallet.")}
                            </p>
                        </div>
                    </center>

                    <div className="rounded bg-[#FFFFFF] py-6 flex justify-between flex-wrap 2xl:px-8 xl:px-8 lg:px-8 md:px-5 sm:px-4 px-4 2xl:hidden xl:hidden ">
                        <div className="">
                            <div className="pb-2">
                                <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Token Name")}</h3>
                                <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mb-2 mr-2">Metro Token</p>
                            </div>
                            <div >
                                <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Listed On")}</h3>
                                <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mb-2 mr-2">xt.com & Uniswap</p>
                            </div>
                            <div >
                                <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Token Type")}</h3>
                                <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mb-2 mr-2">ERC-20 (Ethereum)</p>
                            </div>
                            <div>
                                <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Token Symbol")}</h3>
                                <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mb-2 mr-2">METRO</p>
                            </div>
                            <div>
                                <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Decimal")}</h3>
                                <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2 mb-2">18</p>
                            </div>
                            <div>
                                <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Slippage")}</h3>
                                <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2 mb-2">0% Buy and Sell Taxes</p>
                            </div>

                        </div>

                        <div>
                            <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Token Contract Address")}</h3>
                            <div className="flex ">
                                <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2 mb-2">0xD77401A76.....7615509E7</p>
                                <img src={copyIcon} alt="" onClick={handleCopy} width={13} />
                            </div>
                        </div>


                        {/* <div className="col-span-3 bg-[#FFFFFF] flex  justify-between flex-wrap 2xl:px-8 xl:px-8 lg:px-8 md:px-5 sm:px-4 ">
                            <div className="pb-2 py-2 pt-5">
                                <h3 className="text-[18px] font-[800] font-[Poppins] text-[#000000] mb-2">{t("Presale Details")}</h3>
                                <div>
                                    <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Token sold during the Pre-sale")}</h3>
                                    <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2 mb-2">200,000,000 METRO</p>
                                </div>

                                <div className="pt-6">
                                    <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Current Stage Price")}</h3>
                                    <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2 mb-2">1 METRO = $0.09</p>
                                </div>
                                <div className="pt-6">
                                    <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Exchange Listing Price")}</h3>
                                    <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2 mb-2">1 METRO = $0.1</p>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="lg:hidden md:hidden sm:hidden xs:hidden">
                        <div className="grid grid-cols-8 gap-1 ">
                            <div className="col-span-2"></div>
                            <div className="col-span-4 bg-[#FFFFFF] py-6 flex justify-between flex-wrap 2xl:px-8 xl:px-8 lg:px-8 md:px-5 sm:px-4 px-4">
                                <div className="grid grid-cols-3 gap-8">
                                    <div className="pb-2">
                                        <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] mb-2">{t("Token Name")}</h3>
                                        <div className="2xl:flex xl:flex lg:flex md:flex sm:block block">
                                            <p className="text-[15px] sm:text-[13px] xs:text-[13px] md:text-[13px] lg:text-[13px] font-[400] font-[Poppins] text-[#000000] mr-2">Metro Token</p>
                                        </div>
                                    </div>
                                    <div >
                                        <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] mb-2">{t("Listed On")}</h3>
                                        <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2">xt.com & Uniswap</p>
                                    </div>
                                    <div >
                                        <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] mb-2">{t("Token Type")}</h3>
                                        <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2">ERC-20 (Ethereum)</p>
                                    </div>
                                    <div >
                                        <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] mb-2">{t("Token Symbol")}</h3>
                                        <div className="2xl:flex xl:flex lg:flex md:flex sm:block block">
                                            <p className="text-[15px] sm:text-[13px] xs:text-[13px] md:text-[13px] lg:text-[13px] font-[400] font-[Poppins] text-[#000000] mr-2">METRO</p>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] mb-2">{t("Decimal")}</h3>
                                        <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2">18</p>
                                    </div>

                                    <div>
                                        <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Slippage")}</h3>
                                        <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2 mb-2">0% Buy and Sell Taxes</p>
                                    </div>
                                </div>

                                <div className=" pt-4">
                                    <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] ">{t("Token Contract Address")}</h3>
                                    <div className="2xl:flex xl:flex lg:flex md:flex sm:block block   ">
                                        <p className="text-[15px] sm:text-[13px] xs:text-[13px] md:text-[13px] lg:text-[13px] font-[400] font-[Poppins] text-[#000000] mr-2">0xD77401A76d6cDB7Ac3bb031Bf25dEc07615509E7</p>
                                        <img src={copyIcon} alt="" onClick={handleCopy} width={13} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2"></div>



                            {/* <div className="col-span-3 bg-[#FFFFFF] flex  justify-between flex-wrap 2xl:px-8 xl:px-8 lg:px-8 md:px-5 sm:px-4 px-4">

                                <div className="pb-2 py-2">
                                    <h3 className="text-[18px] font-[800] font-[Poppins] text-[#000000] mb-2">{t("Presale Details")}</h3>
                                    <div>
                                        <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] mb-2">{t("Token sold during the Pre-sale")}</h3>
                                        <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2">200,000,000 METRO</p>
                                    </div>

                                    <div className="pt-6">
                                        <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] mb-2">{t("Current Stage Price")}</h3>
                                        <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2">1 METRO = $0.09</p>
                                    </div>
                                    <div className="pt-6">
                                        <h3 className="text-[16px] font-[600] font-[Poppins] text-[#000000] mb-2">{t("Exchange Listing Price")}</h3>
                                        <p className="text-[15px] font-[400] font-[Poppins] text-[#000000] mr-2">1 METRO = $0.1</p>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}
export default MetropolyContract