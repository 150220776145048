
import React from "react";
import { toast } from 'react-toastify';
export const notify = (message, toastId) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: true,
        toastId
    });
}
export const notifyInfo = (message, toastId) => {
    toast.info(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId
    });
}

export const notifyWarning = (message, toastId) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId
    });
}



export const notifyWithPromise = (message, toastId) => toastId.current = toast.info(message, {
    position: "top-right",
    autoClose: false
});
export const updateApproval = (message, toastId) => toast.update(toastId.current, { render: message, type: toast.TYPE.INFO, autoClose: false, isLoading: true });
export const update = (message, toastId) => toast.update(toastId.current, { render: message, type: toast.TYPE.SUCCESS, autoClose: 3000, isLoading: false });
export const updateError = (message, toastId) => toast.update(toastId.current, { render: message, type: toast.TYPE.ERROR, autoClose: 3000, isLoading: false });
