import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Tick1 from "../assects/images/tick1.png"
import "./slider.css"
import yahoo from "../assects/images/company/yahoo.png";
import bloomberg from "../assects/images/company/bloomberg.png";
import btc from "../assects/images/company/btc.com.png";


import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import { useTranslation } from "react-i18next";



const cardData = [
    {
        id: 1,
        content: `The simple and intuitive Metropoly platform offers the average person the ability to invest in income-generating properties around the world starting with as little as $100 USD.`,
        img: yahoo,
    },
    {
        id: 2,
        content: `Metropoly will revolutionize the real estate market by giving retail investors a simple platform to build truly passive income and long- term value appreciation.`,
        img: bloomberg,
    },
    {
        id: 3,
        content: `Metropoly’s power play in disrupting the real estate market is fractional investment. NFTs are used to represent NFT ownership in properties and all NFTS are 100% backed by real-world properties.`,
        img: btc,
    },
]



const Testimonials = () => {

    const { t, i18n } = useTranslation();

    return (
        <div className="2xl:px-32 xl:px-32 lg:px-32 md:px-12 sm:px-6 px-6 mt-10  " >

            <div className=" ">
                <h3 className=" text-center text-[30px]  font-[700] text[#F4F4F4] font-[Poppins]">{t("Press & Media")}</h3>
            </div>
            <div className="grid grid-cols-4 gap-8 justify-between items-center sm:px-10 xs:px-6 md:space-y-14 sm:space-y-14 xs:space-y-12 md:block sm:block xs:block pt-[36px]">

            </div>
            <div className=" py-4 w-[100%]" style={{ paddingBottom: "-30px", position: "relative" }}>
                <div >
                    <Swiper

                        slidesPerView={4}
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            500: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            750: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            1124: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                        }}
                        initialSlide={1}
                        spaceBetween={10}
                        className="sliderWidth"
                        navigation={{
                            nextEl: ".image-swiper-button-next",
                            prevEl: ".image-swiper-button-prev",
                            disabledClass: "swiper-button-disabled"
                        }}
                        modules={[Navigation]}
                    >

                        {
                            cardData.map((item, id) => (
                                <SwiperSlide className="wrap h-[530px]" key={id} >
                                    {item.id === 2 ?
                                        <div className=" px-10 pt-5 space-y-4 rounded-lg pb-10"
                                            style={{
                                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                margin: "20px",
                                                backgroundColor: "#0072BA"
                                            }}>
                                            <div className="flex place-content-center py-6" >
                                                <img src={item.img} width={200} style={{
                                                    filter: "brightness(0) invert(1)"

                                                }} />

                                            </div>
                                            <div className=" text-white text-center">
                                                {t(item.content)}
                                            </div>
                                        </div> :
                                        <div className="px-10 bg-gray-50 pt-5 space-y-4 rounded-lg pb-10"
                                            style={{
                                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                margin: "20px"
                                            }}>
                                            <div className="flex place-content-center py-6" >
                                                <img src={item.img} width={200} />

                                            </div>
                                            <div className=" text-center">
                                                {t(item.content)}

                                            </div>
                                        </div>
                                    }
                                </SwiperSlide>
                            ))
                        }

                    </Swiper>
                    <div className="swiper-button image-swiper-button-next">
                        <IoIosArrowForward />
                    </div>
                    <div className="swiper-button image-swiper-button-prev">
                        <IoIosArrowBack />
                    </div>
                </div>

            </div>

        </div >
    )
}
export default Testimonials 
